import React from 'react';
import PropTypes from 'prop-types';
import Option from '../../components/option';

const Notifications = ({ states, setStates }) => {
    return (
        <div>
            <Option
                hasToggle
                title='New Affiliate signup'
                content={`Get email alerts whenever a new affiliate signs up to your referral program.`}
                onToggle={setStates.setNotifAffSignup}
                toggleValue={states.notifAffSignup}
            />

            {states.requestPayout &&
                <Option
                    hasToggle
                    title='Payout Requests'
                    content={`Get email alerts whenever an affiliate makes a payout request.`}
                    onToggle={setStates.setNotifPayoutRequest}
                    toggleValue={states.notifPayoutRequest}
                />
            }

        </div>
    );
};

Notifications.propTypes = {

};

export default Notifications;