import React from 'react';

import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
        title='Get advocate email'
        description={`get an advocate's email`}
        method='get'
        path='/advocate-email'
        tabs={[{
            title: 'Axios',
            script: false,
            code:` 
        instance.get('https://api.refmonkey.com/advocate?identifier=\${refCode}')
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
             `,
        },
            {
                title: 'curl',
                script: false,
                code: ` 
        curl https://api.refmonkey.com/advocate?refCode=\${refCode} --header "Authorization: YOUR_ACCESS_TOKEN"     
             `,
            }]}
        inputParameters={
            <>
                <ParamItem
                    required={true}
                    title='refCode'
                    type='string'
                    content={`The referral code of the advocate.`}
                />
            </>
        }

        outputParameters={
            <>
                <ParamItem
                    title='success'
                    type='boolean'
                    content={`Indicates the outcome of the API call.`}
                />
                <ParamItem
                    title='message'
                    type='string'
                    content={`An explain message`}
                />

                <ParamItem
                    title='advocate'
                    type='string'
                    content={`The email of the advocate.`}
                />

            </>
        }
        sampleResponse={`
    {
        "success": true,
        "message": "advocate found",
        "advocate": "jason@gmail.com",
    }
    `}
    />
    );
};

DocRequest.propTypes = {

};

export default DocRequest;
