import useIsTrialPeriod from "./useIsTrialPeriod";
import { getUser } from 'utils/authUtil'
import React, { useEffect, useState } from 'react';
import useUpgradeBilling from "../../hooks/useUpgradeBilling";


const useTrialPeriod = () => {
    const [showPriceDialog, setShowPriceDialog] = useState()
    const [showPriceDialogDismmiss, setShowPriceDialogDismiss] = useState(true)
    const [showTrialBanner, setShowTrialBanner] = useState(false)
    const [trialDays, setTrialDays] = useState('')

    const { upgrade } = useUpgradeBilling()

    const user = getUser()

    const { execute: isTrialPeriod } = useIsTrialPeriod((days) => {
        setTrialDays(days)
        setShowTrialBanner(true)
    }, () => {

        setShowPriceDialog(true)
        setShowPriceDialogDismiss(false)
    })

    useEffect(() => {
        isTrialPeriod(user.email)
    }, [])

    const handleUpgrade = (index, isMonthly) => {
        upgrade(index)
    }

    const handleBannerButtonClick = () => {
        setShowPriceDialog(true)

    }

    const handleDialogClose = () => {
        setShowPriceDialog(false)
    }

    return {
        handleBannerButtonClick, showPriceDialogDismmiss, showPriceDialog, showTrialBanner,
        trialDays, handleDialogClose, handleUpgrade
    }

}

export default useTrialPeriod