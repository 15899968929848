import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from '../../count-item'
import CodeBox from 'components/code-box'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import Text from 'components/text'
import { HashLink } from 'react-router-hash-link';

const ConversionTracking = ({onDocs}) =>{
    const match = useRouteMatch()
    const history = useHistory()

     return (
         <div>
 <CountItem
                number='2'>
                Whenever an action that you want to track is performed,this is called a <b>conversion</b>, i.e someone signs up, makes a purchase,submits form,etc. you will need to signal our platform that a conversion has happened. To do so,run the following javascript snippet inside <b>{'<body>'}</b> tag.
                Add appropriate <b>option</b> properties.
            </CountItem>

            <Text tailwind='font-medium mb-2'>
                View other
                 <HashLink
                    onClick={onDocs}
                    className='text-blue-500 hover:text-blue-400'
                    to={`${match.url}/docs#conversion`}> additional option properties.</HashLink></Text>

            <CodeBox script> {`
            const options = {
                type: "purchase",
                data:{
                    email: "example@gmail.com"
                }
            }

            window.refMonkeyClient.conversion(options)

            `}</CodeBox>



            <Heading>Reverting a Conversion</Heading>
            <CountItem
                number='3'>
                <Text tailwind='font-medium'>Optional</Text>
                There are situations when you need to reverse a conversion , (i.e for a refund), use the <b>conversion_id</b> returned from the <b>conversion(...)</b> method.
                Call the following method in order to reverse a conversion, passing in the <b>conversion_id</b> as a parameter.
            </CountItem>

            <Text tailwind='font-medium mb-2'>
                View what type of
                 <HashLink
                    onClick={onDocs}
                    className='text-blue-500 hover:text-blue-400'
                    to={`${match.url}/docs#reverse-conversion`}> data is returned by this function call.</HashLink></Text>

            <CodeBox
                script={true}
            >{`window.refMonkeyClient.reverseConversion(conversionID, message)`}</CodeBox>

         </div>
     )
}

export default ConversionTracking