import React from 'react';
import PropTypes from 'prop-types';

/**
 * a wrapper is simply a div element 
 */
const Wrapper = ({tailwind,id,className,children}) => {
    return (
        <div 
        data-testid={id}
        className={`${tailwind} ${className}`}>
            {children}
        </div>
    );
};

Wrapper.propTypes = {
    
};

export default Wrapper;