import React from 'react';
import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
                    title='Get referrals'
                    description='get the referrals of an advocate'
                    method='get'
                    path='/referral/list'
                    tabs={[{
                      title: 'Axios',
                      script: false,
                      code:` 
        instance.get('https://api.refmonkey.com/referral/list?identifier=\${identifier}')
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
             `,
                    },
                      {
                        title: 'curl',
                        script: false,
                        code: ` 
        curl https://api.refmonkey.com/referral/list?identifier=\${identifier} --header "Authorization: YOUR_ACCESS_TOKEN"        
             `,
                      }]}
                    inputParameters={
                        <>
                            <ParamItem
                                required={true}
                                title='identifier'
                                type='string'
                                content={`A unique identifier for the user in your system. This can be an email address, username or id`}
                            />
                        </>
                    }

                    outputParameters={
                        <>
                            <ParamItem
                                title='success'
                                type='boolean'
                                content={`Indicates the outcome of the API call.`}
                            />

                            <ParamItem
                                title='referrals'
                                type='object'
                                content='Array of an advocates referrals'
                                childItems={
                                    <>
                                        <ParamItem
                                            title='name'
                                            type='string'
                                            content={`The name of the user in your system`}
                                        />

                                        <ParamItem
                                            title='identifier'
                                            type='string'
                                            content={`A unique identifier for the user in your system.`}
                                        />

                                        <ParamItem
                                            title='referredBy'
                                            type='string'
                                            content={`A unique identifier for the advocate that referred the referral`}
                                        />

                                    </>
                                }
                            />
                        </>
                    }
                    sampleResponse={`
                {
                    "success": true,
                    "identifier": "jason@gmail.com",
                    "referrals": [
                        {
                            "name": "George",
                            "identifier": "johndoe@gmail.com",
                            "referredBy": "jason@gmail.com"
                        },
                        {
                            "name": "Danny",
                            "identifier": "michelle@gmail.com",
                            "referredBy": "jason@gmail.com"
                        }
                    ]
                }
                `}
                />


    );
};

DocRequest.propTypes = {

};

export default DocRequest;
