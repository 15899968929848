import React, { useEffect, useState, useRef, useContext } from 'react'
import Register from 'components/signin/components/register'


const SignUp = ({onRegisterPath, onRegister,logoIcon,registerImage}) => {


  return (

    <Register
      onRegisterPath={onRegisterPath}
      onRegister={onRegister}
      registerImage={registerImage}
      logoIcon={logoIcon}
      title='Create an account'
      subtitle='To redeem your Saastronautics code'
      submitText='Create account'
    />
  );
};

export default SignUp;
