import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccessToken from './components/access_token'
import Text from 'components/text'
import SetupButton from './components/button'
import ApiPNG from 'images/api-logo.png'
import EcwidPNG from 'images/ecwid.png'
import { ReactComponent as JsSVG } from 'images/js-logo.svg'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import JsSetup from './components/js-setup'
import MarketerSetup from './components/marketer-setup'
import ApiSetup from './components/api-setup'
import EcwidSetup from './components/ecwid-setup'
import WixSetup from './components/wix-setup'
import WebflowSetup from './components/webflow-setup'
import WordpressSetup from 'app/docs/components/wordpress-docs'
import ZapierPNG from 'images/zapier.png'
import WixPNG from 'images/wix.png'
import ShopifyPNG from 'images/shopify.png'
import Webflow from 'images/webflow/logo.png'
import JsDocs from 'app/docs/components/js-docs'
import ApiDocs from 'app/docs/components/api-docs'
import Button from 'components/button'
import MarketerPNG from 'images/marketer.png'
import WordPressPNG from 'images/wordpress/logo.png'



//todo have a reference to the docs at the bottom
const Setup = ({ mobileView, onApiDocs, onJsDocs }) => {

    const [showAccessToken, setShowAccessToken] = useState(false)
    const history = useHistory()
    const match = useRouteMatch()

    return (
        <>
            <Route exact path={`${match.path}/api`}>
                <ApiSetup
                    onDocs={onApiDocs}
                    mobileView /> </Route>

            <Route exact path={`${match.path}/js`}>
                <JsSetup
                    onDocs={onApiDocs}
                    mobileView /></Route>

            <Route exact path={`${match.path}/webflow`}>    <WebflowSetup mobileView /></Route>
            <Route exact path={`${match.path}/marketer`}>
                <MarketerSetup
                    mobileView /></Route>

            <Route exact path={`${match.path}/ecwid`}>    <EcwidSetup mobileView /></Route>

            <Route exact path={`${match.path}/wix`}>    <WixSetup mobileView /></Route>

            <Route path={`${match.path}/api/docs`}>    <ApiDocs padding={false} /> </Route>

            <Route path={`${match.path}/js/docs`}>    <JsDocs padding={false} /></Route>

            <Route path={`${match.path}/wordpress`}>    <WordpressSetup padding={false} /></Route>


            <Route exact path={`${match.path}`}>
                <div>
                    <div className='flex items-center mb-6'>
                        {showAccessToken ?
                            <AccessToken />
                            :
                            <Button
                                variant='secondary'
                                tailwind={`w-full ${!mobileView && 'md:w-auto'}`}
                                onClick={() => setShowAccessToken(true)}
                            >Get Access Token</Button>
                        }
                    </div>

                    <Text
                        color
                        tailwind=' text-gray-700'
                        type='heading-small'>Below are some guides to get started with Refmonkey, for the full documentation see our wiki &nbsp;
                        <a href="https://www.notion.so/Refmonkey-Wiki-dfaa6ddff3bd4feba51bd40cb086450d"
                            class="text-indigo-600 font-bold underline">here</a>.
                    </Text>

                    <div className={`grid grid-cols-1  ${!mobileView && 'md:grid-cols-3'} gap-10  py-8`}>

                        <SetupButton
                            png
                            onClick={() => history.push(`${match.path}/marketer`)}
                            image={
                                <img src={MarketerPNG} />
                            }
                        >Refmonkey Easy Setup</SetupButton>

                        <SetupButton
                            onClick={() => {
                                history.push(`${match.path}/js`)
                            }}
                            image={<JsSVG />}
                        >Javascript</SetupButton>

                        <SetupButton
                            image={<img src={ApiPNG} />}
                            onClick={() => {
                                history.push(`${match.path}/api`)
                            }}
                        >REST API</SetupButton>

                        <SetupButton
                            png
                            onClick={() => window.location.href = 'https://zapier.com/apps/refMonkey'}
                            image={
                                <img src={ZapierPNG} />
                            }
                        >Zapier</SetupButton>


                        <SetupButton
                            png
                            onClick={() => window.location.href = 'https://apps.shopify.com/refmonkey-1'}
                            image={
                                <img src={ShopifyPNG} />
                            }
                        >Shopify</SetupButton>



                        <SetupButton
                            image={<img src={EcwidPNG} />}
                            onClick={() => {
                                history.push(`${match.path}/ecwid`)
                            }}
                        >Ecwid</SetupButton>

                        <SetupButton
                            image={<img src={WixPNG} />}
                            onClick={() => {
                                history.push(`${match.path}/wix`)
                            }}
                        >Wix</SetupButton>

                        <SetupButton
                            image={<img src={Webflow} />}
                            onClick={() => {
                                history.push(`${match.path}/webflow`)
                            }}
                        >Webflow</SetupButton>

                        <SetupButton
                            image={<img src={WordPressPNG} />}
                            onClick={() => {
                                history.push(`${match.path}/wordpress`)
                            }}
                        >Wordpress</SetupButton>

                    </div>

                </div>
            </Route>

        </>
    );
};

Setup.propTypes = {

};

export default Setup;