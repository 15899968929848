import React from 'react';
import PropTypes from 'prop-types';
import RewardOption from '../../components/reward-option';
import Option from '../../components/option';


const Reward = ({ states, setStates }) => {
    return (
        <div>
            <Option
                hasToggle={true}
                title="Enable Paypal Mass Payout"
                content="reward affiliates through paypal"
                onToggle={setStates.setEnablePaypal}
                toggleValue={states.enablePaypal}
            />

            <RewardOption
                rewardName={states.rewardAdvocatesName}
                rewardValue={states.rewardAdvocatesValue}
                rewardType={states.rewardAdvocatesType}
                onTypeChange={setStates.setRewardAdvocatesType}
                onNameChange={setStates.setRewardAdvocatesName}
                onValueChange={setStates.setRewardAdvocatesValue}
                title='Reward Affiliates'
                content={`Determines whether affiliates should be given a reward.`}
                onToggle={setStates.setRewardAdvocates}
                toggleValue={states.rewardAdvocates}
            />


            <RewardOption
                showPercentOption={false}
                rewardType={states.rewardReferralsType}
                onTypeChange={setStates.setRewardReferralsType}
                rewardName={states.rewardReferralsName}
                rewardValue={states.rewardReferralsValue}
                onNameChange={setStates.setRewardReferralsName}
                onValueChange={setStates.setRewardReferralsValue}
                title='Reward Referrals'
                content={`Determines whether referrals should be given a reward for performing a conversion through a referral link. Note that the reward is given to the referral only the first time that a conversion happens from the referral.`}
                onToggle={setStates.setRewardReferrals}
                toggleValue={states.rewardReferrals}
            />

            {/** <div className='w-full h-px border-t border-gray-200' /> **/}

        </div>
    );
};

Reward.propTypes = {

};

export default Reward;