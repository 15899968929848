import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/textinput'
import Text from 'components/text'
import Button from 'components/button'

const Item = ({helpText, onClick, placeholder, buttonText, setShowLoading,
    textInputOnChange, textInputValue}) => {
    return (
        <div className='my-4'>
            <div className='flex justify-between'>
                <div className='flex items-center'>
                    <TextInput
                        placeholder={placeholder}
                        value={textInputValue}
                        onChange={value => textInputOnChange(value)}
                    />
                </div>

                <Button
                tailwind='w-64'
                size='button-small'
                    variant='outline'
                    onClick={() => {
                        setShowLoading(true)
                         onClick()
                    }
                    }
                >{buttonText}</Button>
            </div>

            
                <Text type='text-extra-small'>{helpText}</Text>
            
        </div>
    );
};

Item.propTypes = {

};

export default Item;