

const devUrl = 'https://2a96827c83bf.ngrok.io'
const useRedirectShopify = () => {
    let proceed = false
    /**
     * 
     */
    const redirectToShopify = (shop) => {

        const key = '299d08448816f2766fb2cdc5dc9f29b4'
        const scopes = 'write_script_tags,read_orders'
        const redirect = (process.env.NODE_ENV === 'development') ? devUrl : 'https://refmonkey.com/'
        window.location.href = `https://${shop}/admin/oauth/authorize?client_id=${key}&scope=${scopes}&redirect_uri=${redirect}&state=nonce`
    }

    const url = new URL(window.location.href);
    const params = url.searchParams
    const shop = params.get("shop")
    const hmac = params.get("hmac")
    const code = params.get("code")

    if (hmac && shop && !code) {
        redirectToShopify(shop)
    } else {
        proceed = true
    }

    return proceed

}

export default useRedirectShopify