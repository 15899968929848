import { gql } from 'apollo-boost'





export const GET_REDEEMED_REWARDS = gql`
query{
    redeemedRewards{
     name
     identifier
     date
     rewardName
     rewardValue
     units
     type
     redeemDate
     tag
	 productName
     productDescription
     reverted
     revertMessage
 } 

}`

/*
export const UNDO_REDEEM_REWARD = gql`
mutation($identifier: String!,$type: String!){
    redeemReward(identifier: $identifier,type:$type)
}
`*/