import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from '../../../setup/components/count-item'
import CodeBox from 'components/code-box'
import GrayCode from 'components/code-box/gray-code'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import Button from 'components/button'
import Text from 'components/text'
import { HashLink } from 'react-router-hash-link';
import wixSetup from 'images/wix/wix-setup.png'
import screenshot1PNG from 'images/wix/1.png'
// import screenshot2PNG from 'images/wix/2.png'


const Wix = ({ mobileView }) => {
    const match = useRouteMatch()
    const history = useHistory()

    return (
        <div className='w-full'>
            <Heading>Integrate with Wix</Heading>

            <Content><b>Note:</b> This integration only works for WIX online stores.</Content>

            <CountItem
                number='1'>
                In your WIX editor, click “Dev Mode”. If you haven’t already, click “Enable Corvid” to access the Wix's built-in developement tool.</CountItem>

            <div className='flex items-center justify-center mb-4'>
                <img src={screenshot1PNG} alt='selecting Dev Mode in wix' />
            </div>

            <CountItem
                number='2'>
                {/* In the “Site Structure” panel on the left of your page, under Pages > Shop, click “Thank You Page”. */}
                On the left-end sidebar, click on the first panel called "<b>Page Code</b>", and select <b>"Main Pages</b>" {'>'} "<b>Shop</b>" {'>'} "<b>Thank You Page</b>".
            </CountItem>



            <CountItem
                number='3'>
                Click on the sample order details in the middle of the editor. This will open the properties panel. If the value after <b>ID</b> is not <b>thankYouPage1</b>, copy the displayed ID and replace it with “thankYouPage1” at the top of the code snippet below.
            </CountItem>

            {/* <div className='flex items-center justify-center mb-4'>
                <img src={screenshot2PNG} alt='thankYouPage1 in wix editor' />
            </div> */}
            <div className=''>
                <img style={{width: '510px'}} className='m-auto' src={wixSetup} alt='Thank you page in wix' />
            </div>

            {/* <CountItem
                number='4'>
                At the bottom of the page, click the bar that says “Site code”. On the left side of that panel that popped up, click on the <b>Site tab.</b>
            </CountItem> */}


            <CountItem
                number='4'>
                At the bottom of the page, there is a coding panel. Erase the existing code, and paste the following code in:
            </CountItem>

            <CodeBox
                copyText={`
const thankYouPageId = "thankYouPage1"; // Replace with your thank you page id if not thankYouPage1


import wixLocation from "wix-location";import { fetch } from "wix-fetch";import { local } from "wix-storage";$w.onReady(function () {let query = wixLocation.query;
let ref = query.ref;if (ref) {local.setItem("ref", ref);}const e = $w("#" + thankYouPageId);if (Array.isArray(e)) return;e.getOrder()
.then(e => {const refCode = local.getItem("ref");if (!refCode) return;/**e.appliedCoupon.code*/const subTotal = e.totals.subtotal - e.totals.discount;
const currency = e.currency; fetch("https://api.refmonkey.com/purchase-wix", {"method": "post","headers": {"Content-Type": "application/x-www-form-urlencoded",
"Authorization": ${'YOUR_ACCESS_TOKEN'}},
"body": "total_price="+subTotal+"&currency="+currency+"&ref_code="+refCode})}).catch(() => {})});`}
            ><div>
                    {<GrayCode>{'// Replace with your thank you page id if not thankYouPage1'}</GrayCode>}
                    {`const thankYouPageId = "thankYouPage1"; `}
                    {`

import wixLocation from "wix-location";import { fetch } from "wix-fetch";import { local } from "wix-storage";$w.onReady(function () {let query = wixLocation.query;
let ref = query.ref;if (ref) {local.setItem("ref", ref);}const e = $w("#" + thankYouPageId);if (Array.isArray(e)) return;e.getOrder()
.then(e => {const refCode = local.getItem("ref");if (!refCode) return;/**e.appliedCoupon.code*/const subTotal = e.totals.subtotal - e.totals.discount;
const currency = e.currency; fetch("https://api.refmonkey.com/purchase-wix", {"method": "post","headers": {"Content-Type": "application/x-www-form-urlencoded",
"Authorization": ${'YOUR_ACCESS_TOKEN'}},
"body": "total_price="+subTotal+"&currency="+currency+"&ref_code="+refCode})}).catch(() => {})});`}
                </div> </CodeBox>

            <CountItem
                number='6'>
                Hit “Publish” and you’re done!
            </CountItem>




        </div>
    );
};

Wix.propTypes = {

};

export default Wix;