import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'components/toggle'
import Text from 'components/text'
import Option from '../option';
import Button from 'components/button'
import TextInput from 'components/textinput'
import { BASE_URL, HTTP } from '../../../../constants';
import { ReactComponent as LinkSVG } from 'images/ext-link.svg'
import IconButton from 'components/iconbutton'


const ApproveAdvocateOption = ({ approveAdvocates, setApproveAdvocates }) => {
    return (

        <Option
            hasToggle
            title='Request Affiliate Approval'
            content={`affiliate accounts should first be approved before they can be active.`}
            toggleValue={approveAdvocates}
            onToggle={value => setApproveAdvocates(value)}
        />
    );
};

ApproveAdvocateOption.propTypes = {

};

export default ApproveAdvocateOption;