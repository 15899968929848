import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Text from "components/text";
import Button from "components/button";
import { ReactComponent as LinkSVG } from "images/ext-link.svg";
import Dialog from "components/dialog";
import CodeBox from "components/code-box";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_ADVOCATES,
  GET_CLICK_COUNT,
  GET_RECENT_ACTIVITY,
  ADD_REFERRAL,
  REVERSE_CONVERSION,
} from "./constants/GqlQueries";
import Loading from "components/loading";
import ErrorAlert from "components/alert/error";
import Card from "components/card";
import AdvocateItem from "./components/advocate-item";
import AddReferralForm from "./components/AddReferralForm";
import AddReferralSuccess from "./components/AddReferralSuccess";
import { BASE_URL, HTTP } from "../../constants";
import styled from "styled-components";
import Stat from "components/stat";
import { ReactComponent as ActiveAdvocatesSVG } from "images/active-advocates.svg";
import { ReactComponent as TotalAdvocatesSVG } from "images/total-advocates.svg";
import { ReactComponent as TotalReferralsSVG } from "images/total-referrals.svg";
import { GET_SETTINGS } from "../settings/constants/GqlQueries";
import Cvs from "./components/cvs";
import SortText from "components/sort-text";
import Pagination from "components/pagination";
import UndoSuccess from "./components/UndoSuccess";

const ScrollBarHider = styled.div`
  div::-webkit-scrollbar {
    display: none;
  }
`;

const CardWrapper = styled(Card)`
  min-width: 28rem;
`;

const DivWrapper = styled.div`
  min-width: 28rem;
`;

const SORT_BY_AFFILIATE = 1;
const SORT_BY_DATE = 2;
const SORT_BY_REFERRALS = 3;

const DashboardHome = ({ setWithHeadingView }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogAddReferral, setShowDialogAddReferral] = useState(false);
  const [affiliateUrl, setAffiliateUrl] = useState("");
  const [totalAdvocates, setTotalAdvocates] = useState(0);
  const [activeAdvocates, setActiveAdvocates] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [advocates, setAdvocates] = useState([]);

  const [identifier, setIdentifier] = useState("");
  const [referralName, setReferralName] = useState("");
  const [referredBy, setReferredBy] = useState("");
  const [showCustomReward, setShowCustomReward] = useState(false);
  const [showAddReferralError, setShowAddReferralError] = useState(false);
  //if the purchased is false, it will just request to /user endpoint, else request to /purchase
  const [customAdvocateReward, setCustomAdvocateReward] = useState("");
  const [referralWarningObject, setReferralWarningObject] = useState({
    identifier: "",
    referralName: "",
    referredBy: "",
    price: "",
  });
  const [step2, setStep2] = useState(false);
  const [conversionId, setConversionId] = useState("");
  const [undoConfirm, setUndoConfirm] = useState(false);
  const [undoSuccessStep, setUndoSuccessStep] = useState(false);
  const [showUndoError, setShowUndoError] = useState(false);

  const formFieldRef = useRef(null);

  const addReferralStates = {
    identifier,
    referralName,
    referredBy,
    showCustomReward,
    customAdvocateReward,
  };

  const addReferralSetStates = {
    setIdentifier,
    setReferralName,
    setReferredBy,
    setCustomAdvocateReward,
    setShowCustomReward,
  };

  const [sortBy, setSortBy] = useState(SORT_BY_DATE);

  const [affiliateAscending, setAffiliateAscending] = useState(true);
  const [referralsAscending, setReferralsAscending] = useState(true);
  const [dateAscending, setDateAscending] = useState(true);

  const { data, loading, error } = useQuery(GET_ADVOCATES);
  const { data: recentActivityData } = useQuery(GET_RECENT_ACTIVITY);

  const { data: settingsData, error: settingsError } = useQuery(GET_SETTINGS);
  const { data: clickCountData, error: clickCountError } = useQuery(
    GET_CLICK_COUNT
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const [showPagination, setShowPagination] = useState(false);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = advocates.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [addReferral, { loading: addReferralLoading }] = useMutation(
    ADD_REFERRAL,
    {
      onError: (err) => {
        console.log(err);
        setShowAddReferralError(true);
        if (formFieldRef.current) {
          formFieldRef.current.children[0].children[1].children[1].focus();
        }
      },
      onCompleted: ({ addReferral }) => {
        setConversionId(addReferral.conversionId);
        setStep2(true);
      },
    }
  );

  const [
    reverseConversion,
    { loading: reverseConversionLoading },
  ] = useMutation(REVERSE_CONVERSION, {
    onError: (err) => {
      console.log(err);
      setShowUndoError(true);
    },
    onCompleted: ({ reverseConversion }) => {
      setUndoSuccessStep(true);
    },
  });

  useEffect(() => {
    setWithHeadingView(
      <>
        <div className="flex flex-wrap justify-end items-center">
          <Button
            tailwind="md:mr-4 mb-3 mr-3"
            onClick={() => {
              if (
                identifier === "" &&
                referralName === "" &&
                referredBy === "" &&
                customAdvocateReward === "" &&
                !showCustomReward
              ) {
                setStep2(false);
              }
              setShowDialogAddReferral(true);
            }}
            size="small"
          >
            Add Referral
          </Button>
          <Button
            tailwind="md:mr-4 mb-3 mr-3"
            variant="outline"
            size="small"
            onClick={() => setShowDialog(true)}
            iconLeft={<LinkSVG />}
          >
            Share affiliate invite link
          </Button>
        </div>
      </>
    );
  }, []);

  useEffect(() => {
    //TODO this will become a problem when pagination is introduced. figure out a way to calculate accurates even with pagination..// possibly calculate from the server

    if (data) {
      let activeAdvocates = 0;
      let totalReferrals = 0;

      data.advocates.map(({ referralCount }) => {
        totalReferrals = totalReferrals + referralCount;
        if (referralCount > 0) {
          activeAdvocates++;
        }
      });

      setTotalAdvocates(data.advocates.length);
      setActiveAdvocates(activeAdvocates);
      setTotalReferrals(totalReferrals);
      setAdvocates(data.advocates);
      setShowPagination(data.advocates.length / postsPerPage > 1);
    }
  }, [data]);

  useEffect(() => {
    if (settingsData) {
      setAffiliateUrl(
        `${HTTP}${settingsData.settings.affDashboardUrl}.${BASE_URL}`
      );
    }
  }, [settingsData]);

  function sortByAffiliate() {
    setSortBy(SORT_BY_AFFILIATE);

    const sortedAdvocates = advocates.sort((a, b) => {
      if (affiliateAscending) {
        return a.identifier.localeCompare(b.identifier);
      } else {
        return b.identifier.localeCompare(a.identifier);
      }
    });
    setAdvocates([...sortedAdvocates]);
    setAffiliateAscending(!affiliateAscending);
  }

  function sortByReferrals() {
    setSortBy(SORT_BY_REFERRALS);

    const sortedAdvocates = advocates.sort((a, b) => {
      if (referralsAscending) {
        return a.referralCount - b.referralCount;
      } else {
        return b.referralCount - a.referralCount;
      }
    });
    setAdvocates([...sortedAdvocates]);
    setReferralsAscending(!referralsAscending);
  }

  function sortByDate() {
    setSortBy(SORT_BY_DATE);

    const sortedAdvocates = advocates.sort((a, b) => {
      if (dateAscending) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    });
    setAdvocates([...sortedAdvocates]);
    setDateAscending(!dateAscending);
  }

  function checkAddReferralForm() {
    let errorObject = {};
    let advocateNames = data.advocates.map((name) => name.identifier);
    if (identifier.trim() === "") {
      errorObject = {
        ...errorObject,
        identifier: "Referral ID field is required",
      };
    }

    if (referredBy.trim() === "") {
      errorObject = {
        ...errorObject,
        referredBy: "Referred By field is required",
      };
    } else if (!advocateNames.includes(referredBy)) {
      errorObject = {
        ...errorObject,
        referredBy: "The above Advocate ID does not exist",
      };
    }

    if (showCustomReward) {
      if (customAdvocateReward.trim() === "") {
        errorObject = {
          ...errorObject,
          customAdvocateReward: "Custom Reward field is required",
        };
      }
    }

    if (Object.keys(errorObject).length > 0) {
      setReferralWarningObject({ ...errorObject });
      return false;
    }
    return true;
  }

  function addReferralHandler() {
    setShowAddReferralError(false);
    setReferralWarningObject({
      identifier: "",
      referredBy: "",
      customAdvocateReward: "",
    });
    const passed = checkAddReferralForm();

    if (passed) {
      addReferral({
        variables: {
          identifier,
          name: referralName,
          referredBy,
          customReward: customAdvocateReward,
        },
      });
    }
  }

  function closeAddReferralFormHandler() {
    setShowDialogAddReferral(false);
    setIdentifier("");
    setReferredBy("");
    setCustomAdvocateReward("");
    setShowCustomReward(false);

    //watch this one later
    setShowUndoError(false);
    setConversionId("");
    setReferralName("");
    setUndoConfirm(false);
    setUndoSuccessStep(false);
    setShowUndoError(false);
    // setStep2(false);
  }

  function undoConfirmHandler() {
    setShowUndoError(false);
    setUndoConfirm(true);
  }

  function undoHandler() {
    setUndoConfirm(false);
    reverseConversion({ variables: { conversionId } });
    //call mutation to undo here
  }

  return (
    <div className="w-full h-full md:px-4 lg:px-6 ">
      <Dialog
        onCloseComplete={() => setShowDialog(false)}
        isShown={showDialog}
        variant="normal"
      >
        <div className="p-16">
          <ErrorAlert isShown={settingsError} />
          {settingsData && (
            <>
              <div className="flex justify-between items-center mb-6">
                <Text type="heading-small" tailwind="whitespace-no-wrap">
                  Share your link with your network
                </Text>

                <Button
                  tailwind="ml-6"
                  variant="secondary"
                  size="extra-small"
                  onClick={() => {
                    window.open(
                      affiliateUrl,
                      "_blank" // <- This is what makes it open in a new window.
                    );
                  }}
                >
                  View
                </Button>
              </div>
              <CodeBox tailwind="w-full">{affiliateUrl}</CodeBox>
            </>
          )}
        </div>
      </Dialog>

      <Dialog
        onCloseComplete={
          closeAddReferralFormHandler
          //     () => {

          //     // setShowDialogAddReferral(false)
          //     // if(step2) {
          //     //     setStep2(false);
          //     //     setUndoSuccessStep(false);
          //     //     setUndoConfirm(false);
          //     //     setConversionId("");
          //     }
          // }
        }
        shouldCloseOnOverlayClick={false}
        isShown={showDialogAddReferral}
        variant="normal"
      >
        <div className="p-16">
          <ErrorAlert isShown={settingsError} />

          {!step2 ? (
            settingsData && (
              <div>
                <Text tailwind="w-full text-center pb-3" type="heading">
                  Add a Referral Manually
                </Text>
                <ScrollBarHider className="">
                  <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                    <div className="mb-6">
                      <AddReferralForm
                        formFieldRef={formFieldRef}
                        showAddReferralError={showAddReferralError}
                        setReferralWarningObject={setReferralWarningObject}
                        advocates={data}
                        advocatesLoading={loading}
                        advocatesError={error}
                        referralWarningObject={referralWarningObject}
                        settingsData={settingsData}
                        addReferralStates={addReferralStates}
                        addReferralSetStates={addReferralSetStates}
                      />
                    </div>
                  </div>
                </ScrollBarHider>
                <div className="flex-column">
                  {!loading && !error && (
                    <div className="mb-3 pt-5">
                      <Button
                        enabled={!addReferralLoading}
                        tailwind="w-full flex justify-center items-center ml-2 md:mr-4 lg:mr-6"
                        onClick={addReferralHandler}
                        size="xl"
                      >
                        {addReferralLoading ? (
                          <Loading className="text-red-500" />
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </div>
                  )}

                  <div>
                    <button
                      className="w-full text-white flex justify-center focus:outline-none bg-red-700 py-3 rounded-lg items-center ml-2 md:mr-4 lg:mr-6"
                      onClick={() => setShowDialogAddReferral(false)}
                      size="xl"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : undoSuccessStep ? (
            <UndoSuccess
              closeAddReferralFormHandler={closeAddReferralFormHandler}
            />
          ) : (
            <AddReferralSuccess
              reverseConversionLoading={reverseConversionLoading}
              showUndoError={showUndoError}
              undoConfirmHandler={undoConfirmHandler}
              setUndoConfirm={setUndoConfirm}
              undoConfirm={undoConfirm}
              closeAddReferralFormHandler={closeAddReferralFormHandler}
              undoHandler={undoHandler}
            />
          )}
        </div>
      </Dialog>

      {loading && <Loading />}
      <ErrorAlert isShown={error} />

      {data && (
        <div className="mt-6">
          <DivWrapper className="grid grid-cols-3 gap-4 md:gap-8">
            <Stat
              icon={<TotalAdvocatesSVG />}
              showViewAll={false}
              centerValue={true}
              title="Total Affiliates"
              value={totalAdvocates}
            />

            <Stat
              showViewAll={false}
              centerValue={true}
              icon={<ActiveAdvocatesSVG />}
              title="Active Affiliates"
              value={activeAdvocates}
            />

            <Stat
              showViewAll={false}
              centerValue={true}
              icon={<TotalReferralsSVG />}
              title="Referrals"
              value={totalReferrals}
            />
          </DivWrapper>

          <CardWrapper tailwind="my-16" header={{ heading: "Recent activity" }}>
            <div className="p-8">
              <Text tailwind="text-center">
                {recentActivityData
                  ? recentActivityData.recentActivity
                  : "No Activity Yet..."}
              </Text>
            </div>
          </CardWrapper>

          <CardWrapper tailwind="my-16 p-6 flex justify-between items-center">
            {clickCountError ? (
              <ErrorAlert isShown={true} />
            ) : (
              <Text tailwind="text-center w-full">
                {clickCountData && clickCountData.clickCount ? (
                  <span>
                    <span className="text-gray-800">
                      Total number of clicks on referral links:{" "}
                      {clickCountData.clickCount}
                    </span>{" "}
                    clicks
                  </span>
                ) : (
                  "Recent clicks on referral links will show here"
                )}
              </Text>
            )}
          </CardWrapper>

          {/* @TODO: move to settings page */}
          {/* generate report to csv file */}
          {data.advocates.length > 0 && (
            <div className="my-12">
              <Cvs data={data.advocates} />
            </div>
          )}

          <div className="my-12">
            {data.advocates.length <= 0 ? (
              <div className="w-full h-full flex flex-col justify-center md:px-6 lg:px-8">
                <Text type="heading-small">Affiliates</Text>

                <Text>
                  Your affiliates will be the ones who will be promoting your
                  business. They can be bloggers, existing customers,
                  influencers or anyone who can send you potential new leads or
                  customers.
                </Text>
              </div>
            ) : (
              <div className="my-6">
                <Text tailwind="my-2 mb-4 mt-8" type="heading-small">
                  Affiliates
                </Text>

                <CardWrapper tailwind="">
                  <div
                    className={`grid grid-cols-12 py-4 px-6 border-b border-gray-200`}
                  >
                    <SortText
                      isAscending={affiliateAscending}
                      sort={sortBy === SORT_BY_AFFILIATE}
                      onClick={sortByAffiliate}
                      tailwind="col-span-6"
                    >
                      Affiliate
                    </SortText>

                    <SortText
                      isAscending={referralsAscending}
                      sort={sortBy === SORT_BY_REFERRALS}
                      onClick={sortByReferrals}
                      tailwind="col-span-2"
                    >
                      Referrals
                    </SortText>

                    <SortText
                      isAscending={dateAscending}
                      sort={sortBy === SORT_BY_DATE}
                      onClick={sortByDate}
                      tailwind="col-span-2"
                    >
                      Signed up
                    </SortText>
                  </div>

                  {currentPosts.map((item, index) => {
                    return (
                      <AdvocateItem
                        isGray={index % 2 === 0}
                        {...item}
                        index={index}
                        enableApproveAdvocate={
                          settingsData && settingsData.settings.approveAdvocates
                        }
                      />
                    );
                  })}

                  {showPagination && (
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={advocates.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  )}
                </CardWrapper>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DashboardHome.propTypes = {};

export default DashboardHome;
