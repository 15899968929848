import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import TextInput from 'components/textinput'
import DropDown from 'components/dropdown'
import DropDownItem from 'components/dropdown/dropdownitem'
import Button from 'components/button'
import RewardOption from '../reward-option';

const ShopifyRewardOption = ({ rewardName, rewardValue,
    onNameChange, onValueChange, onTypeChange, rewardType }) => {
    return (
        <RewardOption
            hasToggle={false}
            percentTypeHint=''
            otherTypeHint='Reward name can be anything.'
            title='Affiliate Reward'
            content='The type of reward affiliates should receive for referring customers to your store.'
            rewardName={rewardName}
            rewardValue={rewardValue}
            onNameChange={onNameChange}
            onValueChange={onValueChange}
            onTypeChange={onTypeChange}
            rewardType={rewardType}
        />
    );
};

ShopifyRewardOption.propTypes = {

};

export default ShopifyRewardOption;