import React, { useState } from 'react';
import Text from 'components/text'
import Settings from 'app/settings'
import Container from 'components/container'
import Logo from 'components/logo'
import LogoPNG from 'images/logo.png'
import Card from 'components/card'

import Setup from 'app/setup'
import { HTTP_URL } from 'constants/index.js'
import Button from 'components/button'
import GrayDiv from 'components/gray-div'
import { ReactComponent as ArrowRightSVG } from 'images/arrow-right.svg'
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import { getCurrentMonth } from '../../utils/dateUtil';
import Dialog from 'components/dialog'
import Welcome from './components/welcome';

const OnBoarding = () => {
    const [narrow, setNarrow] = useState(true)
    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
    const [saveText, setSaveText] = useState('Next')


    const match = useRouteMatch()
    const history = useHistory()

    return (
        <GrayDiv tailwind='min-h-screen'>


            <Container
                tailwind='flex flex-col justify-center items-center'
                narrow={narrow}>

                <Logo
                    clickable={false}
                    tailwind='my-6'
                    text='RefMonkey'
                    icon={LogoPNG}
                />

                <Card tailwind='p-4 w-full h-full min-h-screen'>
                    <Route path={`${match.path}/reward`}>
                        <>
                            <Welcome
                                description='Lets get you all setup.'
                            />

                            <Settings
                                manualSelectedTabIndex={tabSelectedIndex}
                                showTabs={false}
                                saveButtonTailwind='w-full'
                                onError={() => {
                                    console.log('onError')
                                    history.push(`${match.path}/install`)
                                }}
                                onSave={() => {
                                    switch (tabSelectedIndex) {
                                        case 0:
                                            setTabSelectedIndex(1)
                                            setSaveText('Installation')
                                            break
                                            
                                        default:
                                            history.push(`${match.path}/install`)
                                            break
                                    }
                                }}
                                saveText={saveText} />
                        </>
                    </Route>

                    <Route path={`${match.path}/install`}>
                        <>
                            <div className='mb-6'>
                                <Text type='heading-small'>Installation</Text>
                            </div>

                            <Setup
                                onJsDocs={() => setNarrow(false)}
                                onApiDocs={() => setNarrow(false)}
                                mobileView
                            />

                            <Text

                                variant='text'>
                                Want us to create your entire affiliate program for you using refmonkey? Schedule a time <span
                                    onClick={() => {
                                        window.open(
                                            'https://calendly.com/steven-239/refmonkey',
                                            '_blank' // <- This is what makes it open in a new window.
                                        )
                                    }}
                                    className='cursor-pointer underline text-indigo-400 hover:text-indigo-300'>here </span><span className="text-xs"> (Limited time, only available for rest of {getCurrentMonth()}</span>)
                               </Text>

                            <Button
                                iconRight={<ArrowRightSVG />}
                                onClick={() => window.location.href = HTTP_URL}
                                tailwind={`my-8 w-full`}>Start tracking referrals</Button>

                        </>
                    </Route>
                </Card>
            </Container>
        </GrayDiv>
    );
};

export default OnBoarding;