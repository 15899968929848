import React, { useState } from 'react';
import FieldInput from 'components/fieldinput'
import Form from 'components/form'
import Button from 'components/button'
import Card from 'components/card'
import Container from 'components/container'
import ErrorAlert from 'components/alert/error';
import { isValidPassword } from '../../components/signin/utils';
import GrayDiv from 'components/gray-div'
import useRegister from '../../components/signin/components/register/hooks/useRegister';

const ShopifyCreateAccount = () => {
    const [password, setPassword] = useState('')
    const [shortPassword, setShortPassword] = useState(false)
    const [showError, setShowError] = useState(false)

    const { register, loading } = useRegister(
        () => { },
        () => setShowError(true),
    )

    const handleFormSubmit = () => {
        let error = false
        setShortPassword(false)
        setShowError(false)

        if (!isValidPassword(password)) {
            setShortPassword(true)
            error = true
        }

        const url = new URL(window.location.href);
        const email = url.searchParams.get("email");

        if (!email) {
            setShowError(true)
        }

        if (!error) {
            register(email, password, true)
        }
    }

    return (
        <GrayDiv
            tailwind='min-h-screen w-full'
            id='wrapper-forgot-password '>
            <Container
                tailwind='flex flex-col items-center justify-center w-full'
                narrow>



                <ErrorAlert
                    isShown={showError}
                    id='error'
                />



                <Card tailwind='p-8 w-full mt-2'>
                    <Form
                        id='form'
                        onSubmit={handleFormSubmit}>

                        <FieldInput
                            helpText={shortPassword ? 'minimum 6 characters' : ''}
                            onChange={(value) => setPassword(value)}
                            tailwind=''
                            id='password-input'
                            label='Password'
                            type='password'
                        />


                        <Button
                            id='submit'
                            typeSubmit
                            loading={loading}
                            tailwind='mt-4 w-full'
                        >Create Account</Button>
                    </Form>
                </Card>

            </Container>
        </GrayDiv>
    );
};

export default ShopifyCreateAccount;