import React, { useEffect, useState } from "react";
import Text from "components/text";
import CodeBox from "components/code-box";
import Button from "components/button";
import Card from "components/card";
import Container from "components/container";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  GET_ADVOCATE,
  GET_REFERRALS,
  GET_SETTINGS,
  GET_PENDING_REWARDS,
  GET_REDEEMED_REWARDS
} from "../constants/GqlQueries";
import ErrorAlert from "components/alert/error";
import GrayDiv from "components/gray-div";
import { useLocation, useHistory } from "react-router-dom";

import styled from "styled-components";
import { signout, getAdvocate } from "../../affiliate-login/utils/authUtil";
import Tab from "components/tab";
import Dashboard from "../components/dashboard";
import Reward from "../components/reward";
import Payouts from "../components/payouts";
import Referrals from "../components/referrals";
import Settings from "../components/settings";
import { FormattedMessage } from "react-intl";

const Wrapper = styled(GrayDiv)`
  min-width: 28rem;
`;

const AffiliateDashboardContent = ({
  toRight,
  advocateData,
  setRefCode,
  referralDashboardCode,
  refCode,
  settingsData,
  settingsError,
  settingsLoading,
  intl,
}) => {
  const tabs = [
    // change LANGUAGE
    { id: "dashboard", label: <FormattedMessage id="aff.label.dashboard" /> },
    { id: "referrals", label: <FormattedMessage id="aff.label.referrals" /> },
    { id: "rewards", label: <FormattedMessage id="aff.label.rewards" /> },
    { id: "payouts", label: <FormattedMessage id="aff.label.payouts" /> },
    { id: "settings", label: <FormattedMessage id="aff.label.settings" /> },
  ];

  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [
    getReferrals,
    { data: referralsData, error: referralsError, loading: referralsLoading },
  ] = useLazyQuery(GET_REFERRALS, {
    variables: {
      refCode,
      referralDashboardCode,
    },
  });

  useEffect(() => {
    getReferrals();
  }, []);

  const getSelectedComponent = () => {
    let component;

    switch (selectedTab.id) {
      case "dashboard":
        component = (
          <Dashboard
            toRight={toRight}
            rewardsData={settingsData}
            setRefCode={setRefCode}
            refCode={refCode}
            referralDashboardCode={referralDashboardCode}
            rewardsLoading={settingsLoading}
            rewardsError={settingsError}
          />
        );
        break;
      case "rewards":
        component = (
          <Reward
            toRight={toRight}
            settingsData={settingsData}
            refCode={refCode}
            referralDashboardCode={referralDashboardCode}
          />
        );
        break;

      case "payouts":
        component = (
          <Payouts
            toRight={toRight}
            refCode={refCode}
            referralDashboardCode={referralDashboardCode}
          />
        );
        break;
      case "referrals":
        component = (
          <Referrals
            toRight={toRight}
            referralsData={referralsData}
            referralsLoading={referralsLoading}
            referralsError={referralsError}
          />
        );
        break;
      case "settings":
        component = (
          <Settings
            toRight={toRight}
            enablePaypal={
              settingsData && settingsData.advocatePanelSettings.enablePaypal
            }
            advocateData={advocateData}
            referralDashboardCode={referralDashboardCode}
          />
        );
        break;

      default:
        break;
    }
    return component;
  };

  return (
    <Wrapper tailwind="min-h-screen py-4">
      <Container narrow tailwind="h-full">
        <div className="w-full flex justify-between my-4">
          {/* NAME OF THE SELECTED TAB CHANGE LANGUAGE */}
          <Text type="heading">{selectedTab.label}</Text>

          <div className="flex items-center">
            {advocateData && advocateData.advocate && (
              <div className="mx-4">
                {/* name of the affiliate CHANGE LANGUAGE */}
                <Text tailwind="font-medium">{advocateData.advocate.name}</Text>
              </div>
            )}
            <Button
              size="small"
              variant="outline"
              onClick={() => {
                signout();
                history.replace(`/auth`);
              }}
            >
              <FormattedMessage id="aff.signout" />
            </Button>
          </div>
        </div>

        <div className="flex border-b border-gray-300 mt-6">
          {tabs.map((tabItem, index) => (
            <Tab
              key={tabItem.id}
              id={index}
              variant="underline"
              onClick={() => {
                setSelectedTab(tabItem);
              }}
              isSelected={tabItem.id === selectedTab.id}
            >
              {tabItem.label}
            </Tab>
          ))}
        </div>

        {getSelectedComponent()}
      </Container>
    </Wrapper>
  );
};

export default AffiliateDashboardContent;
