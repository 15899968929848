import { useHistory, useLocation } from "react-router-dom"
import { useQuery } from '@apollo/react-hooks'
import { GET_USER } from "./constants/GqlQueries"
import { useEffect } from 'react'

/**
 * checks if the credit card details are already submitted
 * if not then redirect to the cc page
 */
const useCheckCC = () => {
    const history = useHistory()
    const location = useLocation()

    const { data } = useQuery(GET_USER)

    useEffect(() => {



        //leave cc page if already submitted cc
        if (data && data.user && !data.user.showCC && location.pathname === '/cc') {
            history.push('/')
        }

        checkToRedirect()

    }, [data])


    /**
     * checks whether to redirect to cc page
     */
    const checkToRedirect = () => {

        console.log({ pathname: location.pathname })
        if (data
            && data.user
            && data.user.showCC
            && location.pathname != '/redeem-saastraunatics'
            && location.pathname != '/create-account-saastronautics'
        ) {
            if (location.pathname != '/cc') {
                history.replace('/cc')
            }
        }
    }

    useEffect(() => {


        history.listen((location => {
            checkToRedirect()

        }))

    }, [])


}

export default useCheckCC