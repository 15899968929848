import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {
    return (
        <Request
                    id='redeem-reward'
                    title='Redeem reward'
                    description='redeem the reward of a user who has a reward pending'
                    codeSnippet={`    window.refMonkeyClient.redeemReward(identifier, type)`}
                    parameters={
                        <>
                            <ParamItem
                                required={true}
                                title='identifier'
                                type='string'
                                content={`A unique identifier for the user in your system. This can be an email address, username or id`}
                            />
                            <ParamItem
                                required={true}
                                title='type'
                                type='string'
                                content={`can be 'advocate' or 'referral'`}
                            />
                        </>
                    }
                />
    );
};

DocRequest.propTypes = {
    
};

export default DocRequest;