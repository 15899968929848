import React, { useEffect, useState, useContext } from 'react';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { GET_ADVOCATE, GET_IS_ARCHIVED, GET_SETTINGS } from './constants/GqlQueries';
import ErrorAlert from 'components/alert/error'
import GrayDiv from 'components/gray-div'
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { signout, getAdvocate } from '../affiliate-login/utils/authUtil';

import AffiliateDashboardContent from './content';

import gql from 'graphql-tag'
import { Context } from '../../languages/language-context'
import { getSubDomain } from '../../utils/urlUtil';
import PendingApproval from './components/pending-approval';
import ArchivedCampaign from './components/archived-campaign';
import DisabledAccount from './components/disabled-account';

const Wrapper = styled(GrayDiv)`
    min-width: 28rem;
    
`
const GET_LANGUAGE_SETTING = gql`
query getLanguage($referralDashboardCode: String) {
    getLanguage(referralDashboardCode: $referralDashboardCode)
}
`

const AffiliateDashboard = () => {

    const history = useHistory()
    const [refCode, setRefCode] = useState()
    const [referralDashboardCode, setReferralDashboardCode] = useState()
    const context = useContext(Context);

    const languageInfo = useQuery(GET_LANGUAGE_SETTING, {
        variables: {
            referralDashboardCode: getSubDomain()
        }
    });

    const [getSettings, { data: settingsData, error: settingsError, loading: settingsLoading }] = useLazyQuery(GET_SETTINGS, {
        variables: {
            refCode,
            referralDashboardCode
        }
    })


    const [isArchivedQuery, { data: archivedData }] = useLazyQuery(GET_IS_ARCHIVED, {
        variables: {
            referralDashboardCode
        },
        onCompleted: () => {
            console.log('completed')
        }
    })


    const [getAdvocateQuery, { data: advocateData }] = useLazyQuery(GET_ADVOCATE, {
        variables: {
            refCode,
            referralDashboardCode
        }
    })

    useEffect(() => {

        if (languageInfo.data) {
            context.changeLanguage(languageInfo.data.getLanguage);
        }
    }, [languageInfo.data])


    useEffect(() => {
        const advocate = getAdvocate()
        setRefCode(advocate.refCode)
        setReferralDashboardCode(advocate.dashboardCode)

        getSettings()
        isArchivedQuery()
        getAdvocateQuery()
    }, [])


    return (
       (archivedData && archivedData.isArchived) ?
            <ArchivedCampaign />
            :
            (settingsData && settingsData.advocatePanelSettings.approveAdvocates && advocateData && advocateData.advocate && !advocateData.advocate.approved) ?
                <PendingApproval />

                :
                ((advocateData && advocateData.advocate && advocateData.advocate.isDisabled) ?

                    <DisabledAccount />
                    :
                    <AffiliateDashboardContent
                        toRight={context.local === 'he'}
                        setRefCode={setRefCode}
                        referralDashboardCode={referralDashboardCode}
                        refCode={refCode}
                        settingsData={settingsData}
                        settingsError={settingsError}
                        settingsLoading={settingsLoading}
                        advocateData={advocateData}
                    />
                )
    );
};

export default AffiliateDashboard;