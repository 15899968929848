import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'

const CountItem = ({ number, children ,tailwind}) => {
    return (
        <div className={`flex mt-8 mb-4 flex-shrink ${tailwind}`}>
            <div>
                <div className='rounded-full bg-green-400 p-2 w-8 h-8 flex items-center justify-center'>
                    <Text
                        color
                        tailwind='text-white font-medium'>{number}</Text>
                </div>
            </div>
            <div className='px-6'>{children}</div>
        </div>
    );
};

CountItem.propTypes = {

};

export default CountItem;