import { useEffect, useState } from 'react'
import { GET_SETTINGS,GET_DASHBOARD_CODE } from 'app/affilate-dashboard/constants/GqlQueries';
import { useLazyQuery } from '@apollo/react-hooks';
import { getSubDomain } from 'utils/urlUtil';
import URI from 'urijs'
import { BASE_URL, HTTP } from '../../../constants';

const useDashboardCode = () => {

    const [referralDashboardCode, setReferralDashboardCode] = useState('')

    const [getSettingsMutate, { data: settingsData }] = useLazyQuery(GET_SETTINGS)

    const [getDashboardCode, { data: dashboardCodeData,error: customDomainError }] = useLazyQuery(GET_DASHBOARD_CODE)


    const getSettings = (dashboardCode) => {
        getSettingsMutate({
            variables: {
                referralDashboardCode: dashboardCode
            }
        })
    }

    useEffect(() => {
        const url = window.location.href
        const mainDomain = new URI(url).domain()

        console.log({ mainDomain })
        
        //HERE MIGHT BE THE PROBLEM FOR DISPLAYING WRONG BUSINESS LOGO IN PRODUCTION
        if (mainDomain === process.env.REACT_APP_BASE_URL) {
            const subDomain = getSubDomain()
            setReferralDashboardCode(subDomain)

            getSettings(subDomain)
        } else {
            //query the dashboard code from the url
            let cleanedCustomDomain = url.trim()
            cleanedCustomDomain = cleanedCustomDomain.replace(HTTP,'')
            cleanedCustomDomain = cleanedCustomDomain.slice(0,cleanedCustomDomain.indexOf('/'))
    
            getDashboardCode({
                variables: {
                    customDomain: cleanedCustomDomain
                }
            })
        }
    }, [])

    useEffect(() => {

        if (dashboardCodeData) {
            const subDomain = dashboardCodeData.referralDashboardCode
            setReferralDashboardCode(subDomain)
            getSettings(subDomain)
        }

    }, [dashboardCodeData])



    return { settingsData, referralDashboardCode,customDomainError }
}

export default useDashboardCode