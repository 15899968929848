import React, { useEffect, useState, useRef,useContext} from 'react';
import AffiliateLogin from '../affiliate-login';
import AffiliateDashboard from '../affilate-dashboard';
import { isLoggedIn, getAdvocate } from '../affiliate-login/utils/authUtil';
import { useHistory, useRouteMatch, useLocation, Route } from 'react-router-dom';


const Affiliates = () => {

    const history = useHistory()
    const location = useLocation()

    
    history.listen(() => {

    })
    //later uselazyquery and call method inside useeffect and get the language from the database based on the aff url
    //be aware of changing language after default language is loaded, should be loaded selected langauge in the first try
 

    useEffect(() => {
        const loggedIn = isLoggedIn()

        if (loggedIn) {
            history.replace(`/home`)

        } else {

            const paths = location.pathname.split('/')
            if (paths[2] !== 'reset') {
                history.replace(`/auth`)
            }
        }
    }, [])


    return (

        <div>
            <Route path={`/auth`}>
            
                <AffiliateLogin />
       
            </Route>

            <Route path={`/home`}>
         
                <AffiliateDashboard />
            
            </Route>

        </div>
    );
};

export default Affiliates;