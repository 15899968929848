import React from 'react';
import PropTypes from 'prop-types';
import SuccessAlert from 'components/alert/success'
import Alert from 'components/alert'
import ErrorAlert from 'components/alert/error'

const Alerts = ({ states }) => {
    return (
        <div>
            {states.showSetError &&
                <ErrorAlert
                    tailwind='my-4'
                    isShown={true}
                />
            }

            <Alert
                content='The affiliate signup url is invalid.'
                variant='warning'
                tailwind='mb-2'
                isShown={states.showInvalidAffDashboardLink} />
            <Alert
                content='The affiliate signup url is already taken.'
                variant='warning'
                tailwind='mb-2'
                isShown={states.showTakenAffDashboardLink} />

            <SuccessAlert
                content='Saved'
                tailwind='my-4'
                isShown={states.showSuccess}
            />
        </div>
    );
};

Alerts.propTypes = {

};

export default Alerts;