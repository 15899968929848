import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Toggle from 'components/toggle'
import Text from 'components/text'
import Option from '../option';
import Button from 'components/button'
import TextInput from 'components/textinput'
import { BASE_URL, HTTP } from '../../../../constants';
import { ReactComponent as LinkSVG } from 'images/ext-link.svg'
import IconButton from 'components/iconbutton'
import { useRouteMatch } from 'react-router-dom';
import CustomDomain from '../custom-domain';


const AffUrlOption = ({ affDashboardUrl, setAffDashboardUrl,customDomain, setCustomDomain }) => {
    const [showCustomDomainSettings, setShowCustomDomainSettings] = useState(false)

    return (

        <Option
            toggleValue={true}
            title='Affiliate signup url'
        >
            <div className=''>

                <div className='flex items-center'>
                    <TextInput
                        tailwind='w-auto my-2 max-w-sm'
                        onChange={value => setAffDashboardUrl(value)}
                        value={affDashboardUrl} />

                    <Text tailwind='font-medium text-gray-700'>.{BASE_URL}</Text>
                </div>
                <div className='flex my-4 '>
                   
                    <Button
                        size='extra-small'
                        variant='secondary'
                        tailwind='mr-4 md:mr-6'
                        onClick={() => {
                            window.open(
                                `${HTTP}${affDashboardUrl}.${BASE_URL}`,
                                '_blank' // <- This is what makes it open in a new window.
                            )
                        }
                        }
                    >Preview</Button>

                    

<Button
                        size='extra-small'
                        variant='secondary'
                        onClick={() => setShowCustomDomainSettings(!showCustomDomainSettings)}
                    >
                        Custom Domain
                    </Button>
                    
                </div>
            </div>

            {showCustomDomainSettings ?
                <CustomDomain
                customDomain={customDomain}
                 setCustomDomain={setCustomDomain}
                affDashboardUrl={affDashboardUrl} />
                :
                <Text type='text-small'>This is the url that affiliates will signup to your referral program from. </Text>
            }
        </Option>
    );
};

AffUrlOption.propTypes = {

};

export default AffUrlOption;