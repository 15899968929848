import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from '../count-item'
import CodeBox from 'components/code-box'
import GrayCode from 'components/code-box/gray-code'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import Button from 'components/button'
import Text from 'components/text'
import { HashLink } from 'react-router-hash-link';
import useGetAccessToken from 'app/setup/hooks/useGetAccessToken';
import TextInput from 'components/textinput/index'
import form_id from 'images/webflow/webflow_form_id.png';
import email_id from 'images/webflow/webflow_email_id.png';
import snippet_location from 'images/webflow/webflow_code_snippet_location.png'



const Webflow = ({ mobileView }) => {
  const match = useRouteMatch()
  const history = useHistory()
  const { data } = useGetAccessToken()
  
  const [formId, setFormId] = useState('');
  const [email, setEmail] = useState('');

  const button = (
    <Button
                tailwind={`w-full  ${!mobileView && 'md:w-auto'}`}
                variant='outline'
                size='extra-small'
                onClick={() => history.goBack()}
        >View all</Button>
  )

    return (
      <div className='w-full'>
        {data ? button:null}
        
            <Heading>Integrate with Webflow</Heading>
            
            <CountItem
                number='1'>
                On Webflow, go to your sign up page, click the form section, go to setting, copy the ID and paste it here: <TextInput onChange={value => setFormId(value)} />
            </CountItem>
        
            <div className=''>
                <img style={{width: '510px'}} className='m-auto' src={form_id} alt='form id location' />
            </div>
        
            <CountItem
                number='2'>
                Click on email column, go to setting, copy the ID and paste it here: <TextInput onChange={ value=> setEmail(value)} />
            </CountItem>
        
            <div className=''>
                <img style={{width: '510px'}} className='m-auto' src={email_id} alt='E-mail ID location' />
            </div>

            <CountItem
          number='3'>
                Paste the following code snippet into <b>"Pages"</b> {'>'} <b>"Referral"</b> before the closing <b>{'</'}body{'>'}</b> tag
        </CountItem>
        
        <div className='my-6'>
          <CodeBox tailwind='py-30'>{`
          <script src="https://cdn.refmonkey.com/refmonkey.js"></script>
          <script type="text/javascript">window.refMonkeyClient = RefMonkey.client(${data ? '"' + data.generateAccessToken + '"' : 'YOUR_ACCESS_TOKEN'})</script>
          <script>const baseURL = "https://api.refmonkey.com";const refForm = document.getElementById("${formId}");const referralEmail = document.getElementById("${email}");const refCode = window.location.href.split("ref=")[1];const handleReferralSubmit = async (event) => {// event.preventDefault();const identifier = referralEmail.value;const refData = {identifier,ref_code: refCode,};const registerReferral = await fetch(\`\${baseURL}/user\`, {method: "POST",mode: "cors",headers: {"Content-Type": "application/json",Authorization:${data ? '"' + data.generateAccessToken + '"' : 'YOUR_ACCESS_TOKEN'},},body: JSON.stringify(refData), });};refForm.addEventListener("submit", handleReferralSubmit);</script>>
          `}
          </CodeBox> 
        </div>  
          
        
            <div className=''>
                    <img style={{width: '510px'}} className='m-auto' src={snippet_location} alt='code snippet location' />
            </div>
        
            <CountItem
                number='4'>
                You are all done!
            </CountItem>
        </div>
    );
};

Webflow.propTypes = {

};

export default Webflow;