import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FireworkSVG } from 'images/firework.svg'
import Icon from 'components/icon'
import Text from 'components/text'

const Welcome = ({ description }) => {
    return (
        <div className='mb-6 md:px-4'>
            <div className='flex w-full items-center'>
                <Text type='heading-small'>Welcome to Refmonkey!</Text>

                <Icon
                    size
                    tailwind='transform rotate-45 w-12 h-12 p-2'
                    hasBackground={false}
                    src={<FireworkSVG />}
                />
            </div>


            <Text tailwind='font-medium'>{description}</Text>
        </div>
    );
};

Welcome.propTypes = {

};

export default Welcome;