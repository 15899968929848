import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import Option from '../option';
import Button from 'components/button'
import TextInput from 'components/textinput'

const AffTerms = ({ enableTerms, setEnableTerms, termsUrl, setTermsUrl }) => {

    return (
        <Option
            hasToggle
            title='Affiliate Terms'
            content='A terms of service contract for affiliate to agree to before creating an affiliate account.'
            toggleValue={enableTerms}
            onToggle={value => setEnableTerms(value)}
        >

            <Text
                textSize
                tailwind='text-gray-700 text-sm  font-semibold'>
                Affiliate Terms URL
                    </Text>

            <TextInput
            placeholder='mysite.com/affiliate-tos'
                tailwind='w-64'
                value={termsUrl}
                onChange={(value) => setTermsUrl(value)}
            />

        </Option>
    )
};

AffTerms.propTypes = {

};

export default AffTerms;