import React from 'react';
import Content from '../components/content';
import Footer from '../../landing/components/footer';
import Header from '../../landing/components/header';
import CTA from 'components/cta/simple-centered'
import Text from 'components/text'
import { useHistory } from 'react-router-dom'

const RefProgram = () => {
    const history = useHistory()

    return (


        <div>
            <Header />
            <div className='p-8 md:px-16 lg:px-24'>



                <Text
                    tailwind='my-12'
                    type='heading'>RefMonkey Referral Program</Text>
                <Content />

                <div
                    className='mt-12 mb-24'
                >
                    <CTA
                        content='Get started today'
                        showSecondaryButton={false}
                        onPrimaryButtonClicked={() => history.push('/create-account')}
                    />

                </div>


            </div>
            <Footer />
        </div>
    );
};

export default RefProgram;