import { gql } from 'apollo-boost'

export const GET_ADVOCATES = gql`
query{
 advocates{
     name
    identifier
    referralCount
    date
    approved
    isDisabled
 }
}`

export const GET_RECENT_ACTIVITY = gql`
query{
    recentActivity
}`


export const GET_CLICK_COUNT = gql`
query{
    clickCount
}`





export const DELETE_ADVOCATE = gql`
mutation($identifier: String){
    deleteAdvocate(identifier: $identifier)
}`


export const APPROVE_ADVOCATE = gql`
mutation($identifier: String){
    approveAdvocate(identifier: $identifier)
}`

export const DISABLE_ADVOCATE = gql`
mutation($identifier: String, $isDisabled: Boolean){
    disableAdvocate(identifier: $identifier,isDisabled : $isDisabled)
}`

export const ADD_REFERRAL = gql`
mutation addReferral($identifier:String!,$name:String,$referredBy:String!,$customReward:String) {
    addReferral(identifier:$identifier,name:$name,referredBy:$referredBy,customReward:$customReward) {
        success
        conversionId
    }
}
`

export const REVERSE_CONVERSION = gql`
mutation reverseConversion($conversionId:String!) {
    reverseConversion(conversionId:$conversionId)
}
`

