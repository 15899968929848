import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card'
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_CLICK_COUNT } from '../../constants/GqlQueries';
import Text from 'components/text'
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'
import {FormattedMessage} from 'react-intl'

const ClickCount = ({ referralDashboardCode, refCode }) => {

    const [getClickCount, { data, loading, error }] = useLazyQuery(GET_CLICK_COUNT, {
        variables: {
            refCode,
            referralDashboardCode
        }
    })


    useEffect(() => {
        if (referralDashboardCode && refCode) {
            getClickCount()
        }
    }, [refCode, referralDashboardCode])

    return (
        <Card tailwind='w-full p-6 flex justify-between items-center'>
            {error ?
                <ErrorAlert
                    isShown={true} />
                :
                <Text tailwind='text-center w-full'>{(data && data.advocateClickCount) ? <span><span className='text-gray-800 font-medium'><FormattedMessage id="referralclickcount"/>  {data.advocateClickCount}</span> <FormattedMessage id="clicks"/></span> : <FormattedMessage id="noreferralclick"/>}</Text>
            }
        </Card>

    );
};

ClickCount.propTypes = {

};

export default ClickCount;