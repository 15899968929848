import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card'

import Text from 'components/text'

const Button = ({ children, image, onClick }) => {
    return (
        <Card
            onClick={onClick}
            tailwind='w-full py-6 cursor-pointer hover:bg-gray-100 flex flex-col items-center justify-center'>
            <div className='rounded-t-lg w-24 h-24 my-12'>
                {image}
            </div>
            <Text tailwind='w-full text-center font-medium'
            > {children}</Text>
        </Card>
    );
};

Button.propTypes = {

};

export default Button;