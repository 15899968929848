import React, { useState, useEffect } from "react"
import Text from 'components/text'
import styled from 'styled-components'
import Button from 'components/button'
import { URL, DELETE_URL } from '../constants/GqlQueries'
import { useMutation } from "@apollo/react-hooks";


const Wrapper = styled.div`
    background-color: ${({ theme, isGray }) => isGray ? theme.gray50 : 'white'};
`

const Url = props => {

  const [deleteUrl, { data ,loading}] = useMutation(DELETE_URL, {
    onError: ()=>{},
		variables: {
      url: props.url
    },
    refetchQueries: [
      { query: URL,
        }
    ]
  })

	return (
			<div>
				<Wrapper
					isGray={props.isGray}
					className=' flex py-4 px-6 '>

					<div className='w-full grid grid-cols-4 flex w-full justify-end'>
            <Text tailwind='col-span-3 break-all mr-2'>{props.url}</Text>
            <div className='col-span-1 flex items-center justify-center'>
              <Button
              loading={loading}
                tailwind='mx-2'
              onClick={()=>deleteUrl()}
                size='extra-small'
                variant='outline'>Delete</Button>
            </div>
          </div>
				</Wrapper>
			</div>
	)
}

export default Url;