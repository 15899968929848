import React from 'react';
import PropTypes from 'prop-types';
import AffUrlOption from '../../components/aff-url-option';
import Option from '../../components/option';
import RequestPayoutOption from '../../components/request-payout-option';
import AffTerms from '../../components/aff-terms';
import ApproveAdvocate from '../../components/approve-advocate';
import BusinessLogo from '../../components/businesslogo/businesslogo';
import LanguageOptions from '../../components/language-option/LanguageOptions';



const AffDashboard = ({ states, setStates }) => {
    return (
        <div>
            <AffUrlOption
                affDashboardUrl={states.affDashboardUrl}
                setAffDashboardUrl={setStates.setAffDashboardUrl}
                setCustomDomain={setStates.setCustomDomain}
                customDomain={states.customDomain}
            />

            <BusinessLogo affDashboardUrl={states.showBusinessLogoaffDashboardUrl} />



            <ApproveAdvocate
                approveAdvocates={states.approveAdvocates}
                setApproveAdvocates={setStates.setApproveAdvocates}
            />

            <RequestPayoutOption
                requestPayout={states.requestPayout}
                setRequestPayouts={setStates.setRequestPayout}
                payoutMinDays={states.payoutMinDays}
                setPayoutMinDays={setStates.setPayoutMinDays}
            />

            <AffTerms
                enableTerms={states.enableTerms}
                setEnableTerms={setStates.setEnableTerms}
                termsUrl={states.termsUrl}
                setTermsUrl={setStates.setTermsUrl}
            />

            <LanguageOptions language={states.language} setLanguage={setStates.setLanguage} />



        </div>
    );
};

AffDashboard.propTypes = {

};

export default AffDashboard;