import React, { useState, useEffect } from 'react';
import Content from '../components/content';
import Codebox from 'components/code-box'
import { getEmail } from 'utils/authUtil'
import Text from 'components/text'

const RefProgram = () => {
    const [referralLink, setReferralLink] = useState('')
    const [showCouponCode, setShowCouponCode] = useState(false)

    useEffect(() => {
        const email = getEmail()

        window.refMonkeyClient.getUrlFor(email, (success, url) => {
            if (success) {
                setReferralLink(url)
            }
        })

                            
        window.refMonkeyClient.advocateRewardStatus(email,({status})=>{ 
            if (status === 'pending' || status === 'rewarded') {
                setShowCouponCode(true)
            }
        })
    })

    return (
        <div>
            <Content />

            <Text
                color
                tailwind='mt-8 text-gray-700'
                type='heading-small'>Your RefMonkey referral link</Text>
            <div className='mb-8'>
                <Codebox>{referralLink}</Codebox>
            </div>

            {showCouponCode &&
                <>
                    <Text
                        color
                        tailwind='mt-8 text-gray-700'
                        type='heading-small'>monthly plan 30% off coupon code</Text>
                    <div className='mb-8'>
                        <Codebox>BIG_THANK_YOU</Codebox>
                    </div>
                </>
            }

        </div>
    );
};

export default RefProgram;