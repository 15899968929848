import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'components/toggle'
import Text from 'components/text'


const Option = ({ hasToggle, children, toggleValue, onToggle, title, content }) => {

    return (
        <div>
            <div>
                <div className='flex justify-between my-6  items-center'>
                    <div>
                        <Text color
                            tailwind='font-medium text-gray-700'>{title}</Text>
                        <Text type='text-small'>{content}</Text>
                    </div>

                    {hasToggle &&
                        <Toggle
                            tailwind='ml-4'
                            onToggle={value => onToggle(value)}
                            value={toggleValue} />
                    }
                </div>

                {children && (hasToggle ? toggleValue : true) &&
                    <div className='mt-4 mb-2 ml-6'>
                        {children}
                    </div>
                }
            </div>
            <div className='w-full h-px border-t border-gray-200' />

        </div>
    );
};

Option.propTypes = {

};

export default Option;