import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loading from "components/loading";
import ErrorAlert from "components/alert/error";
import ReferralItem from "../referral-item";
import Card from "components/card";
import Text from "components/text";
import SortText from "components/sort-text";
import Pagination from "components/pagination";
import { FormattedMessage } from "react-intl";

const SORT_BY_REFERRALS = 1;
const SORT_BY_CREATED_DATE = 2;

const Referrals = ({ referralsData, referralsLoading, referralsError }) => {
  const [referrals, setReferrals] = useState([]);
  const [referralsAscending, setReferralsAscending] = useState(true);
  const [dateAscending, setDateAscending] = useState(true);
  const [sortBy, setSortBy] = useState(SORT_BY_CREATED_DATE);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const [showPagination, setShowPagination] = useState(false);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = referrals.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (referralsData) {
      setReferrals(referralsData.referrals);
      setShowPagination(referralsData.referrals.length / postsPerPage > 1);
    }
  }, [referralsData]);

  function sortByReferral() {
    setSortBy(SORT_BY_REFERRALS);

    const sortedReferrals = referrals.sort((a, b) => {
      if (referralsAscending) {
        return a.identifier.localeCompare(b.identifier);
      } else {
        return b.identifier.localeCompare(a.identifier);
      }
    });

    setReferrals([...sortedReferrals]);
    setReferralsAscending(!referralsAscending);
  }

  function sortByDate() {
    setSortBy(SORT_BY_CREATED_DATE);

    const sortedReferrals = referrals.sort((a, b) => {
      if (dateAscending) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    });
    setReferrals([...sortedReferrals]);
    setDateAscending(!dateAscending);
  }

  return (
    <div>
      {referralsLoading && (
        <div className="w-full h-full min-h-64 flex items-center justify-center">
          <Loading />
        </div>
      )}

      <ErrorAlert isShown={referralsError} />

      {referralsData && (
        <div className="mb-8 mt-10">
          <Text tailwind="my-2" type="heading-small">
            <FormattedMessage id="aff.referrals.heading" />
          </Text>

          <Card tailwind="w-full">
            {referralsData.referrals.length <= 0 ? (
              <div className="w-full h-full flex flex-col py-8 justify-center items-center md:px-6 lg:px-8 my-8">
                <Text tailwind="">
                  <FormattedMessage id="aff.referrals.noReferral" />
                </Text>
              </div>
            ) : (
              <div>
                <div
                  className={`grid grid-cols-5 py-4 px-6 border-b border-gray-200`}
                >
                  <SortText
                    isAscending={referralsAscending}
                    sort={sortBy === SORT_BY_REFERRALS}
                    onClick={sortByReferral}
                    tailwind="col-span-3"
                  >
                    <FormattedMessage id="aff.referrals.referral" />
                  </SortText>

                  <SortText
                    isAscending={dateAscending}
                    sort={sortBy === SORT_BY_CREATED_DATE}
                    tailwind="col-span-2"
                    onClick={sortByDate}
                  >
                    <FormattedMessage id="aff.referrals.signedUp" />
                  </SortText>
                </div>

                {currentPosts.map((value, index) => {
                  return <ReferralItem isGray={index % 2 === 0} {...value} />;
                })}
              </div>
            )}
            {showPagination && (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={referrals.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

Referrals.propTypes = {};

export default Referrals;
