import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks'

import theme from 'components/theme'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import useRedirectShopify from './app/hooks/useRedirectShopify';
import apolloClient from 'clients/apolloClient.js'

import {IntlProvider} from 'react-intl' 
import English from './languages/en.json'
import Spanish from './languages/es.json'
import LanguageWrapper from './languages/language-context'
import './tailwind.generated.css';
import 'react-credit-cards/es/styles-compiled.css';

const proceed = useRedirectShopify()


ReactDOM.render(
  proceed &&
 
  <React.StrictMode>
    <ApolloProvider client={apolloClient} >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
        <LanguageWrapper>
          <App />
        </LanguageWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
 

  ,

  document.getElementById('root'))

//want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
