import React from 'react';
import PropTypes from 'prop-types';
import { BASE_URL, HTTP } from '../../../../constants';
import Text from 'components/text'
import TextInput from 'components/textinput'

const CustomDomain = ({ affDashboardUrl, customDomain, setCustomDomain }) => {
    return (
        <div>
            <Text className='my-8'>We've created <span className='font-medium'>{`${affDashboardUrl}.${BASE_URL}`}</span> for you. Instead, you can use your own custom domain (like for example: affiliates.yoursite.com). Just follow the instructions below.</Text>

            <Text className='my-4'>First change your DNS records in your webhosting account by creating a CNAME record for Refmonkey.</Text>

            <Text className='my-4'>Point the value of your CNAME record to: <span className='font-medium'>secure.refmonkey.com</span></Text>

            <Text className='my-4'>Fill in the full url of your new custom domain in the field below and hit ‘SAVE’</Text>

            <TextInput
                onChange={(value) => {
                
                    setCustomDomain(value)
                }
            }
                value={customDomain}
                className='mb-6 mt-2'
                placeholder='affiliates.yoursite.com' />

            <Text type='text-small' className='my-4'>At the very first access to your domain, there is some latency because of generating an ssl certificate for your domain, wait a few minutes for this process to complete.</Text>

        </div>
    );
};

CustomDomain.propTypes = {

};

export default CustomDomain;