
import upgradeBillingHook from 'hooks/useUpgradeBilling'
import useIsShopify from '../useIsShopify';
import { SHOPIFY_YEARLY_PURCHASE, SHOPIFY_MONTHLY_PURCHASE } from 'app/billing/constants/GqlQueries'
import { useEffect, useRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks';

const useUpgradeBilling = () => {

    const productIndexRef = useRef()
    const [loading, setLoading] = useState(false)

    const { usesShopify } = useIsShopify()
    const { upgradeBilling } = upgradeBillingHook()

    const [purchaseShopifyYearly, { data: shopifyearlyPurchaseData, error: shopifyYearlyPurchaseError, loading: shopifyYearlyLoading }] = useMutation(SHOPIFY_YEARLY_PURCHASE, {
        onError: (e) => console.log(e)
    })
    const [purchaseShopifyMonthly, { data: shopifyMonthlyPurchaseData, error: shopifyMonthlyPurchaseError, loading: shopifyMonthlyLoading }] = useMutation(SHOPIFY_MONTHLY_PURCHASE, {
        onError: (e) => console.log(e)
    })

    useEffect(() => {

        if (shopifyYearlyLoading || shopifyMonthlyLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }

    }, [shopifyYearlyLoading, shopifyMonthlyLoading])

    useEffect(() => {
        let approveUrl

        if (shopifyearlyPurchaseData) {
            approveUrl = shopifyearlyPurchaseData.shopifyYearlyPurchase
        }

        if (shopifyMonthlyPurchaseData) {
            approveUrl = shopifyMonthlyPurchaseData.shopifyMonthlyPurchase
        }

        if (approveUrl) {
            window.location.href = approveUrl
        }

    }, [shopifyMonthlyPurchaseData, shopifyearlyPurchaseData])



    useEffect(() => {
        //if the shopify purchase fails to make a purchase then use paddle instead
        if (shopifyYearlyPurchaseError || shopifyMonthlyPurchaseError) {
            makePaddlePurchase()
        }
    }, [shopifyYearlyPurchaseError, shopifyMonthlyPurchaseError])


    const upgrade = (index) => {
        productIndexRef.current = index

        if (usesShopify) {

            //bill using shopify api
            switch (index) {
                case 0:
                    purchaseShopifyMonthly()
                    break
                case 1:
                    purchaseShopifyYearly()
                    break
                default:
                    break
            }

        } else {
            makePaddlePurchase()
        }
    }

    const makePaddlePurchase = () => {

        let productId

        switch (productIndexRef.current) {
            case 0:
                productId = process.env.REACT_APP_PADDLE_MONTHLY
                break
            case 1:
                productId = process.env.REACT_APP_PADDLE_YEARLY
                break
            default:
                break
        }

        upgradeBilling(productId, () => {

        })
    }


    return { upgrade, loading }

}


export default useUpgradeBilling