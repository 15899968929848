import { gql } from 'apollo-boost';

export const EDIT_REWARD_VALUE = gql`
	mutation($identifier: String!,$referredBy: String!,$rewardValue:String!){
		editTransactionRewardValue(transaction:{identifier: $identifier,referredBy: $referredBy,rewardValue:$rewardValue})
	}
`

export const TRANSACTIONS = gql`
  query{
    transactions{
		_id
		date
      identifier
	 referredBy
	 rewardValue
	 status
	 tag
	 reverted
	 revertMessage
	 productName
	 productDescription
	 paypalEmailAddress
      subscriptionDetails {
				cancel_at
				cancel_at_period_end
				collection_method
				created
				discount
				status
				extractedData {
					planID
					active
					amount
					currency
					interval
					product
					quantity
				}
			}
			paymentDetails {
				amount
				created
				currency
				billing_details {
					address {
						city
						country
						line1
						line2
						postal_code
						state
					}
					email
					name
					phone
				}
			}
			squarePaymentDetails {
				name
				emailAddress
				phoneNumber
				id
				payments {
					createdAt
					updatedAt
					amount
					currency
					status
					note
					receiptUrl
				}
			}

    }
  }
`;