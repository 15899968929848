import React from 'react';
import { Content, TopHeading } from 'components/api/text'
import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'
import GrayDiv from 'components/gray-div'

import Setup from './requests/setup'
import NewAdvocate from './requests/new-advocate'
import GetReferralLink from './requests/get-referral-link'
import GetReferrals from './requests/get-referrals'
import NewUser from './requests/new-user'
import PendingRewards from './requests/pending-rewards'
import Purchase from './requests/purchase'
import ReversePurchase from './requests/reverse-purchase'
import RedeemReward from './requests/redeem-reward'
import GetAdvocateEmail from './requests/get-advocate-email'
import Conversion from './requests/conversion'
import ReverseConversion from './requests/reverse-conversion'



const JSDocs = ({padding= true}) => {



    return (
        <GrayDiv>
            <div className={`${padding && 'p-8'}`}>

                <TopHeading>Introduction</TopHeading>

                <Content> This page contains a full reference of the refMonkey javascript library.</Content>

               

                <Setup /> 

                <Conversion />
                <ReverseConversion />
                {/** 
                <NewAdvocate />
                <NewUser />
                <Purchase />
                <ReversePurchase />
                */}
                <GetReferralLink />
                <PendingRewards />
                <RedeemReward />
                <GetReferrals />
                <GetAdvocateEmail />
            </div>
        </GrayDiv >
    );
};

export default JSDocs;