import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import Button from 'components/button'
import { useQuery, useMutation } from '@apollo/react-hooks';
//import { GET_PENDING_REWARDS, REDEEM_REWARD } from '../../constants/GqlQueries';
import styled from 'styled-components'
import ErrorAlert from 'components/alert/error'
import RewardDetails from '../../../affilate-dashboard/components/reward/reward-details';


const Wrapper = styled.div`
    background-color: ${({ theme, isGray }) => isGray ? theme.gray50 : 'white'};
`

const RedeemedRewardItem = (param) => {

    const { identifier, date, rewardName, rewardValue, type, units, isGray, name,
        tag, productName, productDescription,index } = param
    const [rewardTitle, setRewardTitle] = useState('')

    useEffect(() => {
        if (rewardValue === 'refmonkey_custom_reward_value') {
            setRewardTitle(rewardName)
        } else {
            setRewardTitle(`${rewardValue} ${rewardName}`)
        }
    }, [rewardName, rewardValue])

    return (
        <div
        >

            <Wrapper
                isGray={isGray}
                className='flex py-4 px-6'>

                <div className='grid grid-cols-4 w-full'>
                    <Text tailwind='col-span-2 break-all'>{name ? <div className='flex flex-col'><span>{name}</span><span className='text-gray-500 text-xs'> {identifier}</span></div> : identifier}</Text>
                    <Text>{date}</Text>
                    <Text>{rewardTitle}  {/**units > 1 ? `(x ${units})` : ''*/}</Text>
                </div>

                <RewardDetails
                    tag={tag}
                    identifier={identifier}
                    productName={productName}
                    productDescription={productDescription}
                    revertMessage={param.revertMessage}
                    index={index}
                />

              

               
            </Wrapper>


        </div>
    );
};

RedeemedRewardItem.propTypes = {

};

export default RedeemedRewardItem;