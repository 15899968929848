import React from 'react';

import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
        title='Get advocate'
        description={`get an advocate's data`}
        method='get'
        path='/advocate'
        tabs={[{
            title: 'Axios',
            script: false,
            code:` 
        instance.get('https://api.refmonkey.com/advocate?identifier=\${identifier}')
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
             `,
        },
            {
                title: 'curl',
                script: false,
                code: ` 
        curl https://api.refmonkey.com/advocate?identifier=\${identifier} --header "Authorization: YOUR_ACCESS_TOKEN"     
             `,
            }]}
        inputParameters={
            <>
                <ParamItem
                    required={true}
                    title='identifier'
                    type='string'
                    content={`A unique identifier for the advocate/affiliate. This can be an email address, username or id`}
                />
            </>
        }

        outputParameters={
            <>
                <ParamItem
                    title='success'
                    type='boolean'
                    content={`Indicates the outcome of the API call.`}
                />
                <ParamItem
                    title='identifier'
                    type='string'
                    content={`A unique identifier for the user in your system.`}
                />

                <ParamItem
                    required={true}
                    title='refCode'
                    type='string'
                    content={`The referral code of the advocate.`}
                />

                <ParamItem
                    title='referralCount'
                    type='number'
                    content={`The number of referrals the user has.`}
                />

            </>
        }
        sampleResponse={`
    {
        "success": true,
        "identifier": "jason@gmail.com",
        "refCode": "b6ec6c",
        "referralCount": 3,
    }
    `}
    />
    );
};

DocRequest.propTypes = {

};

export default DocRequest;
