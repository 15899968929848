import React from 'react';
import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
            id='pending-rewards'
            title='Get pending rewards'
            description='get the referrals or advocates who are pending to be given a reward'
            method='get'
            path='/reward/pending'
            tabs={[{
              title: 'Axios',
              script: false,
              code:` 
        instance.get('https://api.refmonkey.com/reward/pending')
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                 console.log(error);
            })
             `,
            },
              {
                title: 'curl',
                script: false,
                code: ` 
        curl https://api.refmonkey.com/reward/pending --header "Authorization: YOUR_ACCESS_TOKEN"
             `,
              }]}
            inputParameters={
                <>
                 <ParamItem
                        required={false}
                        title='type'
                        type='string'
                        content={`can be "referral" or "advocate". Filter the pending rewards to only return pending rewards of only a referral or an advocate. If not provided then all pending rewards of advocates and referrals are returned.`}
                    />

                    <ParamItem
                        required={false}
                        title='identifier'
                        type='string'
                        content={`A unique identifier for the advocate or referral in your system. This can be an email address, username or id. Filter the pending rewards to only return rewards of a specific referral or advocate.`}
                    />


                </>
            }
            outputParameters={
                <>
                    <ParamItem
                        title='success'
                        type='boolean'
                        content={`Indicates the outcome of the API call.`}
                    />

                    <ParamItem
                        title='pendingRewards'
                        type='array'
                        content={`array of users pending to be given a reward`}
                        childItems={
                            <>
                                <ParamItem
                                    title='identifier'
                                    type='string'
                                    content={`A unique identifier for the user in your system.`}
                                />

                                <ParamItem
                                    title='name'
                                    type='string'
                                    content={`The name of the user in your system`}
                                />

                                <ParamItem
                                    title='type'
                                    type='string'
                                    content={`can be "referral" or "advocate"`}
                                />
                                <ParamItem
                                    title='rewardName'
                                    type='string'
                                    content={`the name of the reward`}
                                />

                                <ParamItem
                                    title='rewardValue'
                                    type='string'
                                    content={`the value of the reward`}
                                />

                                <ParamItem
                                    title='units'
                                    type='number'
                                    content={`the number of times this reward should be given to the user`}
                                />
                                <ParamItem
                                    title='date'
                                    type='string'
                                    content={`the date when this reward was given.`}
                                />

                            </>
                        }
                    />
                </>
            }

            sampleResponse={`
                    {
                        "success": true,
                        "pendingRewards": [
                            {
                                "identifier": "205@gmail.com",
                                "name": "George",
                                "type": "referral",
                                "date": "7/23/2020",
                                "rewardName": "% discount",
                                "rewardValue": "5",
                                "units": 1
                            }
                        ]
                    }
                    `}
        />
    );
};

DocRequest.propTypes = {

};

export default DocRequest;
