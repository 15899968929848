import React, { useCallback, useState } from 'react'
import gql from 'graphql-tag'
import Option from '../option'
import Text from 'components/text'
import DropZoneButton from './drop-zone/DropZoneButton'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loading from '../../../../components/loading/index'
import { UPLOAD_LOGO ,DELETE_LOGO,GET_LOGO} from '../../constants/GqlQueries'



function BusinessLogo({affDashboardUrl}) {
    const [uploaded, setUploaded] = useState(false);
    let show;
    const resultQuery = useQuery(GET_LOGO, {
        variables: {business: affDashboardUrl},
        onError: error => console.log({ error }),
        onCompleted: (data) => {
            if (data.logo.data !== "") {
                setUploaded(true);
            }
        }
    })

    const [uploadLogo, { data, loading, error }] = useMutation(UPLOAD_LOGO, {
        onError: error => console.log({ error }),
        refetchQueries: () => {
            return [{ query: GET_LOGO, variables:{business: affDashboardUrl} }]
        },
        onCompleted: () => {
            setUploaded(true)
            return resultQuery.refetch();
        },
        awaitRefetchQueries: true,
        errorPolicy: 'all'
    });

    const [deleteLogo, deletionInfo] = useMutation(DELETE_LOGO, {
        onError: error => console.log({ error }),
        refetchQueries: () => {
            return [{ query: GET_LOGO, variables:{business: affDashboardUrl} }]
        },
        onCompleted: () => {
            setUploaded(false);
            return resultQuery.refetch()
        },
        awaitRefetchQueries: true,
        errorPolicy: 'all'
    })

    if (error || resultQuery.error) {
        show = <p className="text-red-600">Cannot Upload the file!</p>
        //dont set uploaded to true;
    }
    if (resultQuery.loading || loading) {
        show = (<Loading className="w-20 h-20" />)
    } else {
        if (resultQuery?.data?.logo?.data) {
            show = <img className="w-20 h-20 object-contain rounded-xl" src={`data:${resultQuery.data.logo.contentType};base64, ${resultQuery.data.logo.data}`} alt="" />

        }
    }

    const onDrop = useCallback(
        ([file]) => {
            uploadLogo({ variables: { file: file, contentType: file.type} })

        }, [uploadLogo])

    const removeLogo = () => {
        deleteLogo();
    }

    return (
        <Option
            toggleValue={false}
            title='Business Logo' >
            <div>
                <div className="ml-1 mb-5">
                    {show}
                </div>
                <div className={uploaded ? "mb-7" : ""}>
                    {uploaded ? <a nohref className="text-white hover:text-white bg-red-700 
                shadow-sm hover:bg-red-600 rounded-lg w-28 h-28 pt-3 pb-3  pl-4 pr-4 text-center 
                border cursor-pointer" onClick={removeLogo}>Remove</a>
                        : <DropZoneButton onDrop={onDrop} />}
                </div>
            </div>
            {
                uploaded ?
                    null
                    :
                    <Text
                        color
                        type='text-small'
                        tailwind='my-2 text-gray-600 mt-5'>
                        adds a business logo to display on affilliate dashboard
        </Text>
            }
        </Option>
    )
}

export default BusinessLogo
