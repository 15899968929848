import React, { useState } from 'react';
import Item from './components/item';
import Button from 'components/button'
import Text from 'components/text'
import Icon from 'components/icon'
import { ReactComponent as AddSVG } from 'images/document-add.svg'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CAMPAIGNS, CREATE_CAMPAIGN, EDIT_CAMPAIGN, GET_SELECTED_CAMPAIGN, ARCHIVE_CAMPAIGN } from './constants/GqlQueries'
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'
import Dialog from 'components/dialog'
import FieldInput from 'components/fieldinput'
import useGetSelectedCampaign from './hooks/useGetSelectedCampaign';
import { HTTP_URL } from '../../constants';

const Campaigns = () => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [emptyCampaignName, setEmptyCampaignName] = useState(false)
    const [campaignName, setCampaignName] = useState('')
    const [showError, setShowError] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [campaign, setCampaign] = useState()




    const selectedCampaign = useGetSelectedCampaign()

    const { data, loading, error } = useQuery(GET_CAMPAIGNS)
    const [createCampaignMutate, { loading: createCampaignLoading, error: createCampaignError }] = useMutation(CREATE_CAMPAIGN, {
        update: () => {
            //refresh browser 
            window.location.href = HTTP_URL
        }
    })

    const [editCampaignMutate, { loading: editCampaignLoading, error: editCampaignError }] = useMutation(EDIT_CAMPAIGN, {
        onError: (e) => console.log(e),
        update: (cache, { data }) => {
            if (data.editCampaign) {
                try {

                    setShowCreateDialog(false)
                    setEditMode(false)

                    const { campaigns } = cache.readQuery({ query: GET_CAMPAIGNS })

                    const newCampaigns = campaigns.map(value => {
                        if ((value.cid === data.editCampaign.cid)) {
                            value.title = campaignName
                        }
                        return value
                    })

                    cache.writeQuery({
                        query: GET_CAMPAIGNS,
                        data: {
                            campaigns: newCampaigns
                        }
                    })

                    //update for selected campaign

                    const { selectedCampaign } = cache.readQuery({ query: GET_SELECTED_CAMPAIGN })

                    if (selectedCampaign.cid === data.editCampaign.cid) {

                        const newSelectedCampaign = { ...selectedCampaign }
                        newSelectedCampaign.title = campaignName

                        cache.writeQuery({
                            query: GET_SELECTED_CAMPAIGN,
                            data: {
                                selectedCampaign: newSelectedCampaign
                            }
                        })

                    }

                    console.log('complete')

                    console.log('set states complete')
                } catch (e) {
                    console.log({ e })
                }

            } else {
                setShowError(true)
            }
        }
    })

    const [archiveCampaignMutate, { loading: archiveLoading }] = useMutation(ARCHIVE_CAMPAIGN, {
        onError: (e) => setShowError(true),
        update: (cache, { data }) => {
            console.log({ data })
            if (data.archiveCampaign) {
                try {
                    const { campaigns } = cache.readQuery({ query: GET_CAMPAIGNS })

                    const newCampaigns = campaigns.map(value => {
                        if ((value.cid === data.archiveCampaign.cid)) {
                            value.isArchived = !value.isArchived
                        }
                        return value
                    })

                    cache.writeQuery({
                        query: GET_CAMPAIGNS,
                        data: {
                            campaigns: newCampaigns
                        }
                    })

                    //update for selected campaign

                    const { selectedCampaign } = cache.readQuery({ query: GET_SELECTED_CAMPAIGN })

                    if (selectedCampaign.cid === data.archiveCampaign.cid) {

                        const newSelectedCampaign = { ...selectedCampaign }
                        newSelectedCampaign.isArchived = !selectedCampaign.isArchived

                        cache.writeQuery({
                            query: GET_SELECTED_CAMPAIGN,
                            data: {
                                selectedCampaign: newSelectedCampaign
                            }
                        })

                    }

                    console.log('complete')

                } catch (e) {
                    console.log({ e })
                }

            } else {
                setShowError(true)
            }
        }
    })

    const archiveCampaign = (campaign) => {
        setShowError(false)


        archiveCampaignMutate({
            variables: {
                cid: campaign.cid,
                isArchived: campaign.isArchived ? false : true,

            }
        })

    }

    const editCampaign = () => {
        setEmptyCampaignName(false)
        setShowError(false)

        if (campaignName.length <= 0) {
            setEmptyCampaignName(true)
        } else {
            editCampaignMutate({
                variables: {
                    cid: campaign.cid,
                    title: campaignName,
                }
            })
        }
    }

    const createCampaign = () => {
        setEmptyCampaignName(false)
        setShowError(false)

        if (campaignName.length <= 0) {
            setEmptyCampaignName(true)
        } else {
            //create campaign
            createCampaignMutate({
                variables: {
                    title: campaignName
                }
            })
        }
    }

    return (
        <>
            <Dialog
                isShown={showCreateDialog}
                onCloseComplete={() => {
                    setShowCreateDialog(false)
                    setEditMode(false)
                    setShowError(false)

                }}
                variant='normal'
            >
                <div className=' w-96 p-8 py-12 flex flex-col items-center justify-center'>

                    {
                        <>
                            <ErrorAlert
                                isShown={(!editMode && createCampaignError) || (editMode && editCampaignError)}
                            />

                            <Text type='heading'>{editMode ? 'Edit' : 'Create'} Campaign</Text>

                            <FieldInput
                                tailwind='w-full mt-10'
                                label='Campaign Name'
                                type='text'
                                value={campaignName}
                                helpText={emptyCampaignName ? 'Campaign name cannot be empty' : ''}
                                onChange={setCampaignName}
                            />

                            <Button
                                tailwind='w-full mt-12'
                                loading={createCampaignLoading || editCampaignLoading}
                                onClick={editMode ? editCampaign : createCampaign}
                            >Save</Button>

                            <Button
                                onClick={() => setShowCreateDialog(false)}
                                color
                                bg
                                hover
                                tailwind='w-full bg-red-700 hover:bg-red-600  text-white mt-2'>
                                Cancel
                        </Button>
                        </>

                    }


                </div>
            </Dialog>

            <ErrorAlert
                isShown={showError}
            />

            < div className="px-2 h-full box-border flex flex-col">
                <div className='flex'>

                </div>

                <div className='mt-8 w-8/10 h-full '>

                    {(error || loading || showLoading || archiveLoading) ?
                        <div className='z-10 w-full h-full border-solid border-2 border-black-900'>
                            <div className='w-full h-full flex justify-center items-center'>
                                {(loading || showLoading || archiveLoading) &&
                                    <Loading
                                    />
                                }

                                {error &&
                                    <ErrorAlert
                                        isShown={true}
                                    />
                                }
                            </div>
                        </div>
                        :


                        <div className="border-solid border-2 border-black-900 p-10 grid grid-cols-1 lg:grid-cols-3 lg:gap-8 md:grid-cols-2 md:gap-6 ">
                            {data &&
                                <>
                                    {data.campaigns.map(item => {
                                        return <Item
                                            onEdit={() => {
                                                console.log("On Edit")
                                                setShowCreateDialog(true)
                                                setEditMode(true)
                                                setCampaignName(item.title)
                                                setCampaign(item)
                                            }}
                                            onArchive={() => {
                                                archiveCampaign(item)
                                            }}
                                            setShowError={setShowError}
                                            setShowLoading={setShowLoading}
                                            isSelected={selectedCampaign.cid === item.cid}
                                            {...item}
                                        />
                                    })}




                                    <li
                                        onClick={() => setShowCreateDialog(true)}
                                        className=' cursor-pointer col-span-1 flex flex-col text-center bg-white hover:bg-gray-100 rounded-lg shadow divide-y divide-gray-200'>
                                        <div className='flex-1 flex flex-col p-8 items-center justify-center'>

                                            <div className='w-32 h-32  text-gray-300 my-12'>
                                                <AddSVG
                                                    className='w-full h-full'
                                                />
                                            </div>


                                            <Text>Create New Campaign</Text>
                                        </div>
                                    </li>
                                </>
                            }

                        </div>
                    }
                </div>
            </div >
        </>
    )
}

export default Campaigns;