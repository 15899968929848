import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import IconButton from 'components/iconbutton'
import ErrorAlert from 'components/alert/error'

import styled from 'styled-components'
import { DELETE_ADVOCATE, GET_ADVOCATES, APPROVE_ADVOCATE, DISABLE_ADVOCATE } from '../../constants/GqlQueries';
import { useMutation } from '@apollo/react-hooks';
import { ReactComponent as DeleteSVG } from 'images/delete.svg'
import Dialog from 'components/dialog'
import Button from 'components/button'
import useGenerateTooltipId from '../../../hooks/useGenerateTooltipId';
import ReactTooltip from 'react-tooltip';


const Wrapper = styled.div`
    background-color: ${({ theme, isGray }) => isGray ? theme.gray50 : 'white'};
`


const AdvocateItem = ({ identifier, index, referralCount, isGray, date, name, approved, enableApproveAdvocate, isDisabled }) => {
    const [showError, setShowError] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const disableTooltipID = useGenerateTooltipId(index)


    const [disableAdvocate, { loading: disableLoading }] = useMutation(DISABLE_ADVOCATE, {
        onError: () => { setShowError(true) },
        update: (cache, { data }) => {
            if (data.disableAdvocate) {

                const { advocates } = cache.readQuery({ query: GET_ADVOCATES })

                const newAdvocates = []
                advocates.map((value) => {
                    const newValue = { ...value }

                    if (identifier === newValue.identifier) {
                        newValue.isDisabled = !isDisabled
                    }

                    newAdvocates.push(newValue)
                })


                cache.writeQuery({
                    query: GET_ADVOCATES,
                    data: {
                        advocates: newAdvocates
                    }
                })
            } else {
                setShowError(true)
            }

        }
    })


    const [approveAdvocate, { loading: approveLoading }] = useMutation(APPROVE_ADVOCATE, {
        onError: () => { setShowError(true) },
        update: (cache, { data }) => {
            if (data.approveAdvocate) {

                const { advocates } = cache.readQuery({ query: GET_ADVOCATES })

                const newAdvocates = []
                advocates.map((value) => {
                    const newValue = { ...value }

                    if (identifier === newValue.identifier) {
                        newValue.approved = true
                    }

                    newAdvocates.push(newValue)
                })


                cache.writeQuery({
                    query: GET_ADVOCATES,
                    data: {
                        advocates: newAdvocates
                    }
                })
            } else {
                setShowError(true)
            }

        }
    })

    const [deleteAdvocate, { loading: deleteLoading }] = useMutation(DELETE_ADVOCATE, {
        onError: () => { setShowError(true) },
        update: (cache, { data }) => {
            if (data.deleteAdvocate) {

                const { advocates } = cache.readQuery({ query: GET_ADVOCATES })

                const newAdvocates = advocates.filter((value) => {

                    if (identifier === value.identifier) {
                        return false

                    } else {
                        return true
                    }
                })

                cache.writeQuery({
                    query: GET_ADVOCATES,
                    data: {
                        advocates: newAdvocates
                    }
                })
            } else {
                setShowError(true)
            }

        }
    })

    const handleDeleteAdvocate = () => {
        setShowDeleteDialog(false)
        setShowError(false)

        deleteAdvocate({
            variables: {
                identifier
            }
        })
    }
    return (
        <div>


            <Dialog
                isShown={showDeleteDialog}
                variant='simple-gray'
                title='Delete Advocate'
                text={`Are you sure you want to delete ${identifier} as an advocate? All of their data as well as any pending rewards will be permanently removed. This action cannot be undone.`}
                onCloseComplete={() => setShowDeleteDialog(false)}
                onConfirm={handleDeleteAdvocate}
            />
            <ErrorAlert
                isShown={showError} />

            <Wrapper
                isGray={isGray}
                className='grid grid-cols-12 py-4 px-6'>

                <Text color tailwind={`col-span-6 break-all ${isDisabled ? 'text-gray-300': 'text-gray-600'}`}>{name ? <div className='flex flex-col'><span>{name}</span><span className={`${isDisabled ? 'text-gray-300': 'text-gray-500'} text-xs`}> {identifier}</span></div> : identifier}</Text>
                <Text color tailwind={`col-span-2 ${isDisabled ? 'text-gray-300': 'text-gray-600'}`}>{referralCount}</Text>
                <Text color tailwind={`col-span-2 ${isDisabled ?'text-gray-300': 'text-gray-600'}`}>{date}</Text>

                <div className='col-span-2 w-full flex justify-center'>
                    {(enableApproveAdvocate && !approved) ?
                        <Button
                            onClick={() => approveAdvocate({
                                variables: {
                                    identifier
                                }
                            })}
                            loading={approveLoading}
                            tailwind='mr-4'
                            variant='outline'
                            size='extra-small'
                        >Approve</Button>

                        :

                        <div
                            data-tip data-for={disableTooltipID}>
                            <Button
                                onClick={() => disableAdvocate({
                                    variables: {
                                        identifier,
                                        isDisabled: !isDisabled
                                    }
                                })}
                                color
                                loading={disableLoading}
                                tailwind={`mr-4 ${isDisabled ? 'text-gray-300' : 'text-gray-500'}`}
                                variant='text'
                                size='extra-small'
                            >{isDisabled ? 'Activate' : 'Disable'} </Button>
                        </div>

                    }
                    <IconButton
                        color
                        size
                        tailwind={`w-8 h-8 p-2 ${isDisabled ? 'text-gray-300' : 'text-gray-400'}`}
                        loading={deleteLoading}
                        hasBackground={false}
                        onClick={() => setShowDeleteDialog(true)}
                        src={<DeleteSVG />}
                    />

                </div>
            </Wrapper>



            <ReactTooltip
                id={disableTooltipID}
                // aria-haspopup='true'
                effect='solid'
                // type='info'
                place='bottom'>

                <div className='px-4 py-2 my-2'>
                    {isDisabled ?
                        <>
                            <Text color tailwind='text-white'> Activate the affiliate account.</Text>

                            <Text color tailwind='text-white'> Affiliate will gain access to their dashboard and </Text>
                            <Text color tailwind='text-white'>  their referral conversions will be tracked.</Text>
                        </>
                        :
                        <>
                            <Text color tailwind='text-white'>Make the affiliate account inactive. </Text>
                            <Text color tailwind='text-white'>Affiliate will not be able to access their dashboard and</Text>
                            <Text color tailwind='text-white'>their referral conversions will nolonger be tracked.</Text>
                        </>
                    }
                </div>
            </ReactTooltip>

        </div>
    );
};

AdvocateItem.propTypes = {

};

export default AdvocateItem;