import React, { useEffect } from 'react';
import Button from 'components/button'
import Text from 'components/text'
import ErrorAlert from 'components/alert/error'
import Loading from 'components/loading'
import CodeBox from 'components/code-box'
import useGetAccessToken from '../../hooks/useGetAccessToken';


const AccessToken = () => {

    const { data, loading, error } = useGetAccessToken()

    return (
        <div className='w-full'>
            {error &&
                <ErrorAlert
                    isShown={true}
                />
            }
            {loading &&
                <div></div>
            }


            <Text
                color
                tailwind=' text-gray-700'
                type='heading-small'>Access Token </Text>

            <CodeBox
            copyText={data && data.generateAccessToken}>
                {`
${data ? data.generateAccessToken : ''}
                `}
                </CodeBox>


        </div>
    );
};

export default AccessToken;