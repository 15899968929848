import React from 'react';
import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
            id='conversion'
            title='Conversion'
            description='Create a conversion'
            method='post'
            path='/conversion'
            tabs={[{
                title: 'Axios',
                script: false,
                code: ` 
        instance.post('https://api.refmonkey.com/conversion', {
            type: "signup",
            email: '\${email}',
        })                  
             `,
            },
            {
                title: 'curl',
                script: false,
                code: ` 
        curl -X POST --data "type=signup&email=\${email}" https://api.refmonkey.com/conversion --header "Authorization: YOUR_ACCESS_TOKEN"
             `,
            }]}
            inputParameters={
                <>
                    <ParamItem
                        required={false}
                        title='cid'
                        type='string'
                        content={`The Campaign ID of the campaign this conversion belongs to. The default campaign is used if parameter is not provided`}
                    />

                    <ParamItem
                        required={false}
                        title='type'
                        type='string'
                        content={`Describes the type of conversion, i.e 'signup', 'purchase', 'form submission', etc.`}
                    />

                    <ParamItem
                        required={false}
                        title='ref_code'
                        type='string'
                        content={`The referral code of an affiliate who invited the referral. This corresponds to the value of ref= in their referral link.For any page visit, check the URL for the presence of the ?ref= query parameter. If it is present, get the value.`}
                    />
                    <ParamItem
                        required={false}
                        title='custom_reward'
                        type='string'
                        content={`A custom reward to give the affiliate that referred the referral.`}
                    />
                    <ParamItem
                        required={false}
                        title='email'
                        type='string'
                        content={`The email address of the referral`}
                    />

                    <ParamItem
                        required={false}
                        title='name'
                        type='string'
                        content={`The name of the referral`}
                    />

                    <ParamItem
                        required={false}
                        title='phone'
                        type='string'
                        content={`The phone number of the referral`}
                    />
                    <ParamItem
                        required={false}
                        title='username'
                        type='string'
                        content={`The username of the referral`}
                    />
                    <ParamItem
                        required={false}
                        title='id'
                        type='string'
                        content={`A unique id of the referral in your system`}
                    />

<ParamItem
                        required={false}
                        title='product_name'
                        type='string'
                        content={`Name of the product that this conversion is for`}
                    />

<ParamItem
                        required={false}
                        title='product_description'
                        type='string'
                        content={`Description of the product that this conversion is for`}
                    />

                </>
            }

            outputParameters={
                <>
                    <ParamItem
                        title='success'
                        type='boolean'
                        content={`Indicates the outcome of the API call.`}
                    />
                    <ParamItem
                        title='conversion_id'
                        type='string'
                        content={`The ID associated with the conversion.`}
                    />
                </>
            }
            sampleResponse={`{
                "success": true,
                "conversion_id": "60032148d4fa9a22f0cbaaaa"
            }`}
        />


    );
};

DocRequest.propTypes = {

};

export default DocRequest;
