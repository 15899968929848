import React from 'react';
import SupportComponent from 'components/contact/form'
import { getEmail } from 'utils/authUtil'
import FrequentlyAsked from 'components/frequently-asked'
import Text from 'components/text'

const questions = [
    {
        title: 'Who is an advocate?',
        content: `An advocate (also called an affiliate) is a person who refers your bussiness/brand to other people and invites them to use your product. `
    }, {
        title: 'Who is a referral?',
        content: `A referral is a person who has been invited by an advocate to use your product/platform.`
    }, {
        title: 'Can I cancel my account at any time?',
        content: `Yes. If you ever decide that RefMonkey isn’t the best service for your business, simply cancel your account.`
    }, {
        title: 'The js code is not working in a code playground?',
        content: `The code does not work in a code playground because the refmonkey script automatically detects the ref parameter in the url and employs the use of a cookie, unfortunately most code playgrounds remove any parameters you try to add to the url and don't save cookies. Because of this the refmonkey script is unable to detect the ref parameter on the website url or even persist it in a cookie.
         The recommended way to test the code is by using 'http-server' npm module on an html file on your local dev machine.`
    }
]

const Support = () => {
    return (
        <div className='py-8 md:px-8'>
            <SupportComponent
                title={`Need technical Support`}
                email={() => getEmail()} />

            <FrequentlyAsked
                tailwind='my-4 md:my-6'
                items={questions}
            />
        </div>
    );
};

export default Support;