import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {
    return (
        <Request
            id='pending-rewards'
            title='Get pending rewards'
            description='get the referrals or advocates who are pending to be given a reward'

            tabs={[{
                title: 'sync',
                script: true,
                code: `
        window.refMonkeyClient.getPendingRewards(TYPE, IDENTIFIER, (success,pendingRewards)=>{ 
         //
        })
`,
                inputParams: <>

                    <ParamItem
                        required={false}
                        title='type'
                        type='string'
                        content={`can be "referral", "advocate" or null. Filter the pending rewards to only return pending rewards of only a referral or an advocate. If null then all pending rewards of advocates and referrals are returned.`}
                    />

                    <ParamItem
                        required={false}
                        title='identifier'
                        type='string'
                        content={`A unique identifier for the advocate or referral in your system. This can be an email address, username or id. Filter the pending rewards to only return rewards of a specific referral or advocate. Can be null.`}
                    />


                    <ParamItem
                        required={false}
                        title='callback'
                        type='function'
                        content={`Callback, is called after completion.`}
                        childItems={
                            <>
                                <ParamItem
                                    title='success'
                                    type='boolean'
                                    content={`Indicates the outcome of the method call.`}
                                />

                                <ParamItem
                                    title='pendingRewards'
                                    type='array'
                                    content={`array of users pending to be given a reward`}
                                    childItems={
                                        <>
                                            <ParamItem
                                                title='identifier'
                                                type='string'
                                                content={`A unique identifier for the user in your system.`}
                                            />
                                            <ParamItem
                                                title='name'
                                                type='string'
                                                content={`The name of the user in your system`}
                                            />

                                            <ParamItem
                                                title='type'
                                                type='string'
                                                content={`can be "referral" or "advocate"`}
                                            />
                                            <ParamItem
                                                title='rewardName'
                                                type='string'
                                                content={`the name of the reward`}
                                            />

                                            <ParamItem
                                                title='rewardValue'
                                                type='string'
                                                content={`the value of the reward`}
                                            />

                                            <ParamItem
                                                title='units'
                                                type='number'
                                                content={`the number of times this reward should be given to the user`}
                                            />
                                            <ParamItem
                                                title='date'
                                                type='string'
                                                content={`the date when this reward was given.`}
                                            />

                                        </>
                                    }
                                />
                            </>
                        }
                    />
                </>
            },
            {
                title: 'async',
                script: false,
                code: `
       const response = await window.refMonkeyClient.getPendingRewards(TYPE, IDENTIFIER)
`,
                inputParams: <>
                    <ParamItem
                        required={false}
                        title='type'
                        type='string'
                        content={`can be "referral", "advocate" or null. Filter the pending rewards to only return pending rewards of only a referral or an advocate. If null then all pending rewards of advocates and referrals are returned.`}
                    />

                    <ParamItem
                        required={false}
                        title='identifier'
                        type='string'
                        content={`A unique identifier for the advocate or referral in your system. This can be an email address, username or id. Filter the pending rewards to only return rewards of a specific referral or advocate. Can be null.`}
                    />
                </>,
                outputParams:
                    <>
                        <ParamItem
                            title='success'
                            type='boolean'
                            content={`Indicates the outcome of the method call.`}
                        />

                        <ParamItem
                            title='pendingRewards'
                            type='array'
                            content={`array of users pending to be given a reward`}
                            childItems={
                                <>
                                    <ParamItem
                                        title='identifier'
                                        type='string'
                                        content={`A unique identifier for the user in your system.`}
                                    />
                                    <ParamItem
                                        title='name'
                                        type='string'
                                        content={`The name of the user in your system`}
                                    />

                                    <ParamItem
                                        title='type'
                                        type='string'
                                        content={`can be "referral" or "advocate"`}
                                    />
                                    <ParamItem
                                        title='rewardName'
                                        type='string'
                                        content={`the name of the reward`}
                                    />

                                    <ParamItem
                                        title='rewardValue'
                                        type='string'
                                        content={`the value of the reward`}
                                    />

                                    <ParamItem
                                        title='units'
                                        type='number'
                                        content={`the number of times this reward should be given to the user`}
                                    />
                                    <ParamItem
                                        title='date'
                                        type='string'
                                        content={`the date when this reward was given.`}
                                    />

                                </>
                            }
                        />
                    </>
            }]}

        />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;