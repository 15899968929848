import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading'
import RefCode from '../ref-code';
import DynamicRefCode from '../dynamic-ref-code';

import Card from 'components/card'
import Text from 'components/text'
import ErrorAlert from 'components/alert/error'
import { FormattedMessage } from 'react-intl';
import ClickCount from '../click-count';


const Dashboard = ({ toRight, setRefCode, refCode, referralDashboardCode, rewardsLoading, rewardsError, rewardsData }) => {
    return (
        <div>
            <div className='my-8'>
                <RefCode
                    setRefCode={setRefCode}
                    refCode={refCode}
                    referralDashboardCode={referralDashboardCode}
                />
            </div>

            <div className='w-full flex justify-center items-center'>
                <Text type='heading-small'><FormattedMessage id="or" /></Text>
            </div>

            <div className='my-8'>
                <DynamicRefCode
                    toRight={toRight}
                    refCode={refCode}
                    referralDashboardCode={referralDashboardCode}
                />
            </div>

            <div className='my-8'>
                <ClickCount 
                 refCode={refCode}
                 referralDashboardCode={referralDashboardCode}/>
                
            </div>

            {rewardsLoading &&
                <div className='w-full h-full min-h-64 flex items-center justify-center'>
                    <Loading />
                </div>
            }



            <ErrorAlert
                aff={true}
                isShown={rewardsError}
            />

            {rewardsData && rewardsData.advocatePanelSettings &&
                //CHANGE LANGUAGE
                <Card tailwind='flex flex-col justify-center p-16 items-center my-12'>
                    {rewardsData.advocatePanelSettings.rewardAdvocates.status ?
                        <>
                            <Text className='font-medium'><FormattedMessage id="aff.dashboard.reward" /></Text>
                            <Text><FormattedMessage id="aff.dashboard.youGet" />
                                <span className='font-medium'>{` ${rewardsData.advocatePanelSettings.rewardAdvocates.value} ${rewardsData.advocatePanelSettings.rewardAdvocates.name} `}</span>
                                <FormattedMessage id="aff.dashboard.for" />
                                {rewardsData.advocatePanelSettings.recurringReward ?
                                    //recuring reward
                                    (rewardsData.advocatePanelSettings.referralsRequired === 1 ? <FormattedMessage id="aff.dashboard.each" /> : ` ${<FormattedMessage id="aff.dashboard.every" />} ${rewardsData.advocatePanelSettings.referralsRequired} ${<FormattedMessage id="aff.dashboard.referredCustomers" />} `)
                                    :
                                    //one off reward
                                    (rewardsData.advocatePanelSettings.referralsRequired === 1 ? ` ${<FormattedMessage id="aff.dashboard.firstCustomer" />}` : ` ${<FormattedMessage id="aff.dashboard.theFirst" />} ${rewardsData.advocatePanelSettings.referralsRequired} ${<FormattedMessage id="aff.dashboard.referredCustomers" />} `)

                                }.</Text>

                        </>
                        :
                        <>
                            <Text><FormattedMessage id="aff.dashboard.noreward" /></Text>
                        </>

                    }
                </Card>
            }


        </div >
    );
};

Dashboard.propTypes = {

};

export default Dashboard;
