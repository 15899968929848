import React from 'react'


const monthlyPrice = 49
const yearlyPrice = 499

const getPricingItems = (showAll = true) => {
    let pricingItems = []

    pricingItems.push({
        monthlyPrice,
        title: "monthly",
        pricingText: "/mo",
        features: [{
            content: 'unlimited referrals per month'
        }, {
            content: 'API'
        }, {
            content: 'Paypal Mass Payouts'
        }, {
            content: 'Integrations'
        }, {
            content: 'No hidden fees or commissions'
        }, {
            content: 'Priority Support'
        }]
    }, {
        monthlyPrice: yearlyPrice,
        pricingText: "/yr",
        title: <p> Yearly <b>save $89</b></p>,
        features: [{
            content: 'unlimited referrals per month'
        }, {
            content: 'API'
        }, {
            content: 'Paypal Mass Payouts'
        }, {
            content: 'Integrations'
        }, {
            content: 'No hidden fees or commissions'
        }, {
            content: 'Priority Support'
        }
        ]
    })

    return pricingItems
}

export default getPricingItems