import React, {useRef, useState} from 'react';
import FrequentlyAsked from 'components/frequently-asked'
import DescriptionItem from 'components/landing/description-item'
import {useHistory, useRouteMatch, Route} from 'react-router-dom'
import Pricing from 'components/pricing/two-tier'
import 'components/fonts/index.css'
import getPricingItems from './pricingItems';
import Nav from 'components/header-nav'
import Logo from "../landing/components/logo";
import { scrollToRef } from 'utils/scrollutil'

const frequentlyAskedData = [
  {
    title: `Why Referral Marketing?`,
    content: `Studies have shown that people are ten times more likely to use a product when referred by a friend. So why not let your users do your advertising for you.
        Word of mouth influences 80% of buying decisions, and people trust referrals over every other channel. Your users will know at least one other new user; why not reward them for making a referral?`
  }, {
    title: `Why should I use RefMonkey?`,
    content: `Referral programs out there are too complex for founders and non-marketers,
        They charge expensive fees, include too much, and gear toward full-fledged marketing packages.
        RefMonkey lets you integrate a referral program into your product without breaking the bank.
    `
  }, {
    title: 'Can I cancel my account at any time?',
    content: `Yes. If you ever decide that RefMonkey isn’t the best service for your business, simply cancel your account.`
  }, {
    title: `I'm already working with another provider`,
    content: `We provide concierge service. If you’ve made your decision and want to switch from another provider, our team is ready to help and migrate your data for you. Please contact us via help@refmonkey.com for more details.`
  }, {
    title: `I don't know anything technical, is this a problem?`,
    content: `No! Integrating RefMonkey to your website is as easy as installing Google Analytics to your website. Our team will also be happy to help you when you need.`
  }
]


const PricingFaq = ({showPricing = true}) => {
  const history = useHistory()
  const pricingRef = useRef()

  const featuresRef = useRef()
  const scrollToFeatures = () => scrollToRef(featuresRef)

  const handleGetStarted = (value) => {
    history.push('/create-account')
    document.cookie= `plan_selected=${value}`
  }

  const handleHeaderItemClicked = (index) => {
    const toDocs = () => {
      window.open(
          'https://www.notion.so/Refmonkey-Wiki-dfaa6ddff3bd4feba51bd40cb086450d',
          '_blank' // <- This is what makes it open in a new window.
      )
    }
    const toContact = () => {
      history.push('/contact')
    }

    const toBlog = () => {
      window.open(
        'https://refmonkey.com/blog',
        '_blank' // <- This is what makes it open in a new window.
      )
    }

    const toPricing = () => {
      history.push('/pricing')
    }

    const toRoadmap = () => {
      window.open(
        'https://refmonkey.canny.io',
        '_blank' // <- This is what makes it open in a new window.
      )
    }



    if (showPricing) {
            switch (index) {
                // case 0:
                //     toBlog()
                //     break
                case 0:
                    toDocs()
                    break
                // case 1:
                //     scrollToFeatures()
                //     break
                // case 2:
                //     scrollToPricing()
                //     break
                case 1:
                    toPricing()
                    break
               /* case 4:
                    toContact()
                    break*/
                case 2:
                    toRoadmap()
                    break
                default:
                    break
            }
        } else {
            switch (index) {
                // case 0:
                //     toBlog()
                //     break
                case 0:
                    toDocs()
                    break
                // case 2:
                //     toDocs()
                //     break
                /*case 3:
                    toContact()
                    break*/
                // case 1:
                //     toPricing()
                //     break
                case 1:
                    toRoadmap()
                    break
                default:
                    break
            }
        }
  }

  const handleLoginClicked = () => {
    history.push('/login')
  }



  return (
    <div>
      <div className='relative'>
        <Nav
          color='text-indigo-600'
          loginBtnTailwind='text-indigo-600 hover:text-indigo-500'
          headerItems={showPricing ? ['Features / Docs', 'Pricing','Roadmap'] : ['Features / Docs','Roadmap']}
          logo={<Logo
            showText={false}
          />}
          bgGrayShort={false}
          onHeaderItemClicked={handleHeaderItemClicked}
          onLoginClicked={handleLoginClicked}/>
      </div>
      {showPricing &&
      <>
        <DescriptionItem
          tailwind='my-24'
          title={`Effortless Referrals`}
          content={`Setup your referral program, sit back and let your customers grow your business for you.
                 Let your creativity run with our flexible API integration and pricing model.`}
        />

        <div
          id='pricing'
          ref={pricingRef}>
          <Pricing
            creditCardRequired
            trialDays={14}
            title='Simple, straight forward pricing'
            showAnnual={false}
            items={getPricingItems()}
            onSelect={handleGetStarted}
          />
        </div>
      </>
      }

      <FrequentlyAsked
        // tailwind='mt-12'
        items={frequentlyAskedData}
      />
    </div>

  );
};

export default PricingFaq;
