import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import Card from 'components/card'

const DocButton = ({ onClick, image, color, title, content ,iconTailwind}) => {
    return (
        <Card
            onClick={onClick}
            className='rounded-lg bg-indigo-50 hover:bg-indigo-100 h-32 flex m-8 cursor-pointer  transition duration-200'>
            <div className={`rounded-l h-32 w-32 flex items-center justify-center ${color} ${iconTailwind}`}>
                {image}
            </div>

            <div className='p-4 flex flex-col justify-center'>
                <Text type='heading-small'>{title}</Text>
                <Text
                tailwind='text-gray-800'
                color>{content}</Text>

            </div>
        </Card>
    );
};

DocButton.propTypes = {

};

export default DocButton;