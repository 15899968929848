import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {
    return (
        <Request
            id='GetAdvocateEmail'
            title='Get advocate email'
            description='This function allows you to get advocate email'
            codeSnippet={` window.refMonkeyClient.GetAdvocateEmail(identifier) `}
            parameters={
                <>
                    <ParamItem
                        required={true}
                        title='identifier'
                        type='string'
                        content={`A unique identifier for the user in your system. This can be an email address, username or id`}
                    />
                </>
            }
        />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;