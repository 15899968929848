import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CountItem from 'app/setup/components/count-item'
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { URL, ADD_URL } from '../../constants/GqlQueries'
import Url from '../Url'
import TextInput from 'components/textinput/index'
import Form from 'components/form'
import FieldInput from 'components/fieldinput'
import GrayCode from 'components/code-box/gray-code'
import Button from 'components/button'
import Text from 'components/text'
import Card from 'components/card'
import styled from 'styled-components'

const CardWrapper = styled(Card)`
    min-width: 28rem;
    
`

function InsertUrls({ number }) {
    const [url, setUrl] = useState('');

    const [addUrl, { loading: addUrlLoading }] = useMutation(ADD_URL, {
        onError: () => { },
        refetchQueries: [
            { query: URL }
        ]
    })

    const { loading, data, error } = useQuery(URL);


    const formSubmit = () => {
        if (url.trim().length > 0) {
            addUrl({
                variables: {
                    url: url.trim()
                }
            })
        }
    };


    return (

        <div className='w-full'>

            <Text>Enter the url paths which should be valid paths that refmonkey should detect conversions on.</Text>

            <div className='w-full flex flex-col items-center justify-center '>
                <div className='w-full max-w-md'>
                    <Form
                        tailwind='w-full'
                        onSubmit={formSubmit}>
                        <div className='w-full'>
                            <FieldInput
                                tailwind='w-full mt-10'
                                label='URL Path'
                                type='text'
                                value={url}
                                placeholder='i.e    /register'
                                // helpText={emptyCampaignName ? 'Campaign name cannot be empty' : ''}
                                onChange={setUrl}
                            />
                            <div className='flex justify-end w-full'>
                                <Button
                                    loading={addUrlLoading}
                                    typeSubmit
                                    tailwind='my-6'>
                                    Add
                        </Button>
                            </div>
                        </div>
                    </Form>
                    {data && (data.getWordpressUrl.length > 0 ?
                        <>
                            <CardWrapper tailwind=' my-6 w-full'>

                                <div className='border-b border-gray-200'>
                                    <div className={`grid grid-cols-4 py-4 px-6 mr-12`}>
                                        <div className="col-span-3">
                                            URL Path
                              </div>

                                        <div className="col-span-1">
                                            {/** edit button */}
                                        </div>
                                    </div>
                                </div>

                                {
                                    data.getWordpressUrl.map((value, index) => {
                                        return <Url
                                            index
                                            isGray={index % 2 === 0}
                                            url={value}
                                        />
                                    })
                                }

                            </CardWrapper>
                        </> : null


                    )}

                </div>
            </div>
        </div>
    )
}

InsertUrls.propTypes = {

}

export default InsertUrls

