import React from 'react';
import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (

        <Request
        title='Get referral'
        description={`get a referral's data`}
        method='get'
        path='/referral'
        tabs={[{
            title: 'Axios',
            script: false,
            code:` 
        instance.get('https://api.refmonkey.com/referral?identifier=\${identifier}')
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
             `,
        },
            {
                title: 'curl',
                script: false,
                code: ` 
        curl https://api.refmonkey.com/referral?identifier=\${identifier} --header "Authorization: YOUR_ACCESS_TOKEN"        
             `,
            }]}
        inputParameters={
            <>
                <ParamItem
                    required={true}
                    title='identifier'
                    type='string'
                    content={`A unique identifier for the user in your system. This can be an email address, username or id`}
                />
            </>
        }

        outputParameters={
            <>
                <ParamItem
                    title='success'
                    type='boolean'
                    content={`Indicates the outcome of the API call.`}
                />
                <ParamItem
                    title='identifier'
                    type='string'
                    content={`A unique identifier for the user in your system.`}
                />

                <ParamItem
                    title='name'
                    type='string'
                    content={`The name of the user in your system`}
                />

                <ParamItem
                    title='referredBy'
                    type='string'
                    content={`A unique identifier for the advocate that referred the referral`}
                />


            </>
        }
        sampleResponse={`
    {
        "success": true,
        "name": "George",
        "identifier": "michelle@gmail.com",
        "referredBy": "jason@gmail.com",
    }
    `}
    />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;
