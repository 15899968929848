import React from 'react';

import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
            id='reverse-conversion'
            title='Reverse Conversion'
            description='This endpoint allows you to revert a conversion after the conversion api had been called.'
            method='post'
            path='/conversion/reverse'
            tabs={[{
              title: 'Axios',
              script: false,
              code:` 
        instance.post('https://api.refmonkey.com/conversion/reverse', {
            conversion_id: '\${conversion_id}'
        })
             `,
            },
              {
                title: 'curl',
                script: false,
                code: ` 
        curl -X POST --data "conversion_id=\${conversion_id}" https://api.refmonkey.com/conversion/reverse --header "Authorization: YOUR_ACCESS_TOKEN"          
             `,
              }]}
            inputParameters={
                <>
                    <ParamItem
                        required={true}
                        title='conversion_id'
                        type='string'
                        content={`The ID associated with the conversion`}
                    />

<ParamItem
                        required={false}
                        title='message'
                        type='string'
                        content={`A message describing the reason for reversing the conversion.`}
                    />
                </>
            }

            outputParameters={
                <>
                    <ParamItem
                        title='success'
                        type='boolean'
                        content={`Indicates the outcome of the API call.`}
                    />
                    <ParamItem
                        title='message'
                        type='string'
                        content={`Provides additional details`}
                    />
                </>
            }
            sampleResponse={`
            {
                "success": true,
                "message": "conversion successfully reversed"
            }
            `}
        />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;
