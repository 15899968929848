import React from "react";

const banner = (props) => {
  return (
    <div class="sticky top-0 bg-indigo-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            <span class="md:hidden">
              Need help? Check out our &nbsp;
              <a href="https://www.notion.so/Refmonkey-Wiki-dfaa6ddff3bd4feba51bd40cb086450d"
                 class="text-white font-bold underline">wiki</a>
              &nbsp; on how to use Refmonkey, or email us anytime at &nbsp;
              <a href="mailto:help@refmonkey.com"
                className="text-white font-bold underline">help@refmonkey.com</a>.
            </span>
            <span class="hidden md:inline">
              Need help? Check out our &nbsp;
              <a href="https://www.notion.so/Refmonkey-Wiki-dfaa6ddff3bd4feba51bd40cb086450d"
                 class="text-white font-bold underline">wiki</a>
              &nbsp; on how to use Refmonkey, or email us anytime at &nbsp;
              <a href="mailto:help@refmonkey.com"
                className="text-white font-bold underline">help@refmonkey.com</a>.
            </span>
            {/*<span class="block sm:ml-2 sm:inline-block">*/}
            {/*  <a href="https://calendly.com/steven-239/refmonkey-help" class="text-white font-bold underline"> Schedule here -{'>'} </a>*/}
            {/*</span>*/}
          </p>
        </div>
        <div class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button type="button" class="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
            <span class="sr-only">Dismiss</span>
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" onClick= {()=>props.click()}>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default banner;
