import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useIsShopify from '../hooks/useIsShopify';
import ShopifySettings from './ShopifySettings';
import DefaultSettings from './defaultSettings';
import { isErrorCode } from '../../components/signin/utils';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_SETTINGS, SET_SETTINGS } from './constants/GqlQueries';
import gql from 'graphql-tag';
import { HTTP } from '../../constants';



const Settings = ({ onSave, saveText = 'Save', saveButtonTailwind, onError, showTabs, manualSelectedTabIndex }) => {

    const { usesShopify } = useIsShopify()

    const [rewardAdvocates, setRewardAdvocates] = useState()
    const [rewardReferrals, setRewardReferrals] = useState()

    const [notifAffSignup, setNotifAffSignup] = useState()
    const [notifPayoutRequest, setNotifPayoutRequest] = useState()

    const [rewardAdvocatesName, setRewardAdvocatesName] = useState()
    const [rewardAdvocatesValue, setRewardAdvocatesValue] = useState()
    const [rewardAdvocatesType, setRewardAdvocatesType] = useState()

    const [rewardReferralsName, setRewardReferralsName] = useState()
    const [rewardReferralsValue, setRewardReferralsValue] = useState()
    const [rewardReferralsType, setRewardReferralsType] = useState()

    const [affDashboardUrl, setAffDashboardUrl] = useState()
    const [showBusinessLogoaffDashboardUrl,setShowBusinessLogoaffDashboardUrl] = useState('');
    const [customDomain, setCustomDomain] = useState('')

    const [defaultUrl, setDefaultUrl] = useState()
    const [recurringReward, setRecurringReward] = useState()
    const [rewardAfterPurchase, setRewardAfterPurchase] = useState()
    const [referralsRequired, setReferralsRequired] = useState()
    const [requestPayout, setRequestPayout] = useState()
    const [payoutMinDays, setPayoutMinDays] = useState(0)
    const [termsUrl, setTermsUrl] = useState()
    const [enableTerms, setEnableTerms] = useState()
    const [enablePaypal, setEnablePaypal] = useState()
    const [approveAdvocates, setApproveAdvocates] = useState()


    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showSetError, setShowSetError] = useState(false)

    const [showInvalidAffDashboardLink, setShowInvalidAffDashboardLink] = useState(false)
    const [showTakenAffDashboardLink, setShowTakenAffDashboardLink] = useState(false)

    const [language, setLanguage] = useState();

    const [Error, setError] = useState(null);
    const [showStripeErrorMessage, setShowStripeErrorMessage] = useState(false)

    const { data, getSettingsLoading, error } = useQuery(GET_SETTINGS, {
        onError: () => { onError && onError() }
    })

  

    const [setReward, { loading: setSettingsLoading }] = useMutation(SET_SETTINGS, {
        onError: error => {

            if (isErrorCode(error, 12)) {
                setShowTakenAffDashboardLink(true)
            } else {
                setShowSetError(true)
            }
        },
        update: (cache) => {
            const { settings } = cache.readQuery({ query: GET_SETTINGS })
            setShowBusinessLogoaffDashboardUrl(settings.affDashboardUrl);
            cache.writeQuery({
                query: GET_SETTINGS,
                data: {
                    settings: {
                        __typename: settings.__typename,
                        rewardAdvocates: {
                            __typename: settings.rewardAdvocates.__typename,
                            status: rewardAdvocates,
                            name: rewardAdvocatesName,
                            value: rewardAdvocatesValue,
                        },
                        rewardReferrals: {
                            __typename: settings.rewardReferrals.__typename,
                            status: rewardReferrals,
                            name: rewardReferralsName,
                            value: rewardReferralsValue,
                        },
                        notifications: {
                            __typename: settings.notifications.__typename,
                            affSignup: notifAffSignup,
                            payoutRequest: notifPayoutRequest
                        },
                        rewardAdvocatesType,
                        rewardReferralsType,
                        referralsRequired,
                        rewardAfterPurchase,
                        defaultUrl,
                        recurringReward,
                        requestPayout,
                        termsUrl,
                        enableTerms,
                        payoutMinDays,
                        enablePaypal,
                        approveAdvocates,
                        affDashboardUrl,
                        customDomain,
                        language
                    }
                }
            })

            onSave && onSave()
            setShowSuccess(true)
        }
    })


    useEffect(() => {
        setShowError(error)
    }, [error])

    useEffect(() => {
        if (data) {
            const { settings } = data

            setNotifAffSignup(settings.notifications && settings.notifications.affSignup)
            setNotifPayoutRequest(settings.notifications && settings.notifications.payoutRequest)

            setRewardAdvocates(settings.rewardAdvocates.status)
            setRewardAdvocatesName(settings.rewardAdvocates.name)
            setRewardAdvocatesValue(settings.rewardAdvocates.value)
            setRewardAdvocatesType(settings.rewardAdvocatesType)

            setRewardReferrals(settings.rewardReferrals.status)
            setRewardReferralsType(settings.rewardReferralsType)
            setRewardReferralsValue(settings.rewardReferrals.value)
            setRewardReferralsName(settings.rewardReferrals.name)
            setLanguage(settings.language)
            setReferralsRequired(settings.referralsRequired)
            setRequestPayout(settings.requestPayout)
            setPayoutMinDays(settings.payoutMinDays)
            setEnablePaypal(settings.enablePaypal)
            setApproveAdvocates(settings.approveAdvocates)
            setTermsUrl(settings.termsUrl)
            setEnableTerms(settings.enableTerms)
            setRewardAfterPurchase(settings.rewardAfterPurchase)
            setDefaultUrl(settings.defaultUrl)
            setAffDashboardUrl(settings.affDashboardUrl)
            setShowBusinessLogoaffDashboardUrl(settings.affDashboardUrl);
            setCustomDomain(settings.customDomain)
            setRecurringReward(settings.recurringReward)
            setLanguage(settings.language)

        }
    }, [data])

    const disableStates = () => {
        setShowSuccess(false)
        setShowError(false)
        setShowSetError(false)
        setShowInvalidAffDashboardLink(false)
        setShowTakenAffDashboardLink(false)
    }

    const handleFormSubmit = () => {
        let error = false

        disableStates()


        if ((!affDashboardUrl || affDashboardUrl.indexOf(' ') >= 0) || (affDashboardUrl.indexOf('.') >= 0)) {
            setShowInvalidAffDashboardLink(true)
            error = true
        }

       
        let cleanedCustomDomain;
        if (customDomain) {
             cleanedCustomDomain = customDomain.trim() 
            cleanedCustomDomain = cleanedCustomDomain.replace(HTTP,'')
            cleanedCustomDomain = cleanedCustomDomain.replaceAll('/','')
            
        }

        if (!error) {
            
            setReward({
                variables: {
                    notifAffSignup,
                    notifPayoutRequest,
                    rewardAdvocatesStatus: rewardAdvocates,
                    rewardAdvocatesName: rewardAdvocatesName,
                    rewardAdvocatesValue: rewardAdvocatesValue,
                    rewardReferralsStatus: rewardReferrals,
                    rewardReferralsName: rewardReferralsName,
                    rewardAdvocatesType: rewardAdvocatesType ? parseInt(rewardAdvocatesType) : null,
                    rewardReferralsType: rewardReferralsType ? parseInt(rewardReferralsType) : null,
                    rewardReferralsValue: rewardReferralsValue,
                    rewardAfterPurchase,
                    requestPayout,
                    termsUrl,
                    enableTerms,
                    enablePaypal,
                    approveAdvocates,
                    payoutMinDays: parseInt(payoutMinDays),
                    defaultUrl,
                    affDashboardUrl,
                    customDomain: cleanedCustomDomain,
                    language,
                    recurringReward,
                    referralsRequired: parseInt(referralsRequired)
                }
            })
            
        }
    }

    return (
        usesShopify ?
            <ShopifySettings
                saveText={saveText}
                saveButtonTailwind={saveButtonTailwind}
                onError={onError}
                setStates={{
                    setRewardAdvocates, setRewardReferrals, setRewardAdvocatesName, setRewardAdvocatesType, setRewardReferralsType,
                    setRewardAdvocatesValue, setRewardReferralsName, setRewardReferralsValue,
                    setAffDashboardUrl, setDefaultUrl, setRecurringReward, setRewardAfterPurchase,
                    setReferralsRequired, setShowSuccess, setShowSetError, setCustomDomain,
                    setTermsUrl, setShowError, setEnableTerms, setEnablePaypal, setApproveAdvocates,
                    setShowInvalidAffDashboardLink, setShowTakenAffDashboardLink, setRequestPayout,
                    setPayoutMinDays, setNotifAffSignup, setNotifPayoutRequest, setLanguage, setError, setShowStripeErrorMessage,setShowBusinessLogoaffDashboardUrl
                }}
                states={{
                    rewardAdvocates, rewardReferrals, rewardAdvocatesValue, rewardAdvocatesName,
                    rewardReferralsName, rewardAdvocatesType, rewardReferralsType, rewardReferralsValue, affDashboardUrl, defaultUrl,
                    recurringReward, rewardAfterPurchase, referralsRequired, showSuccess,
                    showError, showInvalidAffDashboardLink, showSetError, termsUrl,
                    requestPayout, enableTerms, enablePaypal, approveAdvocates, customDomain,
                    showTakenAffDashboardLink, payoutMinDays, notifPayoutRequest, notifAffSignup, language, Error, showStripeErrorMessage, showBusinessLogoaffDashboardUrl
                }}
                disableStates={disableStates}
                loading={{ getSettingsLoading, setSettingsLoading }}
                onFormSubmit={handleFormSubmit}
                data={data}
            />
            :
            <DefaultSettings
                data={data}
                saveText={saveText}
                saveButtonTailwind={saveButtonTailwind}
                onError={onError}
                showTabs={showTabs}
                manualSelectedTabIndex={manualSelectedTabIndex}
                loading={{ getSettingsLoading, setSettingsLoading }}
                setStates={{
                    setRewardAdvocates, setRewardReferrals, setRewardAdvocatesName, setRequestPayout,
                    setRewardAdvocatesValue, setRewardReferralsName, setRewardReferralsValue, setRewardAdvocatesType, setRewardReferralsType,
                    setAffDashboardUrl, setDefaultUrl, setRecurringReward, setRewardAfterPurchase, setCustomDomain,
                    setReferralsRequired, setShowSuccess, setShowSetError, setTermsUrl, setShowError,
                    setShowInvalidAffDashboardLink, setShowTakenAffDashboardLink, setPayoutMinDays, setLanguage,
                    setNotifAffSignup, setNotifPayoutRequest, setEnableTerms, setEnablePaypal, setApproveAdvocates, setError, setShowStripeErrorMessage, setShowBusinessLogoaffDashboardUrl
                }}
                states={{
                    rewardAdvocates, rewardAdvocatesType, rewardReferrals, rewardAdvocatesValue, rewardAdvocatesName,
                    rewardReferralsName, rewardReferralsValue, affDashboardUrl, defaultUrl,
                    recurringReward, rewardAfterPurchase, referralsRequired, showSuccess,
                    showError, showInvalidAffDashboardLink, showSetError, language, customDomain,
                    requestPayout, termsUrl, enableTerms, enablePaypal, approveAdvocates,
                    showTakenAffDashboardLink, payoutMinDays, rewardReferralsType, notifPayoutRequest, notifAffSignup, Error, 
                    showStripeErrorMessage, showBusinessLogoaffDashboardUrl
                }}
                onFormSubmit={handleFormSubmit}
                disableStates={disableStates}
            />
    );
};

Settings.propTypes = {

};

export default Settings;