import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WarningSVG } from 'images/warning.svg'
import Icon from 'components/icon'
import Text from 'components/text'

const CustomDomainError = props => {
    return (
        <div className=''>

            <div className='  min-h-screen flex items-center justify-center flex-col'>
                <Icon
                    size
                    color
                    tailwind='w-24 h-24 text-orange-400 mb-6 p-4'
                    src={<WarningSVG />}
                />

                <Text >
                    Custom domain has not been setup properly.
                    </Text>
            </div>
        </div>
    );
};

CustomDomainError.propTypes = {

};

export default CustomDomainError;