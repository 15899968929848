import React from 'react';
import Logo from 'components/logo'
import LogoPNG from 'images/logo.png'

const index = ({tailwind, clickable=true,showText=true}) => {
    return (
        <Logo
        clickable={clickable}
        tailwind={tailwind}
        showText={showText}
            color='text-gray-800'
            icon={LogoPNG}
            text='RefMonkey' />
    );
};

export default index;
