import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import FormContent from 'components/signin/components/content'
import Text from 'components/text'
import Button from 'components/button'
import Container from 'components/container'
import Card from 'components/card'
import Icon from 'components/icon'
// import logoIcon from 'images/logo.png'
import GrayDiv from 'components/gray-div'
import Loading from 'components/loading'
import { useHistory, useRouteMatch, useLocation, Route } from 'react-router-dom';
import { REGISTER, LOGIN, RESET_PASSWORD, IS_PASSWORD_RESET_VALID, CHANGE_TOKEN_PASSWORD } from './constants/GqlQueries';
import { signin } from './utils/authUtil';
import ErrorAlert from 'components/alert/error'
import ForgotPasswordContent from '../../components/signin/components/forgot-password/components/content';
import ResetPasswordContent from '../../components/signin/components/reset-password/components/content';
import { isErrorCode } from '../../components/signin/utils';
import Alert from 'components/alert'
import { BASE_URL } from '../../constants';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Context } from '../../languages/language-context'
import useDashboardCode from './hooks/useDashboardCode';
import CustomDomainError from './components/custom-domain-error';


const FETCH_LOGO = gql`
     query getLogo($business:String!){
         logo(business:$business){
             data
             contentType
         }
     }
`

const GET_LANGUAGE_SETTING = gql`
query getLanguage($referralDashboardCode: String) {
    getLanguage(referralDashboardCode: $referralDashboardCode)
}
`


const AffiliateLogin = ({ intl }) => {
    const context = useContext(Context);
    const history = useHistory()
    const location = useLocation()

    const [showError, setShowError] = useState(false)
    const [showWrongPassword, setShowWrongPassword] = useState(false)
    const [showWrongAffiliateProgram, setShowWrongAffiliateProgram] = useState(false)
    const [showAlreadyExists, setShowAlreadyExists] = useState(false)
    const [termsUrl, setTermsUrl] = useState("#")

    const { settingsData, referralDashboardCode, customDomainError } = useDashboardCode()

    //displaying logo
    let display;
    const fetchLogoInfo = useQuery(FETCH_LOGO, {
        variables: {
            business: referralDashboardCode
        }
    });

    if (fetchLogoInfo.loading) {
        display = <Loading />
    }
    if (fetchLogoInfo.error) {

    }
    if (fetchLogoInfo.data && !fetchLogoInfo.data.logo.data) {
        display = null;
    }

    if (fetchLogoInfo.data && fetchLogoInfo.data.logo.data) {
        display = (<img className="w-20 h-20 object-contain rounded-xl" src={`data:${fetchLogoInfo.data.logo.contentType};base64, ${fetchLogoInfo.data.logo.data}`} alt="" />)
    }

    //displaying logo ended......


    const languageInfo = useQuery(GET_LANGUAGE_SETTING, {
        variables: {
            referralDashboardCode: referralDashboardCode
        }
    });


    useEffect(() => {

        if (languageInfo.data) {
            context.changeLanguage(languageInfo.data.getLanguage);
        } else if (languageInfo.error) {
            setShowError(true);
        }

    }, [languageInfo.data])

    useEffect(() => {

        if (settingsData) {
            const url = settingsData.advocatePanelSettings.termsUrl

            if (url && (url.includes('http://') || url.includes('https://'))) {
                setTermsUrl(url)
            } else {
                setTermsUrl(`https://${url}`)
            }
        }
    }, [settingsData,customDomainError])

    const [login, { data: loginData, loading: loginLoading }] = useMutation(LOGIN, {
        //need to localize the language on the server!!!
        onError: error => {
            if (isErrorCode(error, 17)) {
                setShowWrongAffiliateProgram(true)
            } else if (isErrorCode(error, 9)) {
                setShowWrongPassword(true)
            }else{
                setShowError(true)
            }
        }
    })
    const [register, { data: registerData, loading: registerLoading }] = useMutation(REGISTER, {
        onError: error => {
            if (isErrorCode(error, 17)) {
                setShowWrongAffiliateProgram(true)
            } else if (isErrorCode(error, 5)) {
                setShowAlreadyExists(true)
            } else {
                setShowError(true)
            }
        }
    })


    useEffect(() => {
        if (loginData) {
            const { advocateLogin } = loginData

            if (advocateLogin && advocateLogin.refCode) {
                //success
                loggedIn(advocateLogin.refCode)
            } else {
                setShowError(true)
            }
        }
    }, [loginData])


    useEffect(() => {
        if (registerData) {
            const { advocateRegister } = registerData

            if (advocateRegister && advocateRegister.refCode) {
                //success
                loggedIn(advocateRegister.refCode)
            } else {
                setShowError(true)
            }
        }
    }, [registerData])



    history.listen(() => {
        setShowError(false)
        setShowWrongPassword(false)
        setShowAlreadyExists(false)
        setShowWrongAffiliateProgram(false)
    })

    const loggedIn = (refCode) => {
        signin(refCode, referralDashboardCode)
        history.replace('/home')
    }

    const handleRegister = (name, email, password) => {
        setShowError(false)
        setShowWrongPassword(false)
        setShowAlreadyExists(false)
        setShowWrongAffiliateProgram(false)

        register({
            variables: {
                referralDashboardCode,
                email,
                name,
                password
            }
        })

    }

    const handleLogin = (email, password) => {
        setShowError(false)
        setShowWrongPassword(false)
        setShowAlreadyExists(false)
        setShowWrongAffiliateProgram(false)

        login({
            variables: {
                referralDashboardCode,
                email,
                password
            }
        })
    }

    return (

        <GrayDiv tailwind={context.local === 'he' ? 'min-h-screen w-full text-right' : 'min-h-screen w-full'}>
           
            {customDomainError ?

                <CustomDomainError />

                :
                <>
                    <Container
                        tailwind='items-center flex flex-col justify-center w-full'
                        narrow>
                        {/** <Icon size
                png
                hasBackground={false}
                src={logoIcon}
                tailwind='w-16 h-16'
                round={false} />
                */}
                        <div>
                            {display}
                        </div>
                        <Text
                            tailwind='my-6'
                            type='heading'>
                            <FormattedMessage id="register.welcome" defaultMessage="Welcome to our affiliate program!" />
                        </Text>

                        <Route path={`/auth/reset`}>
                            {/* CHANGE LANGUAGE */}
                            <ResetPasswordContent
                                aff={true}
                                narrowContainer={false}
                                changeTokenPasswordAdditionalVariables={{ referralDashboardCode }}
                                isPasswodValidAdditionalVariables={{ referralDashboardCode }}
                                gqlQueryIsPasswordValid={IS_PASSWORD_RESET_VALID}
                                gqlMutationChangeTokenPassword={CHANGE_TOKEN_PASSWORD}
                                processData={data => data.isAdvocatePasswordResetValid}
                            />
                        </Route>


                        <Route path={`/auth/forgot-password`}>
                            {/* CHANGE LANGUAGE */}
                            <ForgotPasswordContent
                                aff={true}
                                narrowContainer={false}
                                gqlMutation={RESET_PASSWORD}
                                additionalVariables={{ referralDashboardCode }}
                            />
                        </Route>

                        <Route path={`/auth/login`}>
                            <Text
                                tailwind='my-8 text-center'
                                type='text-small'>
                                <FormattedMessage id="login.appeal" defaultMessage="Login and earn rewards. you will get access to your custom referral link and your dashboard." />
                            </Text>

                            <Card tailwind='w-full p-6'>

                                <FormContent
                                    aff={true}
                                    showError={showError}
                                    toRight={true}
                                    loading={loginLoading}
                                    showPasswordField
                                    onSubmit={handleLogin}
                                    showNameField={false}
                                    forgotPasswordPath={`/auth/forgot-password`}
                                    showForgotPassword
                                    showWrongPassword={showWrongPassword}
                                    showWrongAffiliateProgram={showWrongAffiliateProgram}
                                    submitText={intl.formatMessage({ id: "login" })}
                                />
                            </Card>

                        </Route>
                        <Route exact path={`/auth`}>


                            <Text
                                tailwind='my-8 text-center'
                                //CHANGE LANGUAGE
                                type='text-small'>
                                <FormattedMessage id="register.appeal" defaultMessage="Sign up and earn rewards. you will get access to your custom referral link and your own dashboard." />
                            </Text>
                            <Alert
                                content={intl.formatMessage({ id: "register.emailAlreadyExists" })}
                                variant='warning'
                                id='already-exists'
                                tailwind='mb-2'
                                isShown={showAlreadyExists} />

                            <Card tailwind='w-full p-6'>
                                <FormContent
                                    //FORM CHANGE LANGUAGE
                                    aff={true}
                                    termsText={<p>
                                        {/* I agree to <a target="_blank" className='text-indigo-600 underline' href={termsUrl}>Affiliate Terms</a> */}
                                        <FormattedMessage id="register.terms"
                                            values={{ link: word => <a target="_blank" className='text-indigo-600 underline' href={termsUrl}>{word}</a> }}
                                        />
                                    </p>}
                                    showTerms={settingsData && settingsData.advocatePanelSettings.enableTerms}
                                    showError={showError}
                                    showWrongAffiliateProgram={showWrongAffiliateProgram}
                                    loading={registerLoading}
                                    showPasswordField={true}
                                    onSubmit={handleRegister}
                                    showNameField={true}
                                    submitText={intl.formatMessage({ id: "register.submitButton" })}
                                />

                                <Button
                                    padding
                                    tailwind='mt-8'
                                    variant='text'
                                    onClick={() => history.push(`/auth/login`)}

                                >
                                    <FormattedMessage id="register.loginLink" defaultMessage="Already have an account? <Text span tailwind='text-indigo-600 mx-2'> Login here</Text> "
                                        values={{ text: word => <Text span tailwind='text-indigo-600 mx-2'>{word}</Text> }} />

                                </Button>
                            </Card>


                        </Route>
                    </Container>
                </>
            }
        </GrayDiv>
    );
};

AffiliateLogin.propTypes = {

};

export default injectIntl(AffiliateLogin);