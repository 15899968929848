import { gql } from 'apollo-boost'

export const GET_CAMPAIGNS = gql`
    query{
        campaigns{
            cid
            isArchived
            logo{
              data
              contentType
            }
            title
        }
    }
`

export const GET_SELECTED_CAMPAIGN = gql`
    query{
      selectedCampaign{
            cid
            isArchived
            title
        }
    }
`
export const SET_SELECTED_CAMPAIGN = gql`
mutation($cid: String){
  selectCampaign(cid:$cid)
}
`



export const EDIT_CAMPAIGN = gql`
mutation($cid: String, $title: String){
  editCampaign(title:$title, cid: $cid){
    cid
  }
}
`

export const ARCHIVE_CAMPAIGN = gql`
mutation($cid: String, $isArchived: Boolean){
  archiveCampaign(campaign: {isArchived:$isArchived, cid: $cid}){
    cid
    isArchived
  }
}
`

export const CREATE_CAMPAIGN = gql`
mutation($title: String){
  createCampaign(title:$title)
}
`