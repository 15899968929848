import { gql } from 'apollo-boost'





export const GET_PENDING_REWARDS = gql`
query{
    pendingRewards{
    ids
     name
     identifier
     date
     rewardName
     rewardValue
     referralIdentifier
     units
     type
     
 } 

}`

export const GET_CSV_PAYOUT = gql`
query{
    csvPayout{
        currency
        total
        payouts{
            id
            paypalEmail
            currency
            payout
            value
            note
        }
 } 

}`

export const REDEEM_REWARD = gql`
mutation($identifier: String!,$type: String!,$ids : [String]!){
    redeemReward(identifier: $identifier,type:$type,ids: $ids)
}
`