import React from "react";
export default function MyComponent() {
  const html = `
<!doctype html>
<html lang="en">
	<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Refmonkey Blog Post</title>
  <meta name=description content="Blog post description"/>
  <link rel="canonical" href="https://fiuti.com/blog"/>

  <style>
	  body{font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";background:#f6f6f6;margin:8px}.main-page{line-height:150%;padding:30px;font-size:16px}.main-paige,h1{line-height:100%}@media screen and (max-width:600px){.main-page{padding:10px}}.navbar{background:#5850EC;padding:5px 20px;color:#fff;border-radius:5px}.wrapper{display:flex;flex-wrap:wrap;justify-content:space-around}.page{width:65%;max-width:800px;margin-left:auto;margin-right:auto;background:#fff;border-top:4px solid #5850EC;border-bottom:4px solid #5850EC;box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)}.sidebar{width:25%;height:70vh;overflow:auto;position:-webkit-sticky;position:sticky;top:5%;max-width:300px;margin-left:auto;margin-right:auto}@media screen and (max-width:700px){.sidebar{width:100%;position:static;height:auto}.page{width:100%}}.page img{box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);transition:.3s cubic-bezier(.25,.8,.25,1);border:1px solid silver;margin:20px 0}.page,.sidebar{border-radius:5px;padding:15px}img{width:100%;border-radius:3px}.btn{background:#5850EC;color:#fff;text-align:center;display:inline-flex;justify-content:center;padding:10px;width:96%;margin-left:auto;margin-top:20px;border-radius:5px;text-decoration:none}#signup{margin-top:30px;padding-top:30px}h1{font-size:2em;margin:0.67em 0;font-weight: bold;}h2{font-size:1.5em; margin:20px 0}p{margin:16px 0}a{color:#0602ED;text-decoration: underline;}
  </style>
</head>

<body>
	<div class='navbar'>
<div style="display:flex; justify-content:space-between;">
<a href="/"> 	<img class="" src="https://refmonkey.com/static/media/logo.dd63738b.png" style="width:auto;height:45px;" /></a>
	<a href="/" style="color:white;display:inline-flex; align-items:center; text-decoration: none;">
		<svg style="width:24px;height:24px; margin-right:5px;" viewBox="0 0 24 24">
				<path fill="#fff" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
		</svg>
		<span>Back To Refmonkey</span>
	</a>
</div>
</div>

	<div class='main-page'>
		<div class="wrapper">
			<div class="page">
					<h1 class="">Refmonkey's Blog</h1>
<p>
	Welcome to Refmonkey's blog!
</p>

<li><a href=/blog/affiliate-link-management-software >Affiliate Link Management Software</a></li>
<li><a href=/blog/eight-best-affiliate-link-management-software-in-twentytwentyone >8 Best Affiliate Link Management Software in 2021</a></li>

				</div>

				<div class="sidebar">
	<div id="fiuti-intro">
<img class="" src="https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F0b0621cd-7121-472d-9c71-960dbc88f98b%2FUntitled_design.png?table=block&id=579dc7d0-35e1-4b9d-abd3-f0e218300793&spaceId=55b37bed-f456-49ac-84a6-47a8a6ef8776&width=1000&userId=&cache=v2">
		Refmonkey is a referral and affiliate marketing software that allows you to setup a referral or affiliate campaign in minutes! Grow your sales with word of mouth marketing today
		<a class="btn btn-blue" href="https://refmonkey.com/">
			<svg style="width:24px;height:24px;margin-right:10px;" viewBox="0 0 24 24">
				<path fill="#fff" d="M2.81,14.12L5.64,11.29L8.17,10.79C11.39,6.41 17.55,4.22 19.78,4.22C19.78,6.45 17.59,12.61 13.21,15.83L12.71,18.36L9.88,21.19L9.17,17.66C7.76,17.66 7.76,17.66 7.05,16.95C6.34,16.24 6.34,16.24 6.34,14.83L2.81,14.12M5.64,16.95L7.05,18.36L4.39,21.03H2.97V19.61L5.64,16.95M4.22,15.54L5.46,15.71L3,18.16V16.74L4.22,15.54M8.29,18.54L8.46,19.78L7.26,21H5.84L8.29,18.54M13,9.5A1.5,1.5 0 0,0 11.5,11A1.5,1.5 0 0,0 13,12.5A1.5,1.5 0 0,0 14.5,11A1.5,1.5 0 0,0 13,9.5Z"></path>
			</svg>
			Check it out!
		</a>
	</div>

</div>

			</div>
		</div>
</body>
</html>
`
	return (
			<div dangerouslySetInnerHTML={{ __html: html }} />
    );
}
