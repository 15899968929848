import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "components/card";
import { GET_REF_LINK, SET_REFCODE } from "../../constants/GqlQueries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Text from "components/text";
import CodeBox from "components/code-box";
import TextInput from "components/textinput";
import ErrorAlert from "components/alert/error";
import Button from "components/button";
import Alert from "components/alert";
import { isErrorCode } from "components/signin/utils";
import { setRefCode as setAuthRefCode } from "app/affiliate-login/utils/authUtil";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

const RefCode = ({ refCode, referralDashboardCode, setRefCode }) => {
  const [editMode, setEditMode] = useState(false);
  const [editRefCode, setEditRefCode] = useState(refCode);
  const [showInvalidRefCode, setShowInvalidRefCode] = useState(false);
  const [showTakenRefCode, setShowTakenRefCode] = useState(false);
  const [showSaveError, setShowSaveError] = useState(false);
  const [saastronauticsLinks, setSaastronauticsLinks] = useState([]);

  const [getRefLink, { data: refLinkData, error: refLinkError }] = useLazyQuery(
    GET_REF_LINK,
    {
      variables: {
        refCode,
        referralDashboardCode,
      },
    }
  );

  const [saveRefCode, { loading: saveLoading }] = useMutation(SET_REFCODE, {
    onError: (error) => {
      if (isErrorCode(error, 12)) {
        setShowTakenRefCode(true);
      } else {
        setShowSaveError(true);
      }
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: GET_REF_LINK,
        data: {
          refLink: data.setRefCode,
        },
      });

      setEditMode(false);
      setRefCode(editRefCode);
      setAuthRefCode(editRefCode);
    },
  });

  useEffect(() => {
    if (referralDashboardCode && refCode) {
      getRefLink();
      setEditRefCode(refCode);
    }

    if (
      referralDashboardCode === "saastronautics" ||
      referralDashboardCode === "testing123"
    ) {
      fetch(
        "https://api.airtable.com/v0/appp9W6A5UiCuaLqD/Table%201?api_key=key7JcPvnW1xaL51N"
      )
        .then((resp) => resp.json())
        .then((data) => {
          const links = _.map(_.get(data, ["records"]), (record) => {
            return _.get(record, ["fields", "product-links"]);
          });
          setSaastronauticsLinks(links);
        })
        .catch((err) => {
          // Error :(
          console.log("err ", err);
        });
    }
  }, [refCode, referralDashboardCode]);

  const handleSaveRefCode = () => {
    setShowInvalidRefCode(false);
    setShowTakenRefCode(false);
    setShowSaveError(false);

    const newRefCode = editRefCode.trim();

    if (newRefCode === "" || newRefCode.indexOf(" ") >= 0) {
      setShowInvalidRefCode(true);
    } else {
      saveRefCode({
        variables: {
          refCode: newRefCode,
          oldRefCode: refCode,
          referralDashboardCode,
        },
      });
    }
  };

  return (
    <Card tailwind="w-full p-6">
      {editMode ? (
        <div>
          <Alert
            aff={true}
            content={<FormattedMessage id="aff.dashboard.alert.refcodeTaken" />}
            variant="warning"
            tailwind="mb-4"
            isShown={showTakenRefCode}
          />

          <Alert
            aff={true}
            content={
              <FormattedMessage id="aff.dashboard.alert.invalidRefcode" />
            }
            variant="warning"
            tailwind="mb-4"
            isShown={showInvalidRefCode}
          />

          <ErrorAlert aff={true} tailwind="mb-4" isShown={showSaveError} />

          <div className="flex">
            <TextInput
              aff={true}
              tailwind="mx-2"
              onChange={(value) => {
                setEditRefCode(value);
              }}
              value={editRefCode}
            />

            <Button
              tailwind="ml-2"
              loading={saveLoading}
              onClick={handleSaveRefCode}
              size="extra-small"
              variant="secondary"
            >
              <FormattedMessage id="aff.dashboard.saveButton" />
            </Button>

            <Button
              tailwind="ml-2"
              onClick={() => {
                setEditRefCode(refCode);
                setEditMode(false);
              }}
              size="extra-small"
              variant="outline"
            >
              <FormattedMessage id="aff.dashboard.cancelButton" />
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between mb-4 items-center">
            <Text type="heading-small" tailwind="">
              <FormattedMessage id="aff.dashboard.share" />
            </Text>
            <Button
              onClick={() => setEditMode(true)}
              size="extra-small"
              variant="secondary"
            >
              <FormattedMessage id="aff.dashboard.customize" />
            </Button>
          </div>

          <ErrorAlert aff={true} isShown={refLinkError} />
          {!refLinkError && (
            <>
              <CodeBox tailwind="my-6">
                {refLinkData && refLinkData.refLink.link}
              </CodeBox>

              <>
                {(referralDashboardCode === "saastronautics" ||
                  referralDashboardCode === "testing123") && (
                  <>
                    {_.map(saastronauticsLinks, (link) => {
                      return (
                        <CodeBox tailwind="my-6">
                          {refLinkData && `${link}${refLinkData.refLink.code}`}
                        </CodeBox>
                      );
                    })}
                  </>
                )}
              </>
            </>
          )}
        </>
      )}
    </Card>
  );
};

RefCode.propTypes = {};

export default RefCode;
