

import React, { useRef, useState } from 'react';
import Footer from './components/footer'
import Features from 'components/features';
import FeaturesBrandPanel from 'components/features/brand-panel';

import FrequentlyAsked from 'components/frequently-asked'
import NewsLetter from 'components/news-letter'
import DescriptionItem from 'components/landing/description-item'
import { scrollToRef } from 'utils/scrollutil'
import CTA from '../../components/cta/simple-centered';
import GrayDiv from 'components/gray-div'
import { ReactComponent as TeamSVG } from 'images/team.svg'
import { ReactComponent as CodeSVG } from 'images/code.svg'
import { ReactComponent as ChartSVG } from 'images/chart.svg'
import { ReactComponent as LinkSVG } from 'images/link.svg'
import { ReactComponent as ShieldSVG } from 'images/shield.svg'
import { ReactComponent as AutomaticSVG } from 'images/adjustments.svg'
import { ReactComponent as SpeedSVG } from 'images/speed.svg'
import { ReactComponent as DashboardSVG } from 'images/home.svg'
import { ReactComponent as RewardSVG } from 'images/reward.svg'
import { ReactComponent as CubeTransparentSVG } from 'images/cube-transparent.svg'
import Testimonial from 'components/testimonial'
import LandingComponent from 'components/landing/image-right'
import { useHistory } from 'react-router-dom'
import Logo from './components/logo'
import Text from 'components/text'
import Pricing from 'components/pricing/two-tier'
import Illusation1 from 'images/illustration1.png'
import Illusation2 from 'images/illustration2.png'
import Illusation3 from 'images/illustration3.png'
import Illusation4 from 'images/illustration4.png'
import Screenshot1PNG from 'images/screenshot_1.png'
import Notification from 'components/notification'
import ZapierPNG from 'images/zapier.png'
import SlackPNG from 'images/slack.png'
import GmailPNG from 'images/gmail.png'
import WixPNG from 'images/wix.png'
import ShopifyPNG from 'images/shopify.png'
import Testimonial1PNG from 'images/testimonials/1.png'
import Testimonial2PNG from 'images/testimonials/2.png'
import 'components/fonts/index.css'
import Dialog from 'components/dialog'
import SaastronauticsPNG from 'images/saastronautics.png'
import styled from 'styled-components'
import getPricingItems from './pricingItems';
import LandingPNG from 'images/landing.png'
import FeaturesSection from 'components/features-section'
import FeaturesSection1 from 'images/featuressection1.png'
import FeaturesSection2 from 'images/featuressection2.png'
import FeaturesSection3 from 'images/featuressection3.png'
import FeaturesSection4 from 'images/featuressection4.png'
import Button from 'components/button'

const Image = styled.img`
pointer-events:none;
`
const featureItems=[{
    title: 'Turn all your customers into your sales army',
    subtitle: ['RefMonkey makes it easy to generate customer referral links for all your customers so that any of your customers can refer you new customers and get rewarded for it',
                <br />,
                <br />,
                'We support a multitude of website builder, programming languages, and have robust API support'],
    contentTitle: 'Many integration',
    content: 'We support Wix, Webflow, Shopify, Zapier, Ecwid, WordPress, and many more. Whichever platform or programming language you use, you\'ll be able to trust Refmonkey',
    contentTitle1: 'Easy installation',
    content1: ['Setting up Refmonkey is extremely easy, just copy and paste the snippets we provide into your website.',
                <br />,
                <br />,
                'And if you run into any issues, our dedicated engineering & customer success teams are available to you'],
    imgSrc:FeaturesSection1
},{
    title: 'Branded affiliate dashboard',
    subtitle: 'Each of your affiliates or users get their own dashboard to track their referrals, manage payouts/commissions/rewards and see all their data',
    contentTitle: 'Easy to manage',
    content: ['Your affiliates get their own easy to use affiliate dashboard.',
                <br />,
                <br />,
                'You can manually approve each affiliate or just auto approve anyone who signs up',
                <br />,
                <br />,
                'You can even add in your legal documents for affiliates to agree to'],
    contentTitle1: 'Your brand',
    content1: 'Customize the affiliate sign up process to match your company\'s brand',
    imgSrc:FeaturesSection2
},{
    title: 'Easily manage your referral, loyalty, affiliate, mlm campaigns',
    subtitle: 'Have access to your company dashboard to see how all your affiliates are doing',
    contentTitle: 'Email notifications',
    content: 'Get email notifications when new affiliates sign up',
    contentTitle1: 'Your company dashboard',
    content1: ['See how all your users\' referrals and affiliates are doing',

                <br />,
                <br />,
                'Tracking all their activities and adjust your settings of your affiliate campaigns'],
    imgSrc:FeaturesSection3
},{
    title: 'Your dedicated customer success team',
    subtitle: 'You are in good hands. Refmonkey\'s team is here to help you.',
    contentTitle: 'Anytime email support',
    content: 'Email us any time with your questions',
    contentTitle1: 'Workshops and calls',
    content1: 'Set up calls and workshop sessions with our team whenever you need to help on your campaigns, Refmonkey, how to get more affiliates and anything to help you create successful campaigns',
    imgSrc:FeaturesSection4
}]

const featureItems1 = [{
    title: 'Create new loyal users',
    content: 'Reward your users for sharing your brand.',
    icon: <TeamSVG />
}, {
    title: 'Lightweight script',
    content: 'keeps your site speed fast.',
    icon: <SpeedSVG />,

}, {
    title: 'Powerful APIs',
    content: `Build exactly what you need with a flexible and predictable API which is organized around REST.`
    , icon: <CodeSVG />,
}, {
    title: 'Customizable Referral links',
    content: 'RefMonkey creates unique referral links for your users to share.'
    , icon: <LinkSVG />,
}, {
    title: 'Branded affiliate dashboard',
    content: 'Affiliates can see their stats and share the referral link from their own private dashboard.'
    , icon: <DashboardSVG />,
}, {
    title: 'Two-sided rewards',
    content: 'Reward both your advocates for referring sales and their friends for signing up with cash, discounts, credits...'
    , icon: <RewardSVG />,
}, {
    title: 'Fully Automated',
    content: `Rewards are created automatically based on the set rules, there's no need for manual entry, so you can just "set it and forget it".`
    , icon: <AutomaticSVG />,
}, {
    title: 'Fraud Protection',
    content: `RefMonkey has several built-in features to stop users from abusing your referral program.`
    , icon: <ShieldSVG />,
}
]

const Landing = ({ showPricing = true, saastronautics }) => {

    const history = useHistory()

    const featuresRef = useRef()
    const pricingRef = useRef()

    const scrollToPricing = () => scrollToRef(pricingRef)
    const scrollToFeatures = () => scrollToRef(featuresRef)


    const handleLiveDemoClicked = () => {
        // history.push('/how-it-works')
        window.open(
          'https://calendly.com/steven-239/refmonkey',
          '_blank'
        )
    }

    const handleHeaderItemClicked = (index) => {
        const toDocs = () => {
            window.open(
                'https://www.notion.so/Refmonkey-Wiki-dfaa6ddff3bd4feba51bd40cb086450d',
                '_blank' // <- This is what makes it open in a new window.
            )
        }

        const toContact = () => {
            history.push('/contact')
        }

        const toBlog = () => {
            window.open(
                'https://refmonkey.com/blog',
                '_blank' // <- This is what makes it open in a new window.
            )
        }

        const toPricing = () => {
            history.push('/pricing')
        }

        const toRoadmap = () => {
            window.open(
              'https://refmonkey.canny.io',
              '_blank' // <- This is what makes it open in a new window.
            )
        }



        if (showPricing) {
            switch (index) {
                // case 0:
                //     toBlog()
                //     break
                case 0:
                    toDocs()
                    break
                // case 1:
                //     scrollToFeatures()
                //     break
                // case 2:
                //     scrollToPricing()
                //     break
                case 1:
                    toPricing()
                    break
               /* case 4:
                    toContact()
                    break*/
                case 2:
                    toRoadmap()
                    break
                default:
                    break
            }
        } else {
            switch (index) {
                // case 0:
                //     toBlog()
                //     break
                case 0:
                    toDocs()
                    break
                // case 2:
                //     toDocs()
                //     break
                /*case 3:
                    toContact()
                    break*/
                // case 1:
                //     toPricing()
                //     break
                case 1:
                    toRoadmap()
                    break
                default:
                    break
            }
        }
    }

    const handleLoginClicked = () => {
        history.push('/login')
    }

    const handleGetStarted = () => {
        history.push('/pricing')
        window.scrollTo(0, 0);
    }


    return (
        <div className='min-h-screen'>


            <Dialog
                isShown={saastronautics}
                shouldCloseOnOverlayClick={true}
                tailwind='max-w-lg w-full'
                variant='absolute'
            >
                <div className='flex flex-col p-6 items-center justify-center'>
                    <img
                        className=''
                        src={SaastronauticsPNG} alt='saastronautics logo' />

                    <Text
                        tailwind='mb-4'
                        type='heading-small'>
                        Welcome Saastronaut!
                    </Text>

                    <Text
                        tailwind='mb-4'>Lets get started to claim your deal.</Text>

                    <div className={`flex mt-4 w-full flex-col md:flex-row`}>

                        <Button tailwind={`flex md:mr-2 w-full flex-1 mb-2 md:mb-0`}
                            size='default'
                            onClick={() => history.push('/create-account-saastronautics')}
                        >Sign up</Button>

                        <Button tailwind='flex-1 md:ml-2'
                            variant='outline'
                            size='default'
                            onClick={() => handleLoginClicked()}
                        >Log in</Button>

                    </div>
                </div>
            </Dialog>

            <div className='relative'>
                <LandingComponent
                    color='text-indigo-600'
                    loginBtnTailwind='text-indigo-600 hover:text-indigo-500'
                    headerItems={showPricing ? ['Features / Docs', 'Pricing','Roadmap'] : ['Features / Docs','Roadmap']}
                    logo={<Logo
                        showText={false}
                    />}
                    bgGrayShort={false}
                    heading={{
                        blackText: 'Grow your business with',
                        colorText: 'Word Of Mouth',
                        blackText1:'marketing!'
                    }}
                    subHeading={`Set up referral, affiliate, mlm, loyalty programs for any website or mobile app through our integration (Zapier, Shopify, and many others) or API.`}
                    onHeaderItemClicked={handleHeaderItemClicked}
                    onLoginClicked={handleLoginClicked}
                    onGetStartedClicked={handleGetStarted}
                    onLiveDemoClicked={handleLiveDemoClicked}
                    liveDemoText='Book A Demo'
                    imageSrc={LandingPNG}
                    videoSrc='https://www.youtube.com/embed/MHmPwuXa9x0?rel=0'
                />
            </div>

            <div
              id='features'
              ref={featuresRef}>
                <Features
                  title='Designed for busy startup founders and marketers'
                  content='We provide everything you need.'
                  items={featureItems1}
                />
            </div>

            <FeaturesSection
              title={'Powerful enough to handle any word of mouth marketing'}
              subtitle={'Refmonkey helps you on any affiliate, referral, loyalty and other word of mouth campaigns'}
              featureItems={featureItems}
            />

            {/*<FeaturesBrandPanel*/}

            {/*    onGetStarted={handleGetStarted}*/}
            {/*    imgSrc={Screenshot1PNG}*/}
            {/*    content={`Don’t waste time writing referral logic that’s not core to your business, We've already taken care of that.`}*/}
            {/*/>*/}

            {/*<Text*/}
            {/*    color*/}
            {/*    type='text-extra-small'*/}
            {/*    tailwind='w-full text-center text-orange-500 mt-12'>Connect with more than 2000 apps</Text>*/}
            {/*<DescriptionItem*/}
            {/*    tailwind='mt-0 pt-0'*/}
            {/*    title={`Integrates seamlessly with your favourite tools`}*/}
            {/*    content={`RefMonkey is simple to set up and integrate with popular tools.`}*/}
            {/*/>*/}

            {/*<div className='w-full flex justify-between md:mt-16'>*/}
            {/*    <div className='flex justify-center w-full'>*/}
            {/*        <img*/}
            {/*            alt='slack logo'*/}
            {/*            className='h-12 w-12 md:h-16 md:w-16'*/}
            {/*            src={SlackPNG} />*/}
            {/*    </div>*/}
            {/*    <div className='flex justify-center w-full'>*/}

            {/*        <img*/}
            {/*            alt='zapier logo'*/}

            {/*            className='h-12 w-12 md:h-16 md:w-16'*/}
            {/*            src={ZapierPNG} />*/}
            {/*    </div>*/}
            {/*    <div className='flex justify-center w-full'>*/}

            {/*        <img*/}
            {/*            alt='gmail logo'*/}

            {/*            className='h-12 w-12 md:h-16 md:w-16'*/}
            {/*            src={WixPNG} />*/}
            {/*    </div>*/}
            {/*    <div className='flex justify-center w-full'>*/}
            {/*        <img*/}
            {/*            alt='shopify logo'*/}

            {/*            className='h-12 w-12 md:h-16 md:w-16'*/}
            {/*            src={ShopifyPNG} />*/}
            {/*    </div>*/}


            {/*</div>*/}

            {/*<DescriptionItem*/}
            {/*  tailwind='my-16'*/}
            {/*  title={`What others are saying makers, content creators, and companies of all shapes and sizes`}*/}
            {/*/>*/}

            {/*<div className='flex flex-col md:grid md:grid-cols-2 px-6 md:px-12 lg:px-16  gap-16'>*/}


            {/*    <Image*/}
            {/*      className='rounded-lg '*/}
            {/*      src={Testimonial1PNG} />*/}

            {/*    <Image*/}
            {/*      className='rounded-lg '*/}
            {/*      src={Testimonial2PNG} />*/}
            {/*</div>*/}

            <div>
                <CTA
                    title='Ready to give it a try?'
                    secondaryButtonText='Book A Demo'
                    onPrimaryButtonClicked={handleGetStarted}
                    onSecondaryButtonClicked={handleLiveDemoClicked}
                />
            </div>

            <Footer />

        </div>
    );
};

export default Landing;
