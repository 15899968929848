
import { useQuery } from '@apollo/react-hooks'
import {useState,useEffect} from 'react'
import { GET_SELECTED_CAMPAIGN } from '../constants/GqlQueries'

const useGetSelectedCampaign = () => {
    const [selectedCampaign,setSelectedCampaign] = useState({
        title: ""
    })

    const {data} = useQuery(GET_SELECTED_CAMPAIGN)

    useEffect(()=>{

        if(data && data.selectedCampaign){
            setSelectedCampaign(data.selectedCampaign)
        }
    },[data])

    return selectedCampaign

}

export default useGetSelectedCampaign