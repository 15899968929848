import React, { useEffect, useRef } from "react";

import "./App.css";
import Home from "components/signin/home";
import Dashboard from "app/dashboard";
import Landing from "app/landing";

import { Switch, Route, useHistory } from "react-router-dom";
import Contact from "components/contact";
import LogoPNG from "images/logo.png";
import PrivacyPolicy from "app/legal/privacy-policy";
import Tos from "app/legal/tos";
import Logo from "app/landing/components/logo";
import Footer from "app/landing/components/footer";
import Demo from "app/demo";
import Docs from "app/docs";
import PricingFaq from "app/pricingFaq";
import Instructions from "app/instructions";
import RefProgram from "app/ref-program/landing";
import ChoiceOfSetup from "app/onboarding/choice-of-setup";
import Affiliates from "app/affiliates";
import useVerifyShopify from "./app/hooks/useVerifyShopify";
import ShopifyCreateAccount from "app/shopify-create-account";
import Loading from "components/loading";
import CollectCC from "app/collect-cc";
import RedeemSaastraunatics from "app/redeem-saastraunatics";
import SaastronauticsCreateAccount from "app/saastronautics-create-account";

import useGetSubDomain from "./hooks/useGetSubDomain";

import Blog from "app/blog/blog";
import Blog2 from "app/blog/blogtest2";
import MainBlog from "app/blog/mainBlog";
import Article1 from "./app/blog/affiliate-link-management-software";
import Article2 from "./app/blog/eight-best-affiliate-link-management-software-in-twentytwentyone";
import useCheckCC from "./app/hooks/useCheckCC";

let image =
  "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80";

function App() {
  const { loading, manualLoggedIn } = useVerifyShopify();

  const history = useHistory();

  const { hasSubDomain } = useGetSubDomain();

  //useCheckCC();

  const saastraunaticsRef = useRef();

  useEffect(() => {
    const url = new URL(window.location.href);

    saastraunaticsRef.current = url.searchParams.get("saastronautics");
  }, []);

  const onRegister = (email) => {
    // window.location.href = 'https://steven4354.typeform.com/to/HiMneaaE'
    // verifyShopify()
    window.refMonkeyClient.user(email);
  };

  const onLogin = () => {
    // verifyShopify()
  };

  console.log("STEVENDEBUG deploy prod test x");

  return hasSubDomain ? (
    <Switch>
      <Route path="/">
        {" "}
        <Affiliates />{" "}
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route exact path={`/contact`}>
        <Contact
          getStartedText="Get Started"
          footer={<Footer />}
          logo={<Logo />}
          onGetStarted={() => history.push("/create-account")}
          onLogin={() => history.push("/login")}
        />
      </Route>

      <Route path="/how-it-works">
        <Instructions />
      </Route>
      <Route path="/docs">
        <Docs />
      </Route>
      <Route path="/pricing">
        <PricingFaq />
      </Route>
      <Route path="/api">
        <Landing />
      </Route>

      <Route path="/blog/test">
        <Blog />{" "}
      </Route>
      <Route path="/blog/test2">
        <Blog2 />{" "}
      </Route>
      <Route path="/blog/affiliate-link-management-software">
        <Article1 />{" "}
      </Route>
      <Route path="/blog/eight-best-affiliate-link-management-software-in-twentytwentyone">
        <Article2 />{" "}
      </Route>
      <Route path="/blog">
        <MainBlog />{" "}
      </Route>

      <Route path="/demo">
        <Demo />{" "}
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />{" "}
      </Route>
      <Route path="/tos">
        <Tos />{" "}
      </Route>
      <Route path="/ref-program">
        <RefProgram />{" "}
      </Route>
      <Route path="/onboard">
        {" "}
        <ChoiceOfSetup />
      </Route>
      <Route path="/shopify-create-account">
        {" "}
        <ShopifyCreateAccount />
      </Route>
      <Route path="/cc">
        <CollectCC />
      </Route>
      <Route path="/redeem-saastraunatics">
        {" "}
        <RedeemSaastraunatics />
      </Route>
      <Route path="/create-account-saastronautics">
        <SaastronauticsCreateAccount
          onRegister={onRegister}
          onLogin={onLogin}
          registerImage={image}
          logoIcon={LogoPNG}
          onRegisterPath={saastraunaticsRef.current ? "/" : "/cc"}
        />
      </Route>

      <Route path="/">
        {loading ? (
          <div className="w-full min-h-screen flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <Home
            trialDays={14}
            manualLoggedIn={manualLoggedIn}
            onRegisterPath="/"  //onRegisterPath={saastraunaticsRef.current ? "/" : "/cc"}
            onRegister={onRegister}
            onLogin={onLogin}
            loginImage={image}
            registerImage={image}
            logoIcon={LogoPNG}
            dashboardComponent={
              <Dashboard saastraunaticsRef={saastraunaticsRef} />
            }
            landingComponent={
              <Landing saastronautics={saastraunaticsRef.current} />
            }
          />
        )}
      </Route>
    </Switch>
  );
}

export default App;
