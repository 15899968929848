import React from 'react';
import PropTypes from 'prop-types';
import Option from '../../components/option';
import TextInput from 'components/textinput'
import Text from 'components/text'
import StripeLogo from '../../../../images/StripeLogo.jpg'
import {useState,useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useQuery,useMutation} from '@apollo/react-hooks'
import Loading from '../../../../components/loading'
import {CONNECT_STRIPE,GET_STRIPE_USERID,DISCONNECT_STRIPE} from '../../constants/GqlQueries'
import ConnectStripeOption from './ConnectStripe'
import ConnectSquareOption from './ConnectSquare'
const General = ({ states, setStates }) => {

    return (
        <div>
            <Option
                toggleValue={true}
                title='Share URL'
            >
                <TextInput
                    tailwind='w-full my-2 max-w-sm'
                    placeholder='mywebsite.com'
                    onChange={value => setStates.setDefaultUrl(value)}
                    value={states.defaultUrl} />

                <Text type='text-small'>This is the url that your affiliates will share. </Text>
            </Option>

{/*
            <Option
                hasToggle
                title='Reward after purchase/subscription'
                content='Determines whether advocates (affiliates) should only be given a reward after a referral purchases/subscribes to a product.'
                toggleValue={states.rewardAfterPurchase}
                onToggle={value => setStates.setRewardAfterPurchase(value)}
/>*/}

            <ConnectStripeOption states={states} setStates={setStates}/>
            <ConnectSquareOption state={states} setStates={setStates}/>
            {/**
 *

            <Option
                hasToggle
                title='Recurring reward'
                content='determines whether the reward is given only once or it is recurring'
                toggleValue={states.recurringReward}
                onToggle={value => setStates.setRecurringReward(value)}
            />
            */}

            {/**<div className='mt-6 mb-8'>
                <Text color
                    tailwind='font-medium text-gray-700'>Reward Advocate (affiliate) {states.recurringReward ? 'after every' : 'after'}
                    <TextInput
                        type='number'
                        tailwind='w-16 mx-2'
                        max={30}
                        min={1}
                        onChange={value => setStates.setReferralsRequired(value)}
                        value={states.referralsRequired} />
                              referrals</Text>
        </div> */}

            <div className='w-full h-px border-t border-gray-200' />
        </div>
    );
};

General.propTypes = {

};

export default General;
