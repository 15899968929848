import { gql } from 'apollo-boost'


export const GET_LOGO = gql`
     query getFile($business : String!){
         logo(business: $business){
             data
             contentType
         }
     }
`

export const UPLOAD_LOGO = gql`
     mutation uploadLogo($file: Upload!,$contentType: String!) {
         uploadLogo(file: $file,contentType: $contentType){
             success
             message
             filename
         }
     }
`


export const DELETE_LOGO = gql`
     mutation deleteLogo{
         deleteLogo{
             success
             message
         }
     }
`

export const GET_STRIPE_USERID=gql`
    query{
        getStripeUserID
    }
    `

export const CONNECT_STRIPE = gql`
    mutation($authCode:String!) {
        connectStripe(authCode: $authCode) {
            success
            message
            stripeUserID
        }
    }
    `

export const DISCONNECT_STRIPE = gql`
    mutation{
        disconnectStripe {
            success
            message
            stripeUserID
        }
    }
    `
 export const GET_SQUARE_INFO = gql`
    query {
        getSquareInfo {
            accessToken
            refreshToken
            expiresAt
            merchantId
        }
    }
 `

 export const CONNECT_SQUARE = gql`
    mutation($authCode:String!) {
        connectSquare(authCode:$authCode) {
            success
            message
            tokenInfo {
                accessToken
                refreshToken
                expiresAt
                merchantId
            }
        }
    }
 `

 export const DISCONNECT_SQUARE = gql`
    mutation($accessToken:String!) {
        disconnectSquare(accessToken:$accessToken) {
            success
            message
        }
    }
 `

export const SET_SETTINGS = gql`
   mutation($rewardAdvocatesStatus: Boolean,
    $rewardReferralsStatus:Boolean,
    $rewardReferralsName:String,
    $rewardReferralsValue:String,
    $rewardReferralsType:Int,
    $rewardAdvocatesName:String,
    $rewardAdvocatesType:Int,
    $rewardAdvocatesValue:String,
    $referralsRequired: Int!,
    $defaultUrl: String,
    $recurringReward: Boolean,
    $affDashboardUrl: String,
    $customDomain: String,
    $requestPayout: Boolean,
    $payoutMinDays: Int,
    $notifAffSignup: Boolean,
    $notifPayoutRequest: Boolean,
    $termsUrl: String,
    $enableTerms: Boolean,
    $enablePaypal : Boolean,
    $approveAdvocates : Boolean,
    $rewardAfterPurchase: Boolean!,
    $language: String){

    setSettings(settings: { 
                      rewardAdvocates:{ 
                             status: $rewardAdvocatesStatus,
                             name: $rewardAdvocatesName,
                             value: $rewardAdvocatesValue,
                      },
                     rewardReferrals: {
                            status: $rewardReferralsStatus,
                            name: $rewardReferralsName,
                            value: $rewardReferralsValue,
                    },
                    notifications:{
                        affSignup: $notifAffSignup,
                        payoutRequest: $notifPayoutRequest
                     },
                     approveAdvocates: $approveAdvocates,
                    rewardAdvocatesType: $rewardAdvocatesType,
                    rewardReferralsType: $rewardReferralsType,
                     referralsRequired: $referralsRequired,
                    rewardAfterPurchase: $rewardAfterPurchase,
                     defaultUrl: $defaultUrl,
                     affDashboardUrl: $affDashboardUrl,
                     customDomain: $customDomain,
                     recurringReward: $recurringReward,
                     requestPayout: $requestPayout,
                     enableTerms: $enableTerms,
                     termsUrl: $termsUrl,
                     enablePaypal: $enablePaypal,
                     payoutMinDays: $payoutMinDays,
                     language: $language
                        }){
        rewardAdvocates{
            status
            value
            name
     }
        rewardReferrals{
         status
         value
         name
     }
     notifications{
        affSignup
        payoutRequest
     }
     rewardAdvocatesType
     rewardReferralsType
        referralsRequired
        rewardAfterPurchase
        requestPayout
        defaultUrl
        affDashboardUrl
        customDomain
        recurringReward
        payoutMinDays
        enableTerms
        termsUrl
        enablePaypal
        approveAdvocates
        language
    }
   }`


export const GET_SETTINGS = gql`
query{
 settings{
     rewardAdvocates{
         status
         value
         name
         
     }
     rewardReferrals{
         status
         value
         name
         
     }
     notifications{
        affSignup
        payoutRequest
     }
     rewardAdvocatesType
     rewardReferralsType
     referralsRequired
     rewardAfterPurchase
     defaultUrl
     affDashboardUrl
     customDomain
     recurringReward
     requestPayout
     payoutMinDays
     enableTerms
     termsUrl
     enablePaypal
     approveAdvocates
     language
 }
}`



