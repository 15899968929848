import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'
import useGetAccessToken from 'app/setup/hooks/useGetAccessToken';

const DocRequest = props => {
    const { data } = useGetAccessToken()

    return (
        <Request
                    title='setup'
                    showScript={false}
                    description={<p>This is your tracking code. Place the code in the head of every page of your website - between the <b>{'<head>'}</b> and <b>{'</head>'}</b> HTML tags.</p>}
                    codeSnippet={`
                        <script src="https://cdn.refmonkey.com/refmonkey.js"></script>
                        <script type="text/javascript">  window.refMonkeyClient = RefMonkey.client(${data ? '"' + data.generateAccessToken + '"' : 'YOUR_ACCESS_TOKEN'});  </script>
                        `}
                    parameters={
                        <>
                            <ParamItem
                                required={true}
                                title='YOUR_ACCESS_TOKEN'
                                type='string'
                                content={`Your access token. This can be found on the RefMonkey Dashboard.`}
                            />
                        </>
                    }
                />
    );
};

DocRequest.propTypes = {
    
};

export default DocRequest;