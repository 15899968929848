import React from 'react';
import Text from 'components/text'
import Illustration8 from 'images/illustration8.png'

const Content = () => {
    return (
        <div>
            <Text type='heading-small'>Promote RefMonkey, Get Rewarded</Text>
            <img
            className='my-16 md:px-12 lg:px-16'
                src={Illustration8}
            />
            <Text
             tailwind='font-medium text-center w-full'>We offer 30% off on all the monthly premium plans for referring users to refmonkey.</Text>

        </div>
    );
};

export default Content;