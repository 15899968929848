import React, { useState } from 'react';
import { signout } from '../../../affiliate-login/utils/authUtil';
import { ReactComponent as WarningSVG } from 'images/warning.svg'
import { useLocation, useHistory } from 'react-router-dom';
import Text from 'components/text'
import CodeBox from 'components/code-box'
import Button from 'components/button'
import Card from 'components/card'
import Container from 'components/container'
import Icon from 'components/icon'
import GrayDiv from 'components/gray-div'
import { FormattedDate, FormattedMessage } from 'react-intl'


const DisabledAccount = (params) =>{
    const location = useLocation()
    const history = useHistory()

return(
    <GrayDiv tailwind='min-h-screen'>
    <div className='z-10 absolute w-full flex justify-end p-4'>
        <Button
            size='small'
            variant='outline'
            onClick={() => {
                signout()
                history.replace(`/auth`)
            }}
        >Sign out</Button>
    </div>

    <div className=' relative min-h-screen flex items-center justify-center flex-col'>
        <Icon
            size
            color
            tailwind='w-24 h-24 text-orange-400 mb-6 p-4'
            src={<WarningSVG />}
        />

        <Text >
           <FormattedMessage id="DisabledAccount"/>
        </Text>
    </div>
</GrayDiv>
)
}

export default DisabledAccount