import { useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { TRIAL_PERIOD } from '../constants/GqlQueries'


const useIsTrialPeriod = (onTrialPeriod,onExpiredTrialPeriod,onSubscribed) => {
    const [mutate, { data: trialPeriodData ,error}] = useLazyQuery(TRIAL_PERIOD,{
        onError: (e)=>console.log(e)
    })

    useEffect(() => {
        if (trialPeriodData) {
            const { trialPeriod } = trialPeriodData

            switch (trialPeriod) {
                case '0':
                    onExpiredTrialPeriod && onExpiredTrialPeriod()
                    break;
                case 'subscribed':
                    onSubscribed && onSubscribed()
                    break;
                
                default:
                    onTrialPeriod && onTrialPeriod(trialPeriod)
                    break;
            }
        }
    }, [trialPeriodData])

    const execute = (ownerEmail) => {
        mutate({ variables: { email: ownerEmail } })
    }

    return {execute}
}

export default useIsTrialPeriod;