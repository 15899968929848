import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button'
import { useMutation } from '@apollo/react-hooks'
import { SET_SELECTED_CAMPAIGN } from '../../constants/GqlQueries'
import { HTTP_URL } from '../../../../constants'
import { ReactComponent as CampaignSVG } from 'images/campaign.svg'
import Dialog from 'components/dialog'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from 'components/iconbutton'
import { ReactComponent as CopySVG } from 'images/copy.svg'
import Text from 'components/text'

function Item({ setShowLoading, onArchive, onEdit, isArchived, setShowError, isSelected, cid, logo, title }) {

    const [showArchiveConfirm, setShowArchiveConfirm] = useState(false)
    const [showCopied,setShowCopied] = useState(false)

    const [setSelectedMutate, { error, loading }] = useMutation(SET_SELECTED_CAMPAIGN, {
        update: () => {
            //refresh browser 
            window.location.href = HTTP_URL
        }
    })


    useEffect(()=>{
        if(showCopied){
            setTimeout(()=>setShowCopied(false),1800)
        }
    },[showCopied])

    useEffect(() => {
        setShowLoading(loading)
    }, [loading])

    useEffect(() => {
        setShowError(error)
    }, [error])

    const handleOnSelect = () => {
        setSelectedMutate({
            variables: {
                cid
            }
        })
    }

    const handleCopy = () => {

    }

    return (
        <>
            <Dialog
                onCloseComplete={() => setShowArchiveConfirm(false)}
                isShown={showArchiveConfirm}
                variant='simple'
                onConfirm={() => {
                    onArchive()
                    setShowArchiveConfirm(false)
                }}
                confirmText='Archive'
                title="Archive Campaign"
                text="Are you sure you want to archive this campaign? Affiliates will nolonger be able to access their dashboards. you can still restore it later."
            />

            <li className="cursor-pointer col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">


                <div
                    onClick={handleOnSelect}
                    className="flex-1 flex flex-col p-8 items-center">

                    {(logo.data && !isArchived) ?

                        <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={`data:${logo.contentType};base64, ${logo.data}`} alt="" />
                        :

                        <div className={`w-32 h-32 ${isArchived ? 'text-gray-300' : 'text-gray-400'}`}>
                            <CampaignSVG
                                className='w-full h-full'
                            />
                        </div>

                    }

                    <h3 className={`mt-6 ${isArchived ? 'text-gray-300' : 'text-gray-900'} text-sm font-medium`}>{title}</h3>
                    <dl className="mt-1 flex-grow flex flex-col justify-between">
                        <dt className="sr-only">Title</dt>
                        <dd class={`text-gray-500 text-sm mt-6  ${isArchived ? 'text-gray-300' : 'text-gray-400'}`}>
                            {cid ?
                                <div className="flex items-center">
                                    <span>
                                    Campaign ID: {cid}
                                    </span>
                                    <CopyToClipboard
                                        onCopy={() => setShowCopied(true)}
                                        text={cid}>

                                        <IconButton
                                            color
                                            onClick={()=>setShowCopied(true)}
                                            tailwind=' ml-2 text-gray-400 hover:text-gray-500 focus:text-gray-600'
                                            hasBackground={false}
                                            src={<CopySVG />}
                                            variant="text"
                                        />
                                    </CopyToClipboard>

                                </div> : <div></div>}


                        </dd>
                        {showCopied &&

<Text
color
tailwind='text-gray-300'
type='text-extra-small'>Copied</Text>
}
                        <dt className="sr-only">Role</dt>
                        <dd className="mt-3">
                            {isSelected &&
                                <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">Selected</span>
                            }
                        </dd>
                    </dl>
                </div>
                <div>
                    <div className="-mt-px flex divide-x divide-gray-200">

                        <div

                            className="-ml-px w-0 flex-1 flex">

                            {!isArchived &&
                                <div
                                    onClick={onEdit}
                                    className="hover:bg-gray-100 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                                    <Button
                                        color
                                        tailwind={`${isArchived ? 'text-gray-300' : 'text-gray-500'}`}
                                        size='small'
                                        variant='text'
                                    >Edit</Button>
                                </div>

                            }

                            <div
                                onClick={() => isArchived ? onArchive() : setShowArchiveConfirm(true)}
                                className="hover:bg-gray-100 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                                <Button
                                    color
                                    tailwind={`${isArchived ? 'text-gray-300' : 'text-gray-500'}`}
                                    size='small'
                                    variant='text'
                                >{isArchived ? 'Restore' : 'Archive'}</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </li>
        </>
    )
}

Item.propTypes = {

}

export default Item

