import React from 'react'


const monthlyPrice = 49
const yearlyPrice = 499
const freePlanPrice = 0 //added this too

const getPricingItems = (showAll = true) => {
    let pricingItems = []

    pricingItems.push({
        monthlyPrice: freePlanPrice,
        title: "Free",
        duration: "Free",
        features: [{
            content: 'Limited to 5 referrals'
        }, {
            content: 'API'
        }, {
            content: 'Paypal Mass Payouts'
        }, {
            content: 'Integrations'
        }]
    }, {
        monthlyPrice,
        title: "monthly",
        pricingText: "/mo",
        duration: "Monthly",
        features: [{
            content: 'unlimited referrals per month'
        }, {
            content: 'API'
        }, {
            content: 'Paypal Mass Payouts'
        }, {
            content: 'Integrations'
        }, {
            content: 'No hidden fees or commissions'
        }, {
            content: 'Priority Support'
        } ]
    }, {
        monthlyPrice: yearlyPrice,
        pricingText: "/yr",
        title: <p> Yearly <b>save $89</b></p>,
        duration: "Yearly",
        features: [{
            content: 'unlimited referrals per month'
        }, {
            content: 'API'
        }, {
            content: 'Paypal Mass Payouts'
        }, {
            content: 'Integrations'
        }, {
            content: 'No hidden fees or commissions'
        }, {
            content: 'Priority Support'
        }]
    })

    return pricingItems
}

export default getPricingItems
