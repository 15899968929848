import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'components/toggle'
import Text from 'components/text'
import Option from '../option';
import Button from 'components/button'
import TextInput from 'components/textinput'
import { BASE_URL, HTTP } from '../../../../constants';
import { ReactComponent as LinkSVG } from 'images/ext-link.svg'
import IconButton from 'components/iconbutton'


const RequestPayoutOption = ({ requestPayout, setRequestPayouts, payoutMinDays, setPayoutMinDays }) => {
    return (

        <Option
            hasToggle
            title='Affiliate payout requests'
            content='determines whether advocates (affiliates) should request for payouts'
            toggleValue={requestPayout}
            onToggle={value => setRequestPayouts(value)}
        >
            <Text
                color
                type='text-small'
                tailwind='my-2 text-gray-600'>Affiliates can only make a payout request
                    <TextInput
                    type='number'
                    tailwind='w-16 mx-2'
                    max={90}
                    min={0}
                    onChange={value => setPayoutMinDays(value)}
                    value={payoutMinDays ? payoutMinDays : 0} />
                              days after being rewarded.</Text>
        </Option>
    );
};

RequestPayoutOption.propTypes = {

};

export default RequestPayoutOption;