import React from 'react';
import ParamItem from 'components/api/param-item'
import Request from 'components/api/request'

const DocRequest = props => {
    return (
        <Request
                    id='redeem-advocate-reward'
                    title='Redeem Advocate reward'
                    description='redeem the reward of an advocate. '
                    method='post'
                    path='/reward/advocate'
                    tabs={[{
                      title: 'Axios',
                      script: false,
                      code:` 
        instance.post('https://api.refmonkey.com/reward/advocate', {
            identifier: '\${identifier}'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
             `,
                    },
                      {
                        title: 'curl',
                        script: false,
                        code: ` 
        curl -X POST --data "identifier=\${identifier}" https://api.refmonkey.com/reward/advocate --header "Authorization: YOUR_ACCESS_TOKEN"        
             `,
                      }]}
                    inputParameters={
                        <>
                            <ParamItem
                                required={true}
                                title='identifier'
                                type='string'
                                content={`A unique identifier for the user in your system. This can be an email address, username or id`}
                            />
                        </>
                    }

                    outputParameters={
                        <>
                            <ParamItem
                                title='success'
                                type='boolean'
                                content={`Indicates the outcome of the API call.`}
                            />
                        </>
                    }
                    sampleResponse={`{ "success": true }`}
                />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;
