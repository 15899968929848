import React, { useState, useEffect } from "react";
import Card from "components/card";
import Text from "components/text";
import { useQuery } from "@apollo/react-hooks";
import { GET_PENDING_REWARDS } from "./constants/GqlQueries";
import ErrorAlert from "components/alert/error";
import Loading from "components/loading";
import PendingRewardItem from "./components/item";
import emptyIllustrationPNG from "images/emptyIllustration.png";
import RewardHelp from "./components/rewardHelp";
import ShopifyRewardHelp from "./components/shopifyRewardHelp";
import SortText from "components/sort-text";
import Pagination from "components/pagination";
import Button from "components/button";
import styled from "styled-components";
import useIsShopify from "../hooks/useIsShopify";
import { GET_SETTINGS } from "app/settings/constants/GqlQueries";
import { useRouteMatch, useHistory, Route } from "react-router-dom";
import MassPay from "./components/mass-pay";

const Image = styled.img`
  opacity: 0.04;
`;

const CardWrapper = styled(Card)`
  min-width: 28rem;
`;

const SORT_BY_USER = 1;
const SORT_BY_DATE = 2;
const SORT_BY_REWARD = 3;

const PendingRewards = ({ setWithHeadingView }) => {
  const history = useHistory();

  const [showMassPay, setShowMassPay] = useState(true);

  const [pendingRewards, setPendingRewards] = useState([]);
  const [userAscending, setUserAscending] = useState(true);
  const [dateAscending, setDateAscending] = useState(true);
  const [rewardAscending, setRewardAscending] = useState(true);
  const [sortBy, setSortBy] = useState(SORT_BY_DATE);

  const { data, loading, error } = useQuery(GET_PENDING_REWARDS);

  console.log("STEVENDEBUG useQuery(GET_PENDING_REWARDS) data ", data);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const [showPagination, setShowPagination] = useState(false);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = pendingRewards.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { data: settingsData } = useQuery(GET_SETTINGS);

  history.listen((location) => {
    setShowMassPay(location.pathname === "/pending-rewards" ? true : false);
  });

  useEffect(() => {
    if (
      settingsData &&
      settingsData.settings &&
      showMassPay &&
      settingsData.settings.enablePaypal &&
      pendingRewards.length > 0
    ) {
      //
      setWithHeadingView(
        <Button
          tailwind="ml-2 md:mr-4 lg:mr-6"
          variant="outline"
          size="small"
          onClick={() => {
            history.push("/pending-rewards/mass-pay");
          }}
        >
          Paypal Mass Pay
        </Button>
      );
    } else {
      setWithHeadingView(null);
    }
  }, [settingsData, pendingRewards, showMassPay]);

  useEffect(() => {
    if (data) {
      const pendingRewardsItems = [];

      data.pendingRewards.map((value) => {
        if (
          !value.identifier.includes(
            process.env.REACT_APP_SHOPIFY_PURCHASE_PREPEND
          )
        ) {
          pendingRewardsItems.push(value);
        }
      });

      setPendingRewards(pendingRewardsItems);
      setShowPagination(data.pendingRewards.length / postsPerPage > 1);
    }
  }, [data]);

  const { usesShopify } = useIsShopify();

  function sortByUser() {
    setSortBy(SORT_BY_USER);

    const sortedPendingRewards = pendingRewards.sort((a, b) => {
      if (userAscending) {
        return a.identifier.localeCompare(b.identifier);
      } else {
        return b.identifier.localeCompare(a.identifier);
      }
    });
    setPendingRewards([...sortedPendingRewards]);
    setUserAscending(!userAscending);
  }

  function sortByDate() {
    setSortBy(SORT_BY_DATE);

    const sortedPendingRewards = pendingRewards.sort((a, b) => {
      if (dateAscending) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      } else {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    });
    setPendingRewards([...sortedPendingRewards]);
    setDateAscending(!dateAscending);
  }

  function sortByReward() {
    setSortBy(SORT_BY_REWARD);

    const sortedPendingRewards = pendingRewards.sort((a, b) => {
      if (rewardAscending) {
        return a.rewardValue.localeCompare(b.rewardValue);
      } else {
        return b.rewardValue.localeCompare(a.rewardValue);
      }
    });
    setPendingRewards([...sortedPendingRewards]);
    setRewardAscending(!rewardAscending);
  }

  return (
    <>
      <Route exact path={"/pending-rewards/mass-pay"}>
        <MassPay pendingRewards={pendingRewards} />
      </Route>

      <Route exact path={"/pending-rewards"}>
        <div className="h-full">
          {error && <ErrorAlert isShown={true} />}

         
          {data &&
            (pendingRewards.length > 0 ? (
              <CardWrapper tailwind="md:mx-4 lg:mx-6 mb-6">
                <div
                  className={`grid grid-cols-5 py-4 px-6
            border-b border-gray-200`}
                >
                  <SortText
                    isAscending={userAscending}
                    sort={sortBy === SORT_BY_USER}
                    // onClick={sortByUser}
                    tailwind="col-span-1"
                  >
                     User
                  </SortText>
                  <SortText
                    isAscending={userAscending}
                    sort={sortBy === SORT_BY_USER}
                    onClick={sortByUser}
                    tailwind="col-span-1"
                  >
                    Referred By
                  </SortText>
                  <SortText
                    isAscending={dateAscending}
                    sort={sortBy === SORT_BY_DATE}
                    onClick={sortByDate}
                  >
                    Rewarded on
                  </SortText>
                  <SortText
                    isAscending={rewardAscending}
                    sort={sortBy === SORT_BY_REWARD}
                    onClick={sortByReward}
                  >
                    Reward
                  </SortText>
                </div>

                {currentPosts.map((value, index) => {
                  return (
                    <PendingRewardItem isGray={index % 2 === 0} {...value} />
                  );
                })}

                {showPagination && (
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={pendingRewards.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                )}
              </CardWrapper>
            ) : (
              <div className="relative w-full h-full flex flex-col justify-center items-center">
                <div className="my-8 relative z-10">
                  <Text tailwind="w-full text-center font-medium text-lg my-6">
                    No pending rewards yet.
                  </Text>
                  {usesShopify ? <ShopifyRewardHelp /> : <RewardHelp />}
                </div>

                <Image
                  alt="No pending rewards yet."
                  className="z-0 absolute w-full h-full object-cover"
                  src={emptyIllustrationPNG}
                />
              </div>
            ))}
        </div>
      </Route>
    </>
  );
};

export default PendingRewards;
