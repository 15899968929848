import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import styled from 'styled-components'
import {FormattedDate} from 'react-intl'

const Wrapper = styled.div`
    background-color: ${({ theme, isGray }) => isGray ? theme.gray50 : 'white'};
`
const ReferralItem = ({ identifier, isGray, date, name }) => {

    const [processedIdentifier, setProcessedIdentifier] = useState()

    useEffect(() => {
        if (identifier.includes(process.env.REACT_APP_SHOPIFY_PURCHASE_PREPEND)) {
            setProcessedIdentifier("Referral Purchase")
        }
    }, [identifier])

    return (
        <div>


            <Wrapper
                isGray={isGray}
                className='grid grid-cols-5 py-4 px-6'>

                <Text tailwind='col-span-3 break-all'>{

                    processedIdentifier ? processedIdentifier :

                        (name ?
                            <div className='flex flex-col'>
                                <span>{name}</span>
                                <span className='text-gray-500 text-xs'> {identifier}</span>
                            </div>
                            :
                            identifier)}</Text>
                <Text
                tailwind='col-span-2'
                >{<FormattedDate value={date} day="2-digit" month="2-digit" year="numeric"/>}</Text>
            </Wrapper>


        </div>
    );
};

ReferralItem.propTypes = {

};

export default ReferralItem;