import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {
    const inputParams = <>
        <ParamItem
            title='options'
            type='object'
            content='object containing information about the conversion'
            childItems={
                <>
                <ParamItem
                        required={false}
                        title='cid'
                        type='string'
                        content={`The Campaign ID of the campaign this conversion belongs to. The default campaign is used if parameter is not provided`}
                    />

                    <ParamItem
                        required={false}
                        title='type'
                        type='string'
                        content={`Describes the type of conversion, i.e 'signup', 'purchase', 'form submission', etc.`}
                    />
                    <ParamItem
                        required={false}
                        title='customReward'
                        type='string'
                        content={`A custom reward to give the affiliate that referred the referral.`}
                    />

                    <ParamItem
                        required={false}
                        title='data'
                        type='object'
                        content={`Any additional data to attach to the conversion. Below are some typical items you can add, any custom keys/values you would like to add to the conversion can also be used as well`}
                        childItems={
                            <>
                                <ParamItem
                                    required={false}
                                    title='email'
                                    type='string'
                                    content={`The email address of the referral.`}
                                />
                                <ParamItem
                                    required={false}
                                    title='name'
                                    type='string'
                                    content={`The name of the referral.`}
                                />
                                <ParamItem
                                    required={false}
                                    title='phone'
                                    type='string'
                                    content={`The phone number of the referral.`}
                                />
                                <ParamItem
                                    required={false}
                                    title='username'
                                    type='string'
                                    content={`The username of the referral.`}
                                />
                                <ParamItem
                                    required={false}
                                    title='id'
                                    type='string'
                                    content={`A unique id of the referral in your system.`}
                                />

                                <ParamItem
                                    required={false}
                                    title='productName'
                                    type='string'
                                    content={`Name of the product that this conversion is for.`}
                                />

                                <ParamItem
                                    required={false}
                                    title='productDesc'
                                    type='string'
                                    content={`Description of the product that this conversion is for.`}
                                />
                            </>
                        }
                    />

                </>
            }
        />

    </>


    const outputParams = <>
        <ParamItem
            title='success'
            type='boolean'
            content={`Indicates the outcome of the API call.`}
        />
        <ParamItem
            title='conversion_id'
            type='string'
            content={`The ID associated with the conversion.`}
        />
    </>

    return (
        <Request
            id="conversion"
            title='Conversion'
            description='Create a conversion'
            tabs={[{
                title: 'async',
                script: false,
                inputParams,
                outputParams,
                code: ` 
                     await window.refMonkeyClient.conversion(options)
             `
            },


            {
                title: 'sync',
                script: true,
                code: ` 
                    window.refMonkeyClient.conversion(options, (response)=>{ 
                     //
                    })
             `,
                inputParams: <>

                    {inputParams}

                    <ParamItem
                        required={false}
                        title='callback'
                        type='function'
                        content={`Callback, is called after method completion.`}
                        childItems={outputParams}
                    />
                </>
            },
            ]}

        />
    );
};

DocRequest.propTypes = {

};

export default DocRequest;