import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {
    return (
        <Request
            id='get-referral-link'
            title='Get Referral link'
            description='Get the referral link of an advocate/affiliate'
            tabs={[{
                title: 'sync',
                script: true,
                inputParams:
                    <>
                        <ParamItem
                            required={true}
                            title='identifier'
                            type='string'
                            content={`The unique identifier for the affiliate/advocate previously created in the system. This can be an email address, username or id`}
                        />
                        <ParamItem
                            required={false}
                            title='callback'
                            type='function'
                            content={`Callback, to be called after completion.`}
                            childItems={
                                <>
                                    <ParamItem
                                        title='success'
                                        type='boolean'
                                        content={`Indicates the outcome of the method call.`}
                                    />
                                    <ParamItem
                                        title='url'
                                        type='string'
                                        content={`The referral link of an advocate`}
                                    />

                                </>
                            }
                        />
                    </>,
                code: `
        window.refMonkeyClient.getUrlFor(identifier, (success,url)=>{ 
            //
        })
`
            },
            {
                title: 'async',
                script: false,
                inputParams:
                    <>
                        <ParamItem
                            required={true}
                            title='identifier'
                            type='string'
                            content={`The username of the advocate, this can be an email, custom username or id`}
                        />
                    </>,
                outputParams:
                    <>
                        <ParamItem
                            title='success'
                            type='boolean'
                            content={`Indicates the outcome of the method call.`}
                        />
                        <ParamItem
                            title='url'
                            type='string'
                            content={`The referral link of an advocate`}
                        />

                    </>,
                code: `
        const response = await window.refMonkeyClient.getUrlFor(identifier)
`
            }]}

        />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;