import React from "react";
import Text from '../../../components/text';
import styled from "styled-components";

const AnimationWrapper = styled.div`
	svg {
		width: 100px;
		display: block;
		margin: 40px auto 0;
	}

	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;
		&.circle {
			-webkit-animation: dash 0.9s ease-in-out;
			animation: dash 0.9s ease-in-out;
		}
		&.line {
			stroke-dashoffset: 1000;
			-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
			animation: dash 0.9s 0.35s ease-in-out forwards;
		}
		&.check {
			stroke-dashoffset: -100;
			-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
			animation: dash-check 0.9s 0.35s ease-in-out forwards;
		}
	}

	p {
		text-align: center;
		margin: 20px 0 60px;
		font-size: 1.25em;
		&.success {
			color: #73af55;
		}
		&.error {
			color: #d06079;
		}
	}

	@-webkit-keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}

	@-webkit-keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}
		100% {
			stroke-dashoffset: 900;
		}
	}

	@keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}
		100% {
			stroke-dashoffset: 900;
		}
	}
`;

function UndoSuccess({closeAddReferralFormHandler}) {
	return (
		<div>
			<div className="flex w-full justify-center items-center">
            <Text type='heading' tailwind="flex w-full justify-center">
				The Reward Was Removed!
			</Text>
            </div>
            <AnimationWrapper>
            <svg
					version='1.1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 130.2 130.2'>
					<circle
						class='path circle'
						fill='none'
						stroke='#73AF55'
						stroke-width='6'
						stroke-miterlimit='10'
						cx='65.1'
						cy='65.1'
						r='62.1'
					/>
					<polyline
						class='path check'
						fill='none'
						stroke='#73AF55'
						stroke-width='6'
						stroke-linecap='round'
						stroke-miterlimit='10'
						points='100.2,40.2 51.5,88.8 29.8,67.5 '
					/>
				</svg>
                </AnimationWrapper>
			<div className='flex w-full justify-center items-center'>
				<button
					className='w-full mt-8 focus:outline-none text-white flex justify-center bg-red-700 py-3 rounded-lg items-center'
					onClick={closeAddReferralFormHandler}
					>
					Close
				</button>
			</div>
		</div>
	);
}

export default UndoSuccess;
