import {useState,useEffect} from 'react'

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }
  
  
  const getId = (index) =>{
return `${Date.now()}_${index}_${getRandomInt(1,9999999999999)}`
  }

const useGenerateTooltipId = (index) =>{
    const [id,setId] = useState(getId())

    useEffect(()=>{
        
        setId(getId())

    },[index])

    return id

}

export default useGenerateTooltipId