import React, { useState, useEffect } from "react"
import Text from 'components/text'
import styled from 'styled-components'
import Badge from "../../../components/badge";
import Modal from "../../../components/Modal/index";
import JsonPretty from "react-json-prettify";
import { atomOneLight } from "react-json-prettify/dist/themes";
import Button from 'components/button'
import TextInput from 'components/textinput'
import { useMutation } from "@apollo/react-hooks";
import { EDIT_REWARD_VALUE, TRANSACTIONS } from "../constants/GqlQueries";
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'
import RewardDetails from "../../affilate-dashboard/components/reward/reward-details";

const Wrapper = styled.div`
    background-color: ${({ theme, isGray }) => isGray ? theme.gray50 : 'white'};
`

const Referral = props => {
	const [modalOn, setModalOn] = useState(false);
	const [clickedOn, setClickedOn] = useState(null);
	const [editMode, setEditMode] = useState(false)
	const [showError, setShowError] = useState(false)
	const [rewardValue, setRewardValue] = useState(props.referral.rewardValue ? props.referral.rewardValue : "0")



	useEffect(() => {
		setRewardValue(props.referral.rewardValue ? props.referral.rewardValue : "0")
	}, [props.referral])

	const [editRewardValue, { loading: editLoading }] = useMutation(EDIT_REWARD_VALUE, {
		onError: () => setShowError(true),
		update: (cache, { data }) => {

			try {
				if (data.editTransactionRewardValue) {

					const { transactions } = cache.readQuery({ query: TRANSACTIONS })

					const newTransactions = transactions.map(value => {
						if ((value.identifier === props.referral.identifier) && (value.referredBy === props.referral.referredBy)) {
							value.rewardValue = rewardValue
						}
						return value
					})

					cache.writeQuery({
						query: TRANSACTIONS,
						data: {
							transactions: newTransactions
						}
					})

					setEditMode(false)
					props.setShowEditButton(true)
				} else {
					setShowError(true)
				}
			} catch (error) {
				console.log({ error })
				setShowError(true)
			}
		}
	})


	useEffect(() => {
		if (!modalOn) {
			setClickedOn(null);
		}
	}, [modalOn]);


	const handleCancelEditRewardValue = () => {
		setEditMode(false)
		props.setShowEditButton(true)
		setShowError(false)
		setRewardValue(props.referral.rewardValue ? props.referral.rewardValue : "0")
	}

	const handleSaveEditRewardValue = () => {
		setShowError(false)


		editRewardValue({
			variables: {
				identifier: props.referral.identifier,
				referredBy: props.referral.referredBy,
				rewardValue: rewardValue
			}
		})
	}

	return (
		<>
			{showError &&
				<div>
					<ErrorAlert
						isShown={true}
					/>
				</div>
			}


			<Modal
				width='70%'
				height='600px'
				setModalOn={setModalOn}
				on={modalOn}
				backdropZ={30}
				modalZ={40}>
				{
					clickedOn === 'stripe' ? (
						<div>
							{props.referral.paymentDetails && (
								<JsonPretty
									theme={{ ...atomOneLight, background: "white" }}
									json={{ paymentDetails: props.referral.paymentDetails }}
								/>
							)}
							{props.referral.subscriptionDetails && (
								<JsonPretty
									theme={{ ...atomOneLight, background: "white" }}
									json={{ subscriptionDetails: props.referral.subscriptionDetails }}
								/>
							)}
						</div>
					) : (
							<div>
								{props.referral.squarePaymentDetails && (
									<JsonPretty
										theme={{ ...atomOneLight, background: "white" }}
										json={{ squarePaymentDetails: props.referral.squarePaymentDetails }}
									/>
								)}
							</div>
						)
				}
			</Modal>

			<div
			>
				<Wrapper
					isGray={props.isGray}
					className=' flex py-4 px-6 '>

					<div className='w-full grid grid-cols-12 flex w-full justify-end'>
						<Text tailwind='col-span-1 break-all mr-2'>{props.referral.date}</Text>
						<Text tailwind='col-span-2 break-all mr-2'><div style={{ display: 'block' }}>{props.referral.identifier}</div></Text>
						<Text tailwind='col-span-2 break-all mr-2'>{props.referral.referredBy}</Text>

						{editMode ?
							<div className='flex items-center justify-center col-span-2'>
								<TextInput
									tailwind='w-full'
									value={rewardValue}
									onChange={value => setRewardValue(value)}
								/>
							</div>
							:
							<Text tailwind='text-center col-span-1  break-all'>{rewardValue}</Text>
						}

						{editMode ?
							<>
								{editLoading ?
									<div className='col-span-2'>
										<Loading />
									</div>


									:

									<div className='col-span-2 flex items-center justify-center'>
										<Button
											tailwind='mx-2'
											onClick={() => handleSaveEditRewardValue()}
											size='extra-small'
											variant='outline'>Save</Button>

										<Button
											size='extra-small'
											onClick={() => handleCancelEditRewardValue()}
											variant='outline'>cancel</Button>
									</div>

								}
							</>
							:
							<>

								<div className="text-center mr-2 col-span-2">
									{(props.referral.paymentDetails ||
										props.referral.subscriptionDetails) && (
											<div style={{ width: '72px', display: 'inline-block' }} onClick={() => {
												setClickedOn("stripe");
												setModalOn(true)
											}}>
												<Badge
													color
													variant='green'
													tailwind='inline-block text-center py-1 mt-1 mr-1 text-xs cursor-pointer'>
													Stripe
							</Badge>
											</div>
										)}{(props.referral.squarePaymentDetails) && (
											<div style={{ width: '72px', display: 'inline-block' }} onClick={() => {
												setClickedOn("square");
												setModalOn(true)
											}}>
												<Badge
													color
													variant='dark'
													tailwind='inline-block text-center py-1 mt-1 text-xs cursor-pointer'>
													Square
							</Badge>
											</div>
										)}
								</div>

								<Text tailwind="col-span-2 mr-2">{props.referral.status}</Text>

								<div classname='col-span-1 flex items-center justify-center'>
									{props.showEditButton &&
										<Button
											tailwind='w-full'
											onClick={() => {
												setEditMode(true)
												props.setShowEditButton(false)
											}}
											size='extra-small'
											variant='outline'>Edit</Button>
									}
								</div>

							</>
						}

					</div>

					<RewardDetails
						index={props.index}
						tag={props.referral.tag}
						productName={props.referral.productName}
						productDescription={props.referral.productDescription}
						revertMessage={props.referral.revertMessage}
					/>
				</Wrapper>
			</div>
		</>
	)
}

export default Referral;