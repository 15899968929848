import React, { useState } from 'react';
import RefMonkey from 'refmonkey'
import Loading from 'components/loading'
import Text from 'components/text'
import GrayDiv from 'components/gray-div'
import Item from './components/item';
import Alert from 'components/alert'
import { useHistory } from 'react-router-dom'
import Button from 'components/button'
import CodeBox from 'components/code-box'

const Demo = () => {
    const [showLoading, setShowLoading] = useState(false)
    const [response, setResponse] = useState('')
    const [newReferralIdentifier, setNewReferralIdentifier] = useState('')
    const [advocateIdentifier, setAdvocateIdentifier] = useState('')
    const [isReferralRewardedIdentifier, setIsReferralRewardedIdentifier] = useState('')
    const [isAdvocateRewardedIdentifier, setIsAdvocateRewardedIdentifier] = useState('')
    const [referralsListIdentifier, setReferralsListIdentifier] = useState('test@mail.com')

    const history = useHistory()

    const refMonkeyClient = RefMonkey.client('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3RAZ21haWwuY29tIiwiaWF0IjoxNTkzNjMwODkzfQ.wY64qxJvUeZL6I1E5p461q8Z9wBTv6tActnem-J08NM')

    const getItem = (placeholder, buttonText, onClick, helpText, textInputOnChange, textInputValue) => {
        return <Item
            textInputValue={textInputValue}
            textInputOnChange={textInputOnChange}
            setShowLoading={setShowLoading}
            buttonText={buttonText}
            placeholder={placeholder}
            onClick={onClick}
            helpText={helpText}
        />
    }

    return (
        <GrayDiv tailwind='min-h-screen p-6 pr-2'>

            <Alert
                content={`This is a Live Demo demonstrating how RefMonkey works under the hood`}
                tailwind='w-full my-8'
                variant='info'
                isShown={true}
            />

            {getItem('identifier i.e email/username',
                'Get shareable link',
                () => {
                    refMonkeyClient.getRefCode(advocateIdentifier, (success, refCode) => {
                        setShowLoading(false)
                        if (success) {
                            setResponse(`
                    identifier: ${advocateIdentifier}
                    referral url: mywebsite.com?ref=${refCode}
                    referral code: ${refCode}
                    `)
                        } else {
                            setResponse('Oops! something seems to have gone wrong.')
                        }
                    })
                },
                `get a user's shareable link which they can share with their friends.`,
                value => setAdvocateIdentifier(value),
                advocateIdentifier)}

            {getItem('identifier i.e email/username',
                'Add new Referral',
                async () => {
                    refMonkeyClient.user(newReferralIdentifier)
                    setShowLoading(false)
                    setResponse(` Referral created.`)
                },
                ``,
                value => setNewReferralIdentifier(value), newReferralIdentifier)}


            {getItem('referral identifier i.e email/username',
                'Is Refferal Rewarded?',
                async () => {
                    refMonkeyClient.isReferralRewarded(isReferralRewardedIdentifier, (status) => {
                        setShowLoading(false)

                        setResponse(`
                        identifier: ${isReferralRewardedIdentifier}
                        referral rewarded: ${status}
                        `)
                    })
                },
                `check whether a specific referral qualifies to be given a reward for signing up by being referred.`,
                value => setIsReferralRewardedIdentifier(value),
                isReferralRewardedIdentifier)}

            {getItem('advocate identifier i.e email/username',
                'Is Advocate Rewarded?',
                async () => {
                    refMonkeyClient.isAdvocateRewarded(isAdvocateRewardedIdentifier, (status) => {
                        setShowLoading(false)

                        setResponse(`
                             identifier: ${isAdvocateRewardedIdentifier}
                             advocate rewarded: ${status}
                             `)
                    })
                },
                `check whether a specific advocate qualifies to be given a reward for referring friends.`,
                value => setIsAdvocateRewardedIdentifier(value),
                isAdvocateRewardedIdentifier)}

            {getItem('identifier i.e email/username',
                'Get Referrals',
                async () => {
                    refMonkeyClient.getReferrals(referralsListIdentifier, (success, referrals) => {
                        setShowLoading(false)
                        if (success) {

                            setResponse(referrals.length === 0 ? 'no referrals' : JSON.stringify(referrals))
                        } else {
                            setResponse('Oops! something seems to have gone wrong.')
                        }
                    })

                },
                `get a list of referrals that a user has invited.`,
                value => setReferralsListIdentifier(value),
                referralsListIdentifier)}



            <Text>Response</Text>
            {showLoading ?
                <Loading />
                :
                <CodeBox>{response}</CodeBox>
            }


        </GrayDiv>
    );
};

export default Demo;