import React, { Component, useState } from 'react';
import { CSVLink } from "react-csv";

class AsyncCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvReport: {
        data: [],
        headers: [],
        filename: 'Report.csv'
      }
    }
  }
  
  headers = [
    { label: "Name", key: "name"},
    { label: "Email", key: "identifier" },
    { label: "Referrals", key: "referralCount" },
    { label: "Signed up", key: "date"},
  ];

  downloadReport = (event, done) => {
    // API call to get data
    const objReport = {
      filename: 'AffiliateContactInformation.csv',
      headers: [...this.headers],
      data: [...this.props.data]

    };
    this.setState({ csvReport: objReport }, () => {
      done();
    });
  }

  render() {
    return (
      <CSVLink
        className = "block mx-2 mt-3 text-blue-500 underline mb-8"
        {...this.state.csvReport}
        asyncOnClick={true}
        onClick={this.downloadReport}>
        Export affiliate contact to CSV file
      </CSVLink>
    );
  }
}

export default AsyncCSV;
