import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import Button from 'components/button'
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components'
import ErrorAlert from 'components/alert/error'
import { FormattedMessage, FormattedDate } from 'react-intl';
import RewardDetails from '../reward/reward-details';

const Wrapper = styled.div`
    background-color: ${({ theme, isGray }) => isGray ? theme.gray50 : 'white'};
`

const RewardItem = (props) => {
  
    const { status, date, tag,productName, productDescription, redeemDate, 
        isGray, payedAt, rewardTitle, referralIdentifier} = props

    let percent;
    let title;

    if (rewardTitle.includes("% of sale")) {
        percent = rewardTitle.split(" % ")[0];
        title = <FormattedMessage id="rewardTitlePercent" values={{ number: percent }} />
    } else if (rewardTitle.includes("token")) {
        percent = rewardTitle.split("token")[0].trim();
        title = (parseInt(percent) > 1
            ? <FormattedMessage id="rewardTitleTokens" values={{ number: percent }} />
            : <FormattedMessage id="rewardTitleToken" values={{ number: percent }} />)
    } else {
        title = rewardTitle;
    }

    return (
        <div
    
        >
            <Wrapper
                isGray={isGray}
                className={`flex py-4 px-6`}>

                <div className={` w-full grid grid-cols-${payedAt ? '5' : '4'}`}>

                    <Text tailwind=''>{status}</Text>
                    <Text tailwind='col-span-1 break-all'>{referralIdentifier}</Text>
                    <Text>{<FormattedDate value={date} day="2-digit" month="2-digit" year="numeric" />}</Text>
                    <Text>{title} </Text>

                    {payedAt &&
                        <Text>{redeemDate ? <FormattedDate value={redeemDate} day="2-digit" month="2-digit" year="numeric" /> : ''}</Text>
                    }
                </div>

           <RewardDetails
           tag={tag}
           index={props.index}
           productName={productName}
           productDescription={productDescription}
           revertMessage={props.revertMessage}
           />
            </Wrapper>


        </div>
    );
};

RewardItem.propTypes = {

};

export default RewardItem;
