import React from 'react';
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import ApiDocs from './components/api-docs'
import Button from 'components/button'
import Wix from './components/wix-docs'
import Ecwid from './components/ecwid-setup'
import Webflow from 'app/setup/components/webflow-setup'
import Wordpress from './components/wordpress-docs'
import DocButton from './components/doc-button'
import ZapierPNG from 'images/zapier.png'
import ShopifyPNG from 'images/shopify.png'
import WebflowPNG from 'images/webflow/logo.png'
import WordPressPNG from 'images/wordpress/logo.png'

import Text from 'components/text'
import JSDocs from './components/js-docs';
import GrayDiv from 'components/gray-div'
import Header from 'app/landing/components/header'
import Footer from 'app/landing/components/footer'
import ApiPNG from 'images/api-logo.png'
import WixPNG from 'images/wix.png'
import EcwidPNG from 'images/ecwid.png'

import { ReactComponent as JsSVG } from 'images/js-logo.svg'

const Docs = ({ showHeader = true }) => {
    const history = useHistory()
    const match = useRouteMatch()


    return (
        <div>
            <GrayDiv className='md:px-8 md:pb-32'>
                {showHeader &&
                    <Header />
                }

                <Route path={`${match.path}/api`}>
                    <ApiDocs
                        showHeader={showHeader} />
                </Route>

                <Route path={`${match.path}/js`}>
                    <JSDocs
                        showHeader={showHeader} />
                </Route>

                <Route path={`${match.path}/wix`}>
                    <Wix
                        showHeader={showHeader} />
                </Route>

                
                <Route path={`${match.path}/ecwid`}>
                    <Ecwid
                        showHeader={showHeader} />
                </Route>

                <Route path={`${match.path}/webflow`}>
                    <Webflow
                        showHeader={showHeader} />
                </Route>

                <Route path={`${match.path}/wordpress`}>
                    <Wordpress
                        showHeader={showHeader} />
                </Route>

                <Route exact path={`${match.path}`}>

                    <div className='md:grid md:grid-cols-2 mt-6 md:pt-8'>
                            <DocButton
                                image={
                                    <img src={ZapierPNG} />
                                }
                                iconTailwind='p-6'
                                color='bg-white'
                                title='Connect with Zapier'
                                content='For no-code integrations, use our Zapier app'
                                onClick={() => window.location.href = 'https://zapier.com/apps/refMonkey'}
                            />
                            
                            <DocButton
                                image={
                                    <img src={ShopifyPNG} />
                                }
                                iconTailwind='p-6'
                                color='bg-white'
                                title='Shopify'
                                content='Install RefMonkey app onto your shopify store'
                                onClick={() => window.location.href = 'https://apps.shopify.com/refmonkey-1'}
                            />

                        <DocButton
                            image={
                                <div className='flex pr-2 flex-col-reverse items-end w-full h-full'>
                                    <Text
                                        size
                                        tailwind='text-6xl'
                                        type='heading-large'>JS</Text>
                                </div>
                            }
                            iconTailwind=''
                            color='bg-yellow-400'
                            title='RefMonkey.js'
                            content='A complete reference to our javascript library'
                            onClick={() => history.push(`${match.path}/js`)}
                        />

                        <DocButton
                            iconTailwind='p-6'
                            image={
                                <img src={ApiPNG} />
                            }
                            title='REST API'
                            color='bg-gray-800'
                            content='A complete reference of the RefMonkey REST API'
                            onClick={() => history.push(`${match.path}/api`)}
                        />

                        <DocButton
                            iconTailwind='p-6'
                            image={
                                <img src={WixPNG} />
                            }
                            title='Wix'
                            color='bg-white'
                            content='A setup guide for integrating Refmonkey on Wix Sites'
                            onClick={() => history.push(`${match.path}/wix`)}
                        />

                        <DocButton
                            iconTailwind='p-6'
                            image={
                                <img src={EcwidPNG} />
                            }
                            title='Ecwid'
                            color='bg-white'
                            content='A setup guide for integrating Refmonkey on Ecwid'
                            onClick={() => history.push(`${match.path}/ecwid`)}
                        />

                        <DocButton
                            iconTailwind='p-6'
                            image={
                                <img src={WebflowPNG} />
                            }
                            title='Webflow'
                            color='bg-white'
                            content='A setup guide for integrating Refmonkey on Webflow'
                            onClick={() => history.push(`${match.path}/webflow`)}
                        />

                        <DocButton
                            iconTailwind='p-6'
                            image={
                                <img src={WordPressPNG} />
                            }
                            title='Wordpress'
                            color='bg-white'
                            content='A setup guide for integrating Refmonkey on WordPress'
                            onClick={() => history.push(`${match.path}/wordpress`)}
                        />

                    </div>
                </Route>


            </GrayDiv>
            <Footer />
        </div>

    );
};

export default Docs;