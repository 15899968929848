import React, { useState, useEffect } from 'react';
import { Switch, useHistory, Route } from 'react-router-dom';
import SidebarLayout from 'components/sidebar-layout'
import Logo from 'components/logo'
import LogoPNG from 'images/logo-bg.png'

import { getEmail } from 'utils/authUtil'
import { ReactComponent as CodeSVG } from 'images/code.svg'
import { ReactComponent as HomeSVG } from 'images/home.svg'
import { ReactComponent as PendingRewardSVG } from 'images/pending-reward.svg'
import { ReactComponent as ReferralsSVG } from 'images/referrals.svg'
import { ReactComponent as RewardSVG } from 'images/reward.svg'
import { ReactComponent as SupportSVG } from 'images/support.svg'
import { ReactComponent as CampaignsSVG } from 'images/collection.svg'

import { ReactComponent as IntegrationsSVG } from 'images/integrations.svg'
import { ReactComponent as TicketSVG } from 'images/ticket.svg'
import Banner from 'components/banner'
import QuestionBanner from "./components/banner"
import Badge from 'components/badge'
import { ReactComponent as SettingsSVG } from 'images/settings.svg'
import { ReactComponent as RoadmapSVG } from 'images/scheme1.svg'
import { ReactComponent as WikiSVG } from 'images/wiki.svg'
import getPricingItems from '../landing/pricingItems';

import PendingRewards from 'app/pending-rewards'
import RedeemedRewards from 'app/redeemed-rewards'
import Transactions from 'app/transactions/index'

import Settings from 'app/settings'
import Billing from 'app/billing'
import Integrations from 'app/integrations'

import Support from 'app/support'
import useLogout from '../../hooks/useLogout/index.js';
import SetUp from 'app/setup'
import RefProgram from '../ref-program/dashboard/index.js';
import Dialog from 'components/dialog'
import { REQUIRES_UPGRADE } from './constants/GqlQueries.js';
import { useQuery } from '@apollo/react-hooks';
import Pricing from 'components/pricing'
import Button from 'components/button'
import GrayDiv from 'components/gray-div'
import Text from 'components/text'
import DashboardHome from 'app/dashboard-home'
import useIsShopify from '../hooks/useIsShopify';
import useTrialPeriod from './hooks/useTrialPeriod'
import Campaigns from 'app/campaigns'
import useGetSelectedCampaign from 'app/campaigns/hooks/useGetSelectedCampaign'

const dashboard = {
    id: 'dashboard',
    text: 'Dashboard',
    icon: <HomeSVG />,
    //badgeText: '0'
}

const campaigns = {
    id: 'campaigns',
    text: 'Campaigns',
    icon: <CampaignsSVG />,
}

const transactions = {
    id: 'transactions',
    text: 'Transactions',
icon: <TicketSVG />,
    //badgeText: '0'
}
const pendingRewards = {
    id: 'pending-rewards',
    text: 'Pending Rewards',
    icon: <PendingRewardSVG />,
    //badgeText: '0'
}

const redeemedRewards = {
    id: 'redeemed-rewards',
    text: 'Redeemed Rewards',
    icon: <RewardSVG />,
    //badgeText: '0'
}

const setup = {
    id: 'setup',
    text: 'Installation',
    icon: <CodeSVG />
}

const settings = {
    id: 'settings',
    text: 'Settings',
    icon: <SettingsSVG />
}

const roadmap = {
    id: 'roadmap',
    text: 'Roadmap',
    icon: <RoadmapSVG />
}

const wiki = {
    id: 'wiki',
    text: 'Wiki',
    icon: <WikiSVG />
}

const integrations = {
    id: 'integrations',
    text: 'Integrations',
    icon: <IntegrationsSVG />
}

const navItems = [
    dashboard,
    campaigns,
    transactions,
    pendingRewards,
    redeemedRewards,
    setup,
    settings,
    roadmap,
    wiki
]

const shopifyNavItems = [
    dashboard,
    transactions,
    pendingRewards,
    redeemedRewards,
    integrations,
    settings,
    roadmap,
    wiki
]

const secondaryNavItems = [
    {
        id: 'billing',
        text: 'Billing',
        // icon: <BillingSVG />
    }, {
        id: 'support',
        text: 'Support',
        //  icon: <SupportSVG />
    },/* {
        id: 'ref-program',
        text: 'Our Referral Program',

    }*/]

const shopifySecondaryNavItems = [
    {
        id: 'billing',
        text: 'Billing',
    }, {
        id: 'support',
        text: 'Support',
    }]

const Dashboard = ({ saastraunaticsRef }) => {

    const history = useHistory()
    const logout = useLogout()

    const { usesShopify } = useIsShopify()

    const { handleBannerButtonClick, showPriceDialogDismmiss, showPriceDialog,
        showTrialBanner, trialDays, handleDialogClose, handleUpgrade } = useTrialPeriod()



    const [withHeadingView, setWithHeadingView] = useState()
    const [isQuestionBannerShow, setIsQuestionBannerShow] = useState(true)

    const selectedCampaign = useGetSelectedCampaign()

    useEffect(() => {
        const arr = navItems.map(item => "/" + item.id)
    }, [])

    useEffect(() => {

        const arr = navItems.map(item => "/" + item.id)

    }, [])

    useEffect(() => {
        if (saastraunaticsRef.current) {
            saastraunaticsRef.current = null
            history.push('/redeem-saastraunatics')
        }
    }, [saastraunaticsRef.current])

    const handleNavItemSelected = (id) => {
        if (id !== 'dashboard') {
            setWithHeadingView(null)
        }

        switch (id) {
            case 'dashboard':
                history.push('/' + window.location.search)
                break
            case 'campaigns':
                history.push('/campaigns' + window.location.search)
                break
            case 'transactions':
                history.push('/transactions' + window.location.search)
                break
            case 'redeemed-rewards':
                history.push('/redeemed-rewards' + window.location.search)
                break
            case 'pending-rewards':
                history.push('/pending-rewards' + window.location.search)
                break
            case 'setup':
                history.push('/setup' + window.location.search)
                break
            case 'settings':
                history.push('/settings' + window.location.search)
                break
            case 'roadmap':
                window.open(
                  "https://refmonkey.canny.io",
                  '_blank'
                )
                break
            case 'wiki':
                window.open(
                    'https://www.notion.so/Refmonkey-Wiki-dfaa6ddff3bd4feba51bd40cb086450d',
                    '_blank'
                )
                break
            case 'billing':
                history.push('/billing' + window.location.search)
                break
            case 'support':
                history.push('/support' + window.location.search)
                break
            case 'ref-program':
                history.push('/referral-program' + window.location.search)
                break
            case 'integrations':
                history.push('/integrations' + window.location.search)
                break
            default:
                break
        }
    }

    const handleMenuItemClicked = (index) => {
        switch (index) {
            case 0:
                logout()
                break
            default:
                break
        }

    }

    const handleQuestionBannerClicked = () => {
        setIsQuestionBannerShow(false)
    }

    return (
        <div>
            {isQuestionBannerShow ? <QuestionBanner click={handleQuestionBannerClicked} /> : null}
            <Switch>
                <SidebarLayout
                appBarSectionView={
                <div className='w-full h-full flex items-center justify-center md:pl-6  md:pr-56'>
                    <Badge>{selectedCampaign.title}</Badge>

                    {selectedCampaign.isArchived &&
                    <Text 
                    tailwind='mx-6'
                    type='heading-small'>Archived</Text>

                    }
                </div>}
                    contentPaddlingLeft={64}
                    withHeadingView={withHeadingView}
                    initialSelectedId={navItems.map(item => "/" + item.id).includes(window.location.pathname) ? window.location.pathname.replace("/", "") : 'dashboard'}
                    showSearch={false}
                    logo={<Logo
                        clickable={false}
                        icon={LogoPNG}
                        color='text-white'
                        text='RefMonkey' />}
                    onNavItemSelected={handleNavItemSelected}
                    onMenuItemClicked={handleMenuItemClicked}
                    navItems={usesShopify ? shopifyNavItems : navItems}
                    secondaryNavItems={usesShopify ? shopifySecondaryNavItems : secondaryNavItems}
                    secondaryNavTitle='Account'
                    menuItems={[{ id: 'logout', text: 'Logout' }]}
                    profile={{ email: getEmail() }}
                >
                    <Route path='/referral-program'><RefProgram /></Route>
                    <Route path='/transactions'><Transactions /></Route>
                    <Route path='/setup'><SetUp /></Route>
                    <Route path='/campaigns'><Campaigns /></Route>
                    <Route path='/settings'><Settings /></Route>
                    <Route path='/support'><Support /> </Route>
                    <Route path='/billing'><Billing /></Route>
                    <Route path='/integrations'><Integrations /></Route>
                    <Route path='/pending-rewards'>
                        <PendingRewards
                            setWithHeadingView={setWithHeadingView} />
                    </Route>
                    <Route path='/redeemed-rewards'><RedeemedRewards /></Route>

                    <Route exact path='/'>
                        <DashboardHome
                            setWithHeadingView={setWithHeadingView} />
                    </Route>


                </SidebarLayout>

            </Switch>


            <Dialog
                fullWidth
                fullHeight
                showDismiss={showPriceDialogDismmiss}
                id='price-dialog'
                itemsCenter
                isShown={showPriceDialog}
                shouldCloseOnOverlayClick={false}
                onCloseComplete={handleDialogClose}
                variant='normal'>
                <GrayDiv>

                    {!showPriceDialogDismmiss &&
                        <Button
                            onClick={() => logout()}
                            tailwind='fixed top-0 right-0 m-8 z-10'
                            size='extra-small'
                            variant='secondary'>Logout</Button>
                    }
                    <div className='w-full z-0'>
                        <Pricing
                            centerPrice
                            showAnnual={false}
                            narrow
                            title={showPriceDialogDismmiss ? '' : 'Your free trial has expired, upgrade now'}
                            wrapperVariant='compact'
                            rounded={false}
                            items={getPricingItems(false)}
                            submitText={'Upgrade'}
                            onSelect={handleUpgrade}
                        />
                    </div>
                </GrayDiv>
            </Dialog>
            {/* <Banner
                narrow
                id='trial-banner'
                isShown={showTrialBanner}
                buttonText='Upgrade'
                text={`${trialDays} trial days left, upgrade now`}
                onButtonClick={handleBannerButtonClick}
            /> */}
        </div>
    );
};

export default Dashboard;
