import React from 'react';

import ParamItem from 'components/api/param-item'
import DescList from 'components/description-list'

const DocRequest = props => {
    return (
        <DescList
                    horizontal
                    mobileFlat={false}
                    heading={'Response Codes'}
                    subHeading={`The RefMonkey API uses standard HTTP status codes to indicate success or failure of API calls.`}
                >
                    <ParamItem
                        title='200'
                        content='API call was successful.'
                    />
                    <ParamItem
                        title='400'
                        content={`You've made an error in your request. See the message field in the response for details.`}
                    /><ParamItem
                        title='401'
                        content='Authentication credentials provided were incorrect. (i.e invalid access token)'
                    /><ParamItem
                        title='404'
                        content='Unknown API method.'
                    /><ParamItem
                        title='500'
                        content='A temporary internal server error.'
                    />
                </DescList>
    );
};

DocRequest.propTypes = {
    
};

export default DocRequest;