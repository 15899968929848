import React from 'react'
import PropTypes from 'prop-types';
import Header from 'app/landing/components/header'
import Content from 'app/setup/components/content'
import { useHistory } from 'react-router-dom'
import Footer from 'app/landing/components/footer'
import SplitItem from 'components/landing/split-item'
import SplitDescItem from 'components/landing/split-item/desc-item'
import Illusation5 from 'images/illustration5.png'
import Illusation6 from 'images/illustration6.png'
import Illusation7 from 'images/illustration7.png'
import Text from 'components/text'
import CTA from 'components/cta/simple-centered'
import GrayDiv from 'components/gray-div'

const Instructions = () => {
    const history = useHistory()

    return (
        <GrayDiv tailwind='min-h-screen'>
            <Header />
            <Content />

            <SplitItem

                tailwind=''>

                <img
                    className=''
                    src={Illusation6} />

                <div>
                    <Text
                        color
                        type='heading-extra-large'
                        tailwind='font-medium text-gray-900 text-lg' >Easy to set up</Text>
                    <Text
                        color
                        type='heading-extra-large'
                        tailwind='font-medium text-gray-900 text-lg' >Easy to use</Text>
                    <Text
                        textSize
                        tailwind={`text-sm md:text-base`}>Easily integrate RefMonkey in minutes using one of our modules, guides or just install manually.paste RefMonkey's snippet on every page, add two lines of code and you're done!</Text>
                </div>

            </SplitItem>

            <SplitItem
                alt
                tailwind='mt-12'>

                <SplitDescItem
                    title={`Recruit Advocates`}
                    content={`Invite your fans, influencers, existing customers, strategic partners and affiliates to join your referral program.`} />
            
            
            <img
                    className=''
                    src={Illusation7} />
            </SplitItem>

            <SplitItem

                tailwind='mt-12'>

                <img
                    className=''
                    src={Illusation5} />

                <SplitDescItem

                    title={`Get promoted`}
                    content={`You get new customers from your advocates and show a token of appreciation by Rewarding them with commission on the new revenue they generate.`} />
            </SplitItem>

            <div
                className='mt-12 mb-24'
            >
                <CTA
                content='Get started today'

                    showSecondaryButton={false}
                    onPrimaryButtonClicked={() => history.push('/create-account')}
                />

            </div>

            <Footer />

        </GrayDiv>
    )
}

Instructions.defaultProps = {

}

Instructions.propTypes = {

};

export default Instructions;