import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Form from "../../../components/form";
import Text from "../../../components/text";
import FieldInput from "../../../components/fieldinput";
import Checkbox from "../../../components/checkbox";
import Loading from "../../../components/loading";
import ErrorAlert from "../../../components/alert/error";
import DropDown from "../../../components/dropdown";
import DropDownItem from "../../../components/dropdown/dropdownitem";
import Container from "../../../components/container";

function AddReferralForm({
	referralWarningObject,
	settingsData,
	addReferralStates,
	addReferralSetStates,
	setReferralInfo,
	addReferralHandler,
	advocates,
	advocatesLoading,
	advocatesError,
	setReferralWarningObject,
	showAddReferralError,
	formFieldRef,
}) {
	const {
		identifier,
		referredBy,
		customAdvocateReward,
		showCustomReward,
		referralName,
	} = addReferralStates;
	const {
		setIdentifier,
		setReferredBy,
		setCustomAdvocateReward,
		setShowCustomReward,
		setReferralName,
	} = addReferralSetStates;

	const [advocateNames, setAdvocateNames] = useState([]);
	const [filteredAdvocates, setFilteredAdvocates] = useState([]);
	const [focused, setFocused] = useState(false);
	const [onTopOfFilter, setOnTopOfFilter] = useState(false);

	useEffect(() => {
		if (advocates.advocates) {
			const advocateIdArr = advocates.advocates.map(
				(advocate) => advocate.identifier
			);
			setAdvocateNames(advocateIdArr);
		}
	}, [advocates]);

	useEffect(() => {
		const filteredValues = [];
		if (referredBy.trim() === "") {
			setFilteredAdvocates([]);
		}
		if (referredBy.trim() !== "") {
			if (advocateNames) {
				advocateNames.forEach((name) => {
					if (name.includes(referredBy)) {
						filteredValues.push(name);
					}
				});
				setFilteredAdvocates(filteredValues);
			}
		}
	}, [referredBy]);

	return (
		<>
			{advocatesLoading ? (
				<div className='flex items-center justify-center h-28'>
					<Loading />
				</div>
			) : advocatesError ? (
				<ErrorAlert isShown={advocatesError} variant='error' />
			) : (
				<div className=''>
					<div className='py-10 px-3'>
						<Form onSubmit={addReferralHandler}>
							<div className='mb-3'>
								<ErrorAlert isShown={showAddReferralError} variant='error' />
							</div>

							<div className='mb-5' ref={formFieldRef}>
								<FieldInput
									label='Referral ID'
									helpText={referralWarningObject.identifier}
									type='text'
									placeholder='Enter the referral email, phone or username'
									value={identifier}
									error={referralWarningObject.identifier}
									onChange={setIdentifier}
									inputTailWind='pl-3 pr-3'
									onFocus={() => {
										setReferralWarningObject({
											...referralWarningObject,
											identifier: "",
										});
									}}
								/>
							</div>

							<div className='mb-5'>
								<FieldInput
									label='Referral Name (Optional)'
									helpText={referralWarningObject.referralName}
									type='text'
									placeholder='Enter the referral Name'
									value={referralName}
									error={referralWarningObject.referralName}
									onChange={setReferralName}
									inputTailWind='pl-3 pr-3'
									onFocus={() => {
										setReferralWarningObject({
											...referralWarningObject,
											referralName: "",
										});
									}}
								/>
							</div>

							<div className='mb-5'>
								<FieldInput
									onFocus={() => {
										setFocused(true);
										setReferralWarningObject({
											...referralWarningObject,
											referredBy: "",
										});
									}}
									onBlur={() => setFocused(false)}
									label='Referred By'
									type='text'
									placeholder={`Enter the Affiliate's email address`}
									helpText={referralWarningObject.referredBy}
									error={referralWarningObject.referredBy}
									value={referredBy}
									onChange={setReferredBy}
								/>

								{filteredAdvocates && (focused || onTopOfFilter) && (
									<div
										className={`absolute t-0 mt-1 max-h-64 w-3/5 md:w-2/3 sm:w-2/3 bg-white border border-t-0 rounded overflow-scroll`}>
										<div
											className='h-full min-w-32'
											onMouseEnter={() => setOnTopOfFilter(true)}
											onMouseLeave={() => setOnTopOfFilter(false)}>
											{filteredAdvocates.map((name) => (
												<div
													className='px-3 py-2 cursor-pointer hover:bg-gray-200'
													onClick={() => {
														setReferredBy(name);
														setOnTopOfFilter(false);
													}}>
													{name}
												</div>
											))}
										</div>
									</div>
								)}
							</div>

							{showCustomReward && (
								<div className='mb-5'>
									<FieldInput
										label='Custom Reward'
										type='text'
										placeholder='e.g. 10.3 USD, 200 credit'
										value={customAdvocateReward}
										helpText={referralWarningObject.customAdvocateReward}
										error={referralWarningObject.customAdvocateReward}
										onChange={setCustomAdvocateReward}
										inputTailWind='pl-3 pr-3 mb-1'
										onFocus={() => {
											setReferralWarningObject({
												...referralWarningObject,
												customAdvocateReward: "",
											});
										}}
									/>
								</div>
							)}

							<Checkbox
								label='Provide Custom Reward?'
								value={showCustomReward}
								checked={showCustomReward}
								onToggle={setShowCustomReward}
							/>
						</Form>
					</div>
				</div>
			)}
		</>
	);
}

export default AddReferralForm;
