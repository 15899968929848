import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_BILLING, UNSUBSCRIBE } from './constants/GqlQueries'
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'
import Card from 'components/card'
import getPricingItems from '../landing/pricingItems';
import Text from 'components/text'
import Button from 'components/button'
import Dialog from 'components/dialog'
import Pricing from 'components/pricing'
import useUpgradeBilling from '../hooks/useUpgradeBilling';

const Billing = () => {
    const [showCancelSubDialog, setShowCancelSubDialog] = useState(false)
    const [showError, setShowError] = useState(false)
    const { data, loading, error } = useQuery(GET_BILLING)

    const { upgrade, loading: upgradeLoading } = useUpgradeBilling()



    const [unsubscribe, { data: unsubscribeData, error: unsubscribeError }] = useMutation(UNSUBSCRIBE, {
        onError: () => { }
    })


    useEffect(() => {
        if (error || unsubscribeError) {
            setShowError(true)
            setShowCancelSubDialog(false)
        } else {
            setShowError(false)
        }
    }, [error, unsubscribeError])



    useEffect(() => {
        if (unsubscribeData) {
            if (unsubscribeData.unsubscribe) {
                window.location.reload(true);
            } else {
                setShowError(true)
                setShowCancelSubDialog(false)

            }
        }
    }, [unsubscribeData])

    return (
        <div className='w-full h-full'>
            <ErrorAlert
                isShown={showError}
            />

            {(loading || upgradeLoading) &&
                <Loading />
            }

            {data && !upgradeLoading &&

                /**manually upgraded */

                (data.billing.manualBillingUpgrade ?
                    <>
                        <Text tailwind='my-4'>To cancel your subscription, send an email to help@refmonkey.com.</Text>


                        <Card
                            tailwind=' flex  p-8 mt-4 items-center'>
                            <div className='flex  items-center'>
                                <Text>{`${data.billing.pricePlan}    ${data.billing.price}`}</Text>
                            </div>

                        </Card>
                    </>
                    :

                    /**upgraded */

                    (data.billing.price ?
                        <>
                            <Card
                                id='cancel-sub-content'
                                tailwind=' flex justify-between p-8 mt-4 items-center'>
                                <div className='flex  items-center'>
                                    <Text> {data.billing.price} . Renews {data.billing.renews}</Text>
                                </div>
                                <div className='flex items-center'>
                                    <Button
                                        id='unsub'
                                        size='extra-small'
                                        onClick={() => setShowCancelSubDialog(true)}
                                    >Cancel</Button>
                                </div>
                            </Card>

                            <Dialog
                                id='unsub-dialog'
                                title='Cancel your subscription'
                                text={`Are you sure you want to cancel your subscription? you will never get
                charged from refmonkey again and wont be able to use our services.`}
                                variant='simple-gray'
                                confirmText='delete'
                                onConfirm={() => unsubscribe()}
                                onCloseComplete={() => setShowCancelSubDialog(false)}
                                isShown={showCancelSubDialog} />
                        </>

                        :

                        /**not upgraded */

                        < div >
                            <Text className=''>For billing help or customer support, please send an email to help@refmonkey.com.</Text>
                            {/* <Text className=''>You can also choose to upgrade immediately below.</Text> */}

                            {/* <div
                                className='-mt-16'
                            >

                                <Pricing
                                    bg
                                    tailwind
                                    showAnnual={false}
                                    wrapperVariant='alt'
                                    items={getPricingItems(false)}
                                    onSelect={upgrade}
                                    submitText="Upgrade"
                                />
                            </div> */}
                        </div>)


                )
            }

        </div >
    );
};

export default Billing;
