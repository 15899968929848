import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from '../../../setup/components/count-item'
import CodeBox from 'components/code-box'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import Text from 'components/text'
import { HashLink } from 'react-router-hash-link';
import useGetAccessToken from '../../../setup/hooks/useGetAccessToken';
import headerApp from 'images/wordpress/headerapp.png'
import headerTag from 'images/wordpress/headertag.png'

import { URL, ADD_URL } from './constants/GqlQueries'
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { getUser } from 'utils/authUtil'
import Card from 'components/card'
import InsertUrls from './components/insert-urls';





const Wordpress = ({ mobileView }) => {
  const match = useRouteMatch()
  const history = useHistory()
  const token = useGetAccessToken()




  // useEffect(() => {

  // },[data.getWordpressUrl.length])

  return (
    <div className='w-full'>
      <Heading>Integrate with WordPress</Heading>


      <CountItem
        number='1'>Install the Head, Footer and Post Injections plugin:
        <Link className='text-blue-500 hover:text-blue-400'
          to='https://wordpress.org/plugins/header-footer'> https://wordpress.org/plugins/header-footer.</Link>
      </CountItem>

      <div className=''>
        <img style={{ width: '510px' }} className='m-auto' src={headerApp} alt='form id location' />
      </div>

      <CountItem
        number='2'>Once you activate the plugin, you can access its interface by going to Settings → Header and Footer in your WordPress dashboard. You’ll see a number of tabs in the plugin’s interface. But for this guide, you’ll mostly work in the default Head tab:
      </CountItem>

      <div className=''>
        <img style={{ width: '510px' }} className='m-auto' src={headerTag} alt='form id location' />
      </div>

      <Text className="my-4">To add code to your site’s header, you need to paste it in one of the boxes under the <b>{'<HEAD>'} SECTION INJECTION</b> area.</Text>

      <CountItem
        number='3'>
        Copy the following code to your HEADER
      </CountItem>

      <CodeBox>{`
        <script src="https://cdn.refmonkey.com/refmonkey.js"></script>
        <script type="text/javascript">
          window.refMonkeyClient = RefMonkey.client(${token.data ? '"' + token.data.generateAccessToken + '"' : 'YOUR_ACCESS_TOKEN'})
        
          window.refMonkeyClient.autoWatch();
        </script>`}
      </CodeBox>

      <Text className="my-4">Note: In order for this to work, referrals must provide their email address somewhere in the form.</Text>

      <CountItem
        number='4'>
        <InsertUrls />
      </CountItem>

      <Text className="my-4 mt-12">That's it for the easy set up! </Text>

      <div className='mt-16'>
        <Content ><b>Important:</b> This concludes our setup instructions for our most basic form of integration.</Content>
      </div>

      <Content>For all additional tracking posibilities, like referral links and more…
                 please check our<Link
          // onClick={onDocs}
          className='text-blue-500 hover:text-blue-400'
          to={`${match.url}/docs`}> complete refmonkey.js reference.</Link></Content>

    </div>
  );
}
Wordpress.propTypes = {

};

export default Wordpress;