import React from 'react';
import Text from 'components/text'
import { HTTP_URL } from 'constants/index.js'
import { HashLink as Link } from 'react-router-hash-link';

const RewardHelp = () => {

    const textType = 'text-small'

    const textTailwind = 'text-center'

    return (
        <div className='md:mx-16 flex flex-col items-center justify-center'>
           
            <Text
                type={textType}
                color
                tailwind={`${textTailwind} mt-12 text-gray-800 font-medium`}>Status of a Reward</Text>
            <Text
                type={textType}
                tailwind={`${textTailwind}`}
            >There are two states for a reward:</Text>

            <ul className='mx-0 my-4'>
                <li><Text
                    tailwind={`${textTailwind}`}
                    type={textType}
                >Pending</Text></li>
                <li
                ><Text
                    tailwind={`${textTailwind}`}
                    type={textType}
                >Redeemed</Text></li>
            </ul>

            <Text
                tailwind={`${textTailwind}`}
                type={textType}
            >The reward is in pending state by default meaning that it's waiting to be payed out by you. For example; 5 USD to be payed out to an affiliate</Text>

            <Text
                tailwind={`${textTailwind}`}
                className='my-4'
                type={textType}
            >After paying out an affiliate, you mark it as paid through this dashboard.</Text>
        </div>
    );
};

export default RewardHelp;
