import React,{useState,useEffect} from 'react'
import {IntlProvider} from 'react-intl'
import Spanish from './es.json'
import English from './en.json'
import Hebrew from './he.json'
import French from './fr.json'
import Japanese from './ja.json'

export const Context = React.createContext();

const LanguageWrapper = (props) => {
    
    const [local,setLocal] = useState("en-US");
    const [lang,setLang] = useState(English);
   
    const changeLanguage = (selectedLanguage) => {
        setLocal(selectedLanguage);
        if(selectedLanguage === 'es-MX') {
            setLang(Spanish);
            
        }else if(selectedLanguage === 'ja-JP') {
            setLang(Japanese);
        }else if(selectedLanguage === 'fr'){
            setLang(French);
        }else if(selectedLanguage === 'he') {
            setLang(Hebrew);
        } 
        else {
            setLang(English);
        }
        setLocal(selectedLanguage)
    
    }
    return (
        <Context.Provider value={{local,changeLanguage}}>
        <IntlProvider locale={local} messages={lang}>
            {props.children}
        </IntlProvider>
        </Context.Provider>
    )
}

export default LanguageWrapper
