import { gql } from 'apollo-boost';

export const ADD_URL = gql`
  mutation($url: String!){
		  addWordpressUrl(url:$url)
	}
`

export const DELETE_URL = gql`
	mutation($url: String!){
		  deleteWordpressUrl(url:$url)
	}
`

export const URL = gql`
  query{
    getWordpressUrl
  }
`;