
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

const useSetStatus = ({ data, settingsRequestPayout, setShowRequestPayout,
    setAdvocatePendingRewards, setShowPagination, waitingPurchase, postsPerPage }) => {

    useEffect(() => {
        if (data) {

            //determine whether to show the request button or not
            let showRequestButton = false

            data.advocatePendingRewards.map(({ purchased, payoutRequested, daysLeftToRequest, tag }) => {
                let payoutRequestedCondition = waitingPurchase ? (purchased && !payoutRequested) : !payoutRequested

                if (tag) {
                    payoutRequestedCondition = !payoutRequested
                }

                console.log({settingsRequestPayout})

                if (settingsRequestPayout && payoutRequestedCondition && (!daysLeftToRequest || daysLeftToRequest <= 0)) {
                    showRequestButton = true
                }
            })

            setShowRequestPayout(showRequestButton)


            data.advocatePendingRewards.map((value) => {

                if (value.rewardValue === 'refmonkey_custom_reward_value') {
                    value.rewardTitle = value.rewardName
                } else {
                    value.rewardTitle = `${value.rewardValue} ${value.rewardName}`
                }

                value.status = waitingPurchase && !value.purchased && !value.tag ?
                    <FormattedMessage id="aff.rewards.waitPurchase" />
                    :
                    (!settingsRequestPayout ?
                        <FormattedMessage id="pending" />
                        :
                        (value.daysLeftToRequest && value.daysLeftToRequest > 0 ?
                            // `wait ${value.daysLeftToRequest} more days`
                            <FormattedMessage id="waitDays" values={{ day: value.daysLeftToRequest }} />
                            :
                            (value.payoutRequested ?
                                <FormattedMessage id="pending" />
                                :
                                <FormattedMessage id="ready" />)
                        )
                    )
            })

            setAdvocatePendingRewards(data.advocatePendingRewards)
            setShowPagination(data.advocatePendingRewards.length / postsPerPage > 1)
        }
    }, [data, settingsRequestPayout])

}

export default useSetStatus