import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import Button from 'components/button'
import styled from 'styled-components'
import Card from 'components/card'
import RewardItem from '../reward-item';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PENDING_REWARDS, REQUEST_PAYOUT } from '../../constants/GqlQueries';
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'
import SortText from 'components/sort-text'
import Pagination from 'components/pagination'
import { FormattedDate, FormattedMessage } from 'react-intl';
import useSetStatus from './useSetStatus';

const CardWrapper = styled(Card)`
    min-width: 28rem;
    
`
const SORT_BY_VALUE = 1
const SORT_BY_CREATED_DATE = 2
const SORT_BY_STATUS = 3

const Reward = ({ refCode, referralDashboardCode, settingsData }) => {
    const [showRequestPayout, setShowRequestPayout] = useState(false)
    const [settingsRequestPayout, setSettingsRequestPayout] = useState(false)
    const [waitingPurchase, setWaitingPurchase] = useState(false)
    const [advocatePendingRewards, setAdvocatePendingRewards] = useState([])

    const [sortBy, setSortBy] = useState(SORT_BY_CREATED_DATE)
    const [statusAscending, setStatusAscending] = useState(true)
    const [dateAscending, setDateAscending] = useState(true)
    const [rewardValueAscending, setRewardValueAscending] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(50)
    const [showPagination, setShowPagination] = useState(false)

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = advocatePendingRewards.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = pageNumber => setCurrentPage(pageNumber)


    const { data, loading, error } = useQuery(GET_PENDING_REWARDS, {
        variables: {
            refCode,
            referralDashboardCode
        }
    })


    const [requestPayout, { loading: payoutLoading, error: payoutError }] = useMutation(REQUEST_PAYOUT, {
        variables: {
            refCode,
            referralDashboardCode
        },
        onError: e => console.log(e),
        update: (cache, { data }) => {
            if (data.requestPayout) {
                setShowRequestPayout(false)
                const { advocatePendingRewards } = cache.readQuery({
                    query: GET_PENDING_REWARDS,
                    variables: {
                        refCode,
                        referralDashboardCode
                    }
                })

                const newArr = []

                advocatePendingRewards.map(value => {
                    const newVal = { ...value }

                    //filter to only set payout reqested to specific rewards that were sent a request
                    let executeRequest = true

                    if (waitingPurchase && !value.purchased && !value.tag) {
                        executeRequest = false
                    }


                    if (executeRequest && value.daysLeftToRequest <= 0) {
                        newVal.payoutRequested = true
                    }
                    newArr.push(newVal)
                })


                cache.writeQuery({
                    query: GET_PENDING_REWARDS,
                    data: { advocatePendingRewards: newArr },
                    variables: {
                        refCode,
                        referralDashboardCode
                    }
                })

            }
        }
    })

    useSetStatus({
        data, settingsRequestPayout, setShowRequestPayout, postsPerPage,
        setAdvocatePendingRewards, setShowPagination, waitingPurchase
    })

    useEffect(() => {
        if (settingsData && settingsData.advocatePanelSettings && settingsData.advocatePanelSettings.rewardAfterPurchase) {
            setWaitingPurchase(true)
        } else {
            setWaitingPurchase(false)
        }


        if (settingsData && settingsData.advocatePanelSettings && settingsData.advocatePanelSettings.requestPayout) {
            setSettingsRequestPayout(true)
        } else {
            setSettingsRequestPayout(false)

        }
    }, [settingsData])


    function sortByStatus() {
        setSortBy(SORT_BY_STATUS)
        const sortedAdvocatePendingRewards = advocatePendingRewards.sort((a, b) => {
            if (statusAscending) {
                return a.status.localeCompare(b.status)
            } else {
                return b.status.localeCompare(a.status)
            }
        })

        setAdvocatePendingRewards([...sortedAdvocatePendingRewards])

        setStatusAscending(!statusAscending)
    }

    function sortByDate() {
        setSortBy(SORT_BY_CREATED_DATE)

        const sortedAdvocatePendingRewards = advocatePendingRewards.sort((a, b) => {
            if (dateAscending) {
                return new Date(a.date).getTime() - new Date(b.date).getTime()
            } else {
                return new Date(b.date).getTime() - new Date(a.date).getTime()
            }
        })
        setAdvocatePendingRewards([...sortedAdvocatePendingRewards])
        setDateAscending(!dateAscending)
    }


    function sortByValue() {
        setSortBy(SORT_BY_VALUE)

        const sortedAdvocateRedeemedRewards = advocatePendingRewards.sort((a, b) => {
            if (rewardValueAscending) {
                return a.rewardTitle.localeCompare(b.rewardTitle)
            } else {
                return b.rewardTitle.localeCompare(a.rewardTitle)
            }
        })
        setAdvocatePendingRewards([...sortedAdvocateRedeemedRewards])
        setRewardValueAscending(!rewardValueAscending)
    }

    return (
        <div>

            {loading &&
                <div className='w-full h-full mt-32 min-h-64 flex items-center justify-center'>
                    <Loading />
                </div>
            }

            <ErrorAlert
                aff="true"
                isShown={error}
            />

            {data &&
                (data.advocatePendingRewards.length > 0 ?
                    <>
                        <div className='w-full justify-between flex items-center'>
                            <Text
                                type='text-large'
                                tailwind={`md:mx-4 lg:mx-6 font-medium ${!showRequestPayout && 'w-full text-center'} my-8`}
                            ><FormattedMessage id="aff.rewards.latestCommissions" /></Text>

                            {showRequestPayout &&
                                <div className='justify-between flex items-center'>
                                    <Button
                                        tailwind='md:mx-4 lg:mx-6'
                                        loading={payoutLoading}
                                        onClick={() => requestPayout()}
                                        size='small'
                                        variant='secondary'><FormattedMessage id="aff.rewards.requestPayout" /></Button>
                                </div>

                            }
                        </div>

                        <ErrorAlert
                            aff={true}
                            isShown={payoutError}
                        />


                        <CardWrapper tailwind='md:mx-4 lg:mx-6 mb-6'>

                            <div className='border-b border-gray-200'>
                                <div className={`grid grid-cols-4 py-4 px-6
            mr-12`}>

                                    <SortText
                                        isAscending={statusAscending}
                                        sort={sortBy === SORT_BY_STATUS}
                                        onClick={sortByStatus}><FormattedMessage id="status" /></SortText>

                                    <Text
                                        color
                                        tailwind='font-medium text-gray-800'><FormattedMessage id="referral" /></Text>

                                    <SortText
                                        isAscending={dateAscending}
                                        sort={sortBy === SORT_BY_CREATED_DATE}
                                        onClick={sortByDate}><FormattedMessage id="created" /></SortText>

                                    <SortText
                                        isAscending={rewardValueAscending}
                                        sort={sortBy === SORT_BY_VALUE}
                                        onClick={sortByValue}><FormattedMessage id="value" /></SortText>


                                </div>
                            </div>
                            {
                                currentPosts.map((value, index) => {
                                    return <RewardItem
                                        isGray={index % 2 === 0}
                                        status={value.status}
                                        {...value}
                                    />
                                })
                            }
                            {showPagination &&
                                <Pagination postsPerPage={postsPerPage}
                                    totalPosts={advocatePendingRewards.length}
                                    paginate={paginate}
                                    currentPage={currentPage} />
                            }

                        </CardWrapper>
                    </>
                    :
                    <div className='relative w-full h-full flex flex-col justify-center items-center'>
                        <div className='my-8 relative z-10'>

                            <Text
                                tailwind='w-full text-center text-lg my-24'
                            ><FormattedMessage id="aff.rewards.noReward" /></Text>
                        </div>
                    </div>

                )
            }
        </div>
    );
};

Reward.propTypes = {

};

export default Reward;
