import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/textinput'
import Button from 'components/button'
import Text from 'components/text'
import Form from 'components/form'
import { GET_SETTINGS, SET_SETTINGS } from './constants/GqlQueries';
import SuccessAlert from 'components/alert/success'
import ErrorAlert from 'components/alert/error'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loading from 'components/loading'
import RewardOption from './components/shopify-reward-option';
import Option from './components/option';
import { BASE_URL } from '../../constants';
import AffUrlOption from './components/aff-url-option';
import AffDashboard from './tabs/aff-dashboard';
import Notifications from './tabs/notifications';
import Alerts from './components/alerts';
import Tab from 'components/tab'

const tabs = ['General', 'Affiliate Dashboard', 'Notifications']

const ShopifySettings = ({ saveText, saveButtonTailwind, states, setStates,
    onFormSubmit, loading, data, disableStates
}) => {

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

    useEffect(() => {
        disableStates()
    }, [tabSelectedIndex])

    return (
        <div className='w-full h-full'>
            {states.showError &&
                <ErrorAlert
                    isShown={true}
                />
            }

            {loading.getSettingsLoading &&
                <Loading />

            }
            {data &&
                <div className='md:px-6'>
                    <div className='flex mt-2'>
                        {tabs.map((value, index) => (
                            <Tab
                                tailwind='px-8'
                                id={index}
                                variant='underline'
                                onClick={() => {
                                    setTabSelectedIndex(index)
                                }}
                                isSelected={index === tabSelectedIndex}
                            >{value}</Tab>
                        ))}
                    </div>

                    <div className='w-full h-px border-t border-gray-200' />

                    <Form onSubmit={onFormSubmit}>
                        {tabSelectedIndex === 0 &&
                            <>


                                <RewardOption
                                    rewardType={states.rewardAdvocatesType}
                                    onTypeChange={setStates.setRewardAdvocatesType}
                                    rewardName={states.rewardAdvocatesName}
                                    rewardValue={states.rewardAdvocatesValue}
                                    onNameChange={setStates.setRewardAdvocatesName}
                                    onValueChange={setStates.setRewardAdvocatesValue}
                                    title='Affiliate Reward'
                                />

                            </>
                        }

                        {tabSelectedIndex === 1 &&
                            <AffDashboard
                                states={states}
                                setStates={setStates}
                            />
                        }

                        {tabSelectedIndex === 2 &&
                            <Notifications
                                states={states}
                                setStates={setStates}
                            />
                        }



                        <Alerts
                            states={states}
                        />

                        <div className='w-full flex justify-end'>

                            <Button
                                typeSubmit
                                loading={loading.setSettingsLoading}
                                tailwind={`my-6 ${saveButtonTailwind}`}>{saveText}</Button>
                        </div>
                    </Form>

                </div >
            }
        </div>
    );
};

ShopifySettings.propTypes = {

};

export default ShopifySettings;