import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import Button from 'components/button'
import styled from 'styled-components'
import Card from 'components/card'
import RewardItem from '../reward-item';
import { useQuery } from '@apollo/react-hooks';
import { GET_REDEEMED_REWARDS } from '../../constants/GqlQueries';
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'
import SortText from 'components/sort-text'
import Pagination from 'components/pagination'
import { FormattedMessage } from 'react-intl';


const CardWrapper = styled(Card)`
    min-width: 28rem;
    
`

const SORT_BY_VALUE = 1
const SORT_BY_CREATED_DATE = 2
const SORT_BY_REDEEM_DATE = 3

const Payouts = ({ refCode, referralDashboardCode }) => {

    const [advocateRedeemedRewards, setAdvocateRedeemedRewards] = useState([])
    const [createDateAscending, setCreateDateAscending] = useState(true)
    const [rewardValueAscending, setRewardValueAscending] = useState(true)
    const [redeemDateAscending, setRedeemDateAscending] = useState(true)
    const [sortBy, setSortBy] = useState(SORT_BY_CREATED_DATE)

    const { data, loading, error } = useQuery(GET_REDEEMED_REWARDS, {
        variables: {
            refCode,
            referralDashboardCode
        }
    })

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(50)
    const [showPagination, setShowPagination] = useState(false)

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = advocateRedeemedRewards.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = pageNumber => setCurrentPage(pageNumber)

    useEffect(() => {
        if (data) {
            data.advocateRedeemedRewards.map((value) => {
                if (value.rewardValue === 'refmonkey_custom_reward_value') {
                    value.rewardTitle = value.rewardName
                } else {
                    value.rewardTitle = `${value.rewardValue} ${value.rewardName}`
                }
            })
            setAdvocateRedeemedRewards(data.advocateRedeemedRewards)
            setShowPagination(data.advocateRedeemedRewards.length / postsPerPage > 1)
        }
    }, [data])

    function sortByCreateDate() {
        setSortBy(SORT_BY_CREATED_DATE)

        const sortedAdvocateRedeemedRewards = advocateRedeemedRewards.sort((a, b) => {
            if (createDateAscending) {
                return new Date(a.date).getTime() - new Date(b.date).getTime()
            } else {
                return new Date(b.date).getTime() - new Date(a.date).getTime()
            }
        })
        setAdvocateRedeemedRewards([...sortedAdvocateRedeemedRewards])
        setCreateDateAscending(!createDateAscending)
    }

    function sortByValue() {
        setSortBy(SORT_BY_VALUE)

        const sortedAdvocateRedeemedRewards = advocateRedeemedRewards.sort((a, b) => {
            if (rewardValueAscending) {
                return a.rewardTitle.localeCompare(b.rewardTitle)
            } else {
                return b.rewardTitle.localeCompare(a.rewardTitle)
            }
        })
        setAdvocateRedeemedRewards([...sortedAdvocateRedeemedRewards])
        setRewardValueAscending(!rewardValueAscending)
    }

    function sortByRedeemDate() {
        setSortBy(SORT_BY_REDEEM_DATE)

        const sortedAdvocateRedeemedRewards = advocateRedeemedRewards.sort((a, b) => {
            if (redeemDateAscending) {
                return new Date(a.redeemDate).getTime() - new Date(b.redeemDate).getTime()
            } else {
                return new Date(b.redeemDate).getTime() - new Date(a.redeemDate).getTime()
            }
        })
        setAdvocateRedeemedRewards([...sortedAdvocateRedeemedRewards])
        setRedeemDateAscending(!redeemDateAscending)
    }

    return (
        <div>

            {loading &&
                <div className='w-full h-full mt-32 min-h-64 flex items-center justify-center'>
                    <Loading />
                </div>
            }

            <ErrorAlert
                aff={true}
                isShown={error}
            />

            {data &&
                (data.advocateRedeemedRewards.length > 0 ?
                    <>
                        <Text type='text-large' tailwind='font-medium text-center my-8'><FormattedMessage id="aff.payouts.subHeader" /></Text>

                        <CardWrapper tailwind='md:mx-4 lg:mx-6 mb-6'>

                            <div className='border-b border-gray-200'>
                                <div className={`grid grid-cols-5 py-4 px-6 mr-12
            `}>

                                    <Text
                                        color
                                        tailwind='font-medium text-gray-800'><FormattedMessage id="status" /></Text>

                                    <Text
                                        color
                                        tailwind='font-medium text-gray-800'><FormattedMessage id="referral" /></Text>

                                    <SortText
                                        isAscending={createDateAscending}
                                        sort={sortBy === SORT_BY_CREATED_DATE}
                                        onClick={sortByCreateDate}
                                    ><FormattedMessage id="created" /></SortText>

                                    <SortText
                                        sort={sortBy === SORT_BY_VALUE}
                                        isAscending={rewardValueAscending}
                                        onClick={sortByValue}><FormattedMessage id="value" /></SortText>

                                    <SortText
                                        sort={sortBy === SORT_BY_REDEEM_DATE}
                                        isAscending={redeemDateAscending}
                                        onClick={sortByRedeemDate}><FormattedMessage id="payedOn" /></SortText>
                                </div>
                            </div>

                            {
                                currentPosts.map((value, index) => {
                                    return <RewardItem
                                        index
                                        payedAt
                                        isGray={index % 2 === 0}
                                        status={value.reverted ? <FormattedMessage id="reverted" /> : <FormattedMessage id="payedOut" />}
                                        {...value}
                                    />
                                })
                            }
                            {showPagination &&
                                <Pagination postsPerPage={postsPerPage}
                                    totalPosts={advocateRedeemedRewards.length}
                                    paginate={paginate}
                                    currentPage={currentPage} />
                            }

                        </CardWrapper>
                    </>
                    :
                    <div className='relative w-full h-full flex flex-col justify-center items-center'>
                        <div className='my-8 relative z-10'>

                            <Text
                                tailwind='w-full text-center text-lg my-24'
                            ><FormattedMessage id="aff.payouts.noPayouts" /></Text>
                        </div>
                    </div>

                )
            }
        </div>
    );
};

Payouts.propTypes = {

};

export default Payouts;
