import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Route, useHistory } from 'react-router-dom';
import OnBoarding from 'app/onboarding'
import Container from 'components/container'
import Logo from 'components/logo'
import Card from 'components/card'
import LogoPNG from 'images/logo.png'
import Welcome from '../components/welcome';
import Text from 'components/text'
import { HTTP_URL } from 'constants/index.js'
import { ReactComponent as ArrowRightSVG } from 'images/arrow-right.svg'
import Button from 'components/button'
import GrayDiv from 'components/gray-div'

const ChoiceOfSetup = props => {

    const [showDone, setShowDone] = useState(false)
    const history = useHistory()
    const match = useRouteMatch()

    return (
        <GrayDiv>
            <Route path={`${match.path}/setup`}>
                <OnBoarding />
            </Route>

            <Route exact path={`${match.path}`}>

                <Container
                    tailwind='flex flex-col justify-center items-center'
                    narrow={true}>

                    <Logo
                        clickable={false}
                        tailwind='my-6'
                        text='RefMonkey'
                        icon={LogoPNG}
                    />

                    <Card tailwind='p-4 max-w-lg'>
                        <Welcome
                            description='To get started, Choose one of the below options to create your referral campaign.'
                        />

                        <div className='w-full px-4 my-8'>
                            <Card
                                onClick={() => {
                                    window.open(
                                        'https://calendly.com/steven-239/refmonkey',
                                        '_blank' // <- This is what makes it open in a new window.
                                    )

                                    setShowDone(true)
                                }}
                                tailwind='w-full cursor-pointer hover:bg-gray-100 p-10 py-16 '>

                                <Text  tailwind='font-medium'>I'd like to have my referral campaign created for me.</Text>
                                <Text
                                    type='text-small'
                                    tailwind='mt-6 italic '>(Click here to setup a time to talk with a RefMonkey engineer to setup your campaign for you and integrate it with your site)</Text>
                            </Card>
                        </div>

                        <div className='w-full px-4 my-8'>
                            <Card
                                onClick={() => {
                                    history.push(`${match.path}/setup/reward`)
                                }}
                                tailwind='w-full cursor-pointer hover:bg-gray-100 p-10 py-16 '>

                                <Text tailwind='font-medium'>I'd like to setup RefMonkey myself.</Text>
                                <Text
                                    type='text-small'
                                    tailwind='mt-6 italic '>(Click here to setup your campaign and integrate with your site)</Text>
                            </Card>
                        </div>


                        {showDone &&
                        <div className='w-full px-4 pt-6'>
                            <Button
                                iconRight={<ArrowRightSVG />}
                                onClick={() => window.location.href = HTTP_URL}
                                tailwind={`my-8 w-full`}>Complete</Button>
                                </div>
                        }
                        
                    </Card>


                </Container>

            </Route>


        </GrayDiv>
    );
};

ChoiceOfSetup.propTypes = {

};

export default ChoiceOfSetup;