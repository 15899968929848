import ApolloClient from 'apollo-client'
import { getToken } from 'utils/authUtil'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {createHttpLink} from 'apollo-link-http' 
import {createUploadLink} from 'apollo-upload-client'
import { ApolloLink } from '@apollo/client';

const httpLink = createUploadLink({
  uri: (process.env.NODE_ENV === 'development') ? `http://localhost:4000/graphql` : 'https://api.refmonkey.com/graphql',

})

const authLink = new ApolloLink((operation,forward)=> {
  const token = getToken();
  operation.setContext({
    headers: {
      authorization: token
    }
  })
  return forward(operation)
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})


  export default apolloClient