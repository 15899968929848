import React, { useState, useEffect } from 'react'
import Text from 'components/text'
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoSVG } from 'images/info.svg'
import Icon from 'components/icon'
import useGenerateTooltipId from '../../../../hooks/useGenerateTooltipId';


const RewardDetails = (props) => {
    //determines whether the details drop down can be show or not
    const [allowShowDetails, setAllowShowDetails] = useState(false)
   const id = useGenerateTooltipId(props.index)
    
    useEffect(() => {
        if (props.productName || props.productDescription || props.tag) {
            setAllowShowDetails(true)
        }
    }, [props.productName, props.productDescription])

   

    return (
        <>
        <div
                data-tip data-for={id}>
                <Icon
                    color
                    tailwind={allowShowDetails ? `text-gray-400`: 'opacity-0'}
                    src={<InfoSVG />}
                    hasBackground={false}
                />
            </div>

        {allowShowDetails &&

        
            

            <ReactTooltip
                id={id}
                // aria-haspopup='true'
                effect='solid'
                // type='info'
                place='bottom'>

                <div className='px-8 py-4 my-4'>
                    {props.revertMessage && <Text color tailwind='mb-2 text-gray-400'>Cause for revert:  <Text color span tailwind='text-white'>{`     ${props.revertMessage}`}</Text></Text>}

                    {props.tag && <Text color tailwind='text-gray-400'>Type:     <Text color span tailwind='text-white'>{`     ${props.tag}`}</Text>   </Text>}
                    {props.productName && <Text color tailwind='text-gray-400'>Product Name:     <Text color span tailwind='text-white'>{`     ${props.productName}`}</Text></Text>}
                    {props.productDescription && <Text color tailwind='text-gray-400'>Product Description:       <Text color span tailwind='text-white'>{`     ${props.productDescription}`}</Text> </Text>}

                </div>
            </ReactTooltip>
}
        </>
    )
}

export default RewardDetails