import React, { useState, useEffect } from 'react';
import Card from 'components/card'
import Text from 'components/text'
import { useQuery } from '@apollo/react-hooks';
import { GET_REDEEMED_REWARDS } from './constants/GqlQueries';
import ErrorAlert from 'components/alert/error'
import Loading from 'components/loading'
import RedeemedRewardItem from './components/item';
import emptyIllustrationPNG from 'images/emptyIllustration.png'
import RewardHelp from './components/rewardHelp'
import ShopifyRewardHelp from './components/shopifyRewardHelp'
import SortText from 'components/sort-text'
import Pagination from 'components/pagination'

import styled from 'styled-components'
import useIsShopify from '../hooks/useIsShopify';

const Image = styled.img`
    opacity: .04;
`

const CardWrapper = styled(Card)`
    min-width: 28rem;
    
`

const SORT_BY_USER = 1
const SORT_BY_DATE = 2
const SORT_BY_REWARD = 3

const RedeemedRewards = () => {
    const [redeemedRewards, setRedeemedRewards] = useState([])

    const { data, loading, error } = useQuery(GET_REDEEMED_REWARDS)

    const [userAscending, setUserAscending] = useState(true)
    const [dateAscending, setDateAscending] = useState(true)
    const [rewardAscending, setRewardAscending] = useState(true)
    const [sortBy, setSortBy] = useState(SORT_BY_DATE)

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(50)
    const [showPagination, setShowPagination] = useState(false)

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = redeemedRewards.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = pageNumber => setCurrentPage(pageNumber)

    useEffect(() => {
        if (data) {
            const redeemedRewardsItems = []

            data.redeemedRewards.map((value) => {
                if (!value.identifier.includes(process.env.REACT_APP_SHOPIFY_PURCHASE_PREPEND)) {
                    redeemedRewardsItems.push(value)
                }
            })

            setRedeemedRewards(redeemedRewardsItems)
            setShowPagination(data.redeemedRewards.length/postsPerPage > 1)
        }

    }, [data])

    const { usesShopify } = useIsShopify()

    function sortByUser() {
        setSortBy(SORT_BY_USER)

        const sortedRedeemedRewards = redeemedRewards.sort((a, b) => {
            if (userAscending) {
                return a.identifier.localeCompare(b.identifier)
            } else {
                return b.identifier.localeCompare(a.identifier)
            }
        })
        setRedeemedRewards([...sortedRedeemedRewards])
        setUserAscending(!userAscending)
    }

    function sortByDate() {
        setSortBy(SORT_BY_DATE)

        const sortedRedeemedRewards = redeemedRewards.sort((a, b) => {
            if (dateAscending) {
                return new Date(a.date).getTime() - new Date(b.date).getTime()
            } else {
                return new Date(b.date).getTime() - new Date(a.date).getTime()
            }
        })
        setRedeemedRewards([...sortedRedeemedRewards])
        setDateAscending(!dateAscending)
    }

    function sortByReward() {
        setSortBy(SORT_BY_REWARD)

        const sortedRedeemedRewards = redeemedRewards.sort((a, b) => {
            if (rewardAscending) {
                return a.rewardValue.localeCompare(b.rewardValue)
            } else {
                return b.rewardValue.localeCompare(a.rewardValue)
            }
        })
        setRedeemedRewards([...sortedRedeemedRewards])
        setRewardAscending(!rewardAscending)
    }


    return (

        <div className='h-full'>
            {error &&
                <ErrorAlert
                    isShown={true} />}

            {loading &&
                <Loading />}

            {data &&
                (redeemedRewards.length > 0 ?

                    <CardWrapper tailwind='md:mx-4 lg:mx-6 mb-6'>

<div className='border-b border-gray-200'>
                        <div className={`grid grid-cols-4 py-4 px-6 mr-12`}>
                            <SortText
                                isAscending={userAscending}
                                sort={sortBy === SORT_BY_USER}
                                onClick={sortByUser}
                                tailwind='col-span-2'>User</SortText>
                            <SortText
                                isAscending={dateAscending}
                                sort={sortBy === SORT_BY_DATE}
                                onClick={sortByDate}
                            >Rewarded on</SortText>
                            <SortText
                                isAscending={rewardAscending}
                                sort={sortBy === SORT_BY_REWARD}
                                onClick={sortByReward}
                            >Reward</SortText>
                        </div>
                        </div>
                        {
                            currentPosts.map((value, index) => {
                                return <RedeemedRewardItem
                                    isGray={index % 2 === 0}
                                    index={index}
                                    {...value}
                                />
                            })
                        }

                        {showPagination &&
                            <Pagination postsPerPage={postsPerPage}
                                        totalPosts={redeemedRewards.length}
                                        paginate={paginate}
                                        currentPage={currentPage}/>
                        }

                    </CardWrapper>
                    :
                    <div className='relative w-full h-full flex flex-col justify-center items-center'>
                        <div className='my-8 relative z-10'>

                            <Text
                                tailwind='w-full text-center font-medium text-lg my-6'
                            >No redeemed rewards yet.</Text>
                            {usesShopify ? <ShopifyRewardHelp /> : <RewardHelp />}
                        </div>

                        <Image
                            alt='No redeemed rewards yet.'
                            className='z-0 absolute w-full h-full object-cover'
                            src={emptyIllustrationPNG} />

                    </div>

                )
            }
        </div>
    );
};

export default RedeemedRewards;
