import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {

    const inputParams = <>
        <ParamItem
            isJs
            required={true}
            title='conversionID'
            type='string'
            content={`The ID associated with the conversion`}

        />

        <ParamItem
            isJs
            required={false}
            title='message'
            type='string'
            content={`A message describing the reason for reversing the conversion.`}
        />

    </>

    const outputParams = <>
        <ParamItem
            title='success'
            type='boolean'
            content={`Indicates the outcome of the function call.`}
        />
        <ParamItem
            title='message'
            type='string'
            content={`Provides additional details`}
        />
    </>

    return (
        <Request
            id='reverse-conversion'
            title='Reverse a Conversion'
            description='This function allows you to revert a conversion after the conversion function had been called.'
            tabs={[{
                title: 'async',
                script: false,
                inputParams,
                outputParams,
                code: ` await window.refMonkeyClient.reverseConversion(conversionID, message) `
            },
            {
                title: 'sync',
                script: false,
                inputParams: <>

                    {inputParams}

                    <ParamItem
                        required={false}
                        title='callback'
                        type='function'
                        content={`Callback, is called after method completion.`}
                        childItems={outputParams}
                    />
                </>,

                code: `  window.refMonkeyClient.reverseConversion(conversionID, message, (response)=>{ 
                    //
                   }) `
            }
            ]}

        />

    );
};

DocRequest.propTypes = {

};

export default DocRequest;