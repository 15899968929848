import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from '../count-item'
import CodeBox from 'components/code-box'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import Button from 'components/button'
import Text from 'components/text'
import useGetAccessToken from '../../hooks/useGetAccessToken';
import { HashLink, NavHashLink } from 'react-router-hash-link';

const ApiSetup = ({ mobileView, onDocs }) => {
    const history = useHistory()
    const match = useRouteMatch()
    const { data } = useGetAccessToken()


    return (
        <div className='w-full pb-16'>
            <Button
                tailwind={` w-full  ${!mobileView && 'md:w-auto'}`}
                variant='outline'
                size='extra-small'
                onClick={() => history.goBack()}
            >View all</Button>

            <Heading>Integrate with REST API</Heading>

            <Content>If you don't want to use any javascript, you can follow these steps to set up a REST-only integration.</Content>

            <CountItem
                number='1'>
                For any page visit, check the URL for the presence of the <b>?ref=</b> query parameter.
                 If it is present, get the value and persist this value in a cookie.
            </CountItem>

            <CountItem
                number='2'>
                Whenever an action that you want to track is performed,this is called a <b>conversion</b>, i.e someone signs up, makes a purchase,submits form,etc. you will need to signal our platform that a conversion has happened. To do so,call
                <HashLink
                    onClick={onDocs}
                    className='text-blue-500 hover:text-blue-400'
                    to={`${match.url}/docs#conversion`}> this API endpoint </HashLink>
                passing in the persisted <b>ref</b> value as a parameter.
            </CountItem>

           

            <Heading>Reverting a Conversion</Heading>
            
            <CountItem
                number='3'>
                <Text tailwind='font-medium'>Optional</Text>
                There are situations when you need to reverse a conversion , (i.e for a refund), use the <b>conversion_id</b> returned by the conversion endpoint.
                call  
                <HashLink
                    onClick={onDocs}
                    className='text-blue-500 hover:text-blue-400'
                    to={`${match.url}/docs#reverse-conversion`}> this API endpoint </HashLink>
    passing in the <b>conversion_id</b> as a parameter.
        
            </CountItem>

            <div className='mt-16'>
            <Content><b>Important:</b> This concludes our setup instructions for our most basic form of integration.</Content>
            </div>

            <Content>For all additional tracking posibilities, like referral links and more…
     please check our<Link
                    onClick={onDocs}
                    className='text-blue-500 hover:text-blue-400'
                    to={`${match.url}/docs`}> complete REST API reference.</Link></Content>

        </div>
    );
};

ApiSetup.propTypes = {

};

export default ApiSetup;