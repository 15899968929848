import React from 'react'
import PropTypes from 'prop-types';
import HeaderComponent from 'components/contact/header'
import Logo from '../logo'
import {useHistory} from 'react-router-dom'

const Header = () =>{
  const history = useHistory()
  
    return (
      <HeaderComponent
      logo={ <Logo />}
      getStartedText='Get Started'
      onGetStarted={() => { history.push('/create-account') }}
      onLogin={() => { history.push('/login') }}
      />
    )
}

Header.defaultProps = {

}

Header.propTypes = {

};

export default Header;