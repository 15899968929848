import React from 'react'
import Option from '../option'
import Text from 'components/text'
import Dropdown from '../../../../components/dropdown/index'
const LanguageOptions = (props) => {
    return (
        <Option
            toggleValue={false}
            title='Language Options' 
         >
             <div className="">
             <select className="px-3 py-1 border border-cool-gray-400 border0 rounded outline-none" onChange={(e)=> props.setLanguage(e.target.value)} value={props.language} id="">
                 <option name="en-US" value="en-US" >
                     English
                 </option>
                 <option name="es-MX" value="es-MX">
                     Spanish
                 </option>
                 <option name="fr" value="fr">
                     French
                 </option>
                 <option name="ja-JP" value="ja-JP">
                     Japanese
                 </option>
                 <option name="he" value="he">
                     Hebrew
                 </option>
             </select>
             </div>

           
            <Text
            color
            type='text-small'
            tailwind='my-2 text-gray-600 mt-5'>
            Preferred language that will display on the affiliate dashboard.
            </Text>
            
        </Option>
    )
}

export default LanguageOptions
