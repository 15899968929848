import { gql } from 'apollo-boost'

export const GET_REF_LINK = gql`
    query($refCode : String, $referralDashboardCode: String){
        refLink(refCode: $refCode,referralDashboardCode:$referralDashboardCode){
            code
            url
            link
        }
    }
`

export const GET_ADVOCATE = gql`
    query($refCode : String, $referralDashboardCode: String){
        advocate(refCode: $refCode,referralDashboardCode:$referralDashboardCode){
            identifier
            name
            refCode
            approved
            isDisabled
        }
    }
`


export const GET_CLICK_COUNT = gql`
    query($refCode : String, $referralDashboardCode: String){
        advocateClickCount(refCode: $refCode,referralDashboardCode:$referralDashboardCode)
    }
`


export const GET_REFERRALS = gql`
    query($refCode : String,$referralDashboardCode: String){
        referrals(advocateRefCode: $refCode,referralDashboardCode: $referralDashboardCode){
            identifier
            date
            name
        }
    }
`

export const GET_PENDING_REWARDS = gql`
    query($refCode : String,$referralDashboardCode: String){
        advocatePendingRewards(advocateRefCode: $refCode,referralDashboardCode: $referralDashboardCode){
            name
            identifier
            type
            date
            rewardName
            rewardValue
            units
            payoutRequested
            daysLeftToRequest
            purchased
            referralIdentifier
            tag
            productName
            productDescription
        }
    }
`

export const GET_REDEEMED_REWARDS = gql`
    query($refCode : String,$referralDashboardCode: String){
        advocateRedeemedRewards(advocateRefCode: $refCode,referralDashboardCode: $referralDashboardCode){
            name
            identifier
            type
            date
            rewardName
            rewardValue
            units
            redeemDate
            referralIdentifier
            tag
            productName
            productDescription
            reverted
            revertMessage
        }
    }
`

export const REQUEST_PAYOUT = gql`
    mutation($refCode : String,$referralDashboardCode: String){
        requestPayout(advocateRefCode: $refCode,referralDashboardCode: $referralDashboardCode)
    }
`


export const GET_SETTINGS = gql`
    query($referralDashboardCode: String){
        advocatePanelSettings(referralDashboardCode: $referralDashboardCode){
            rewardAdvocates{
                status
                name
                value
            }
            referralsRequired
            requestPayout
            recurringReward
            rewardAfterPurchase
            enableTerms
            termsUrl
            enablePaypal
            approveAdvocates
        }
    }
`

export const GET_IS_ARCHIVED = gql`
    query($referralDashboardCode: String){
        isArchived(referralDashboardCode: $referralDashboardCode)   
    }
`


export const GET_DASHBOARD_CODE = gql`
    query($customDomain: String){
        referralDashboardCode(customDomain: $customDomain)
    }
`

export const GET_ADVOCATE_SETTINGS = gql`
    query($referralDashboardCode: String, $identifier: String){
        advocateSettings(identifier: $identifier,referralDashboardCode: $referralDashboardCode){
            paypalEmail
            identifier
        }
    }
`

export const SET_ADVOCATE_SETTINGS = gql`
    mutation($identifier: String, $paypalEmail: String,$referralDashboardCode: String){
        setAdvocateSettings(referralDashboardCode: $referralDashboardCode, 
            settings: {
                identifier: $identifier,paypalEmail: $paypalEmail
            }){
            paypalEmail
            identifier
        }
    }
`


export const SET_REFCODE = gql`
    mutation($refCode : String,$oldRefCode: String, $referralDashboardCode: String){
        setRefCode(refCode: $refCode,oldRefCode: $oldRefCode,referralDashboardCode:$referralDashboardCode)
    }
`

