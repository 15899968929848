import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card'
import { GET_REF_LINK, SET_REFCODE } from '../../constants/GqlQueries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Text from 'components/text'
import CodeBox from 'components/code-box'
import TextInput from 'components/textinput'
import ErrorAlert from 'components/alert/error'
import Button from 'components/button'
import Alert from 'components/alert'
import { isErrorCode } from 'components/signin/utils';
import { setRefCode as setAuthRefCode } from 'app/affiliate-login/utils/authUtil'
import { FormattedMessage } from 'react-intl';


const DynamicRefCode = ({ toRight,refCode, referralDashboardCode }) => {

    const [getRefLink, { data: refLinkData, error: refLinkError }] = useLazyQuery(GET_REF_LINK, {
        variables: {
            refCode,
            referralDashboardCode
        }
    })



    useEffect(() => {
        if (referralDashboardCode && refCode) {
            getRefLink()
        }
    }, [refCode, referralDashboardCode])



    return (
        <Card tailwind='w-full p-6'>

            <>
                <div className='flex justify-between mb-4 items-center'>
                    <Text className={toRight && "text-right"} tailwind=''><FormattedMessage id="aff.dashboard.dynamic-share" /></Text>
                </div>

                <ErrorAlert
                    aff={true}
                    isShown={refLinkError}
                />

                {!refLinkError &&
                    <>
                        <CodeBox tailwind='my-6'>
                            {refLinkData && refLinkData.refLink.code}
                        </CodeBox>

                        <div className='mt-4 items-center'>
                            <Text color tailwind='text-gray-400'><FormattedMessage id="example" /></Text>
                            <Text color tailwind='text-gray-400'>{refLinkData && (refLinkData.refLink.url ? refLinkData.refLink.url : 'www.mysite.com/route')}</Text>
                            <Text color tailwind='text-gray-400'>{refLinkData && `${(refLinkData.refLink.url ? refLinkData.refLink.url : 'www.mysite.com/route')}?ref=yourId`}</Text>
                        </div>
                    </>

                }

            </>

        </Card>
    );
};

DynamicRefCode.propTypes = {

};

export default DynamicRefCode;