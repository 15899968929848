import React, { useState, useEffect } from "react";
import Option from "../../components/option";
import Text from "components/text";
import Loading from "../../../../components/loading";
import { withRouter } from "react-router-dom";
import SquareLogo from "../../../../images/SquareLogo.png";
import md5 from "md5";
import {
	GET_SQUARE_INFO,
	CONNECT_SQUARE,
	DISCONNECT_SQUARE,
} from "../../constants/GqlQueries";
import { useQuery, useMutation } from "@apollo/react-hooks";

function ConnectSquare({ location, history }) {
	const [error, setError] = useState(false);
	const [showSquareErrorMessage, setShowSquareErrorMessage] = useState(false);

	const squareConnectHandler = (e) => {
		e.preventDefault();
		localStorage.setItem("state", md5(Date.now()));
		window.location.replace(
			`${
				process.env.NODE_ENV === "development"
					? `https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-KECnnYakgs6Nk8RT8pMgLQ&scope=PAYMENTS_READ+SUBSCRIPTIONS_READ+CUSTOMERS_READ&state=${localStorage.getItem(
							"state"
					  )}`
					: `https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-sgPxGR6QyMWFFdH5vzh68g&scope=PAYMENTS_READ+SUBSCRIPTIONS_READ+CUSTOMERS_READ&state=${localStorage.getItem(
							"state"
					  )}`
			} `
		);
	};

	useEffect(() => {
		if (error) {
			setShowSquareErrorMessage(true);
			const showTime = setTimeout(() => {
				setShowSquareErrorMessage(false);
				setError(false);
			}, 3000);
		}
	}, [error]);

	const getSquareInfo = useQuery(GET_SQUARE_INFO);
	//if there is an data and expiresAt Date is greater than new Date(), we need to
	//refresh the token before we do anything at all

	const [connectSquare, connectSquareResponse] = useMutation(CONNECT_SQUARE, {
		onError: (err) => {
			if (err.graphQLErrors[0]?.extensions?.exception?.body) {
				const errorMessage = JSON.parse(
					err.graphQLErrors[0].extensions.exception.body
				).message;
				if (errorMessage !== "Authorization code is already claimed.") {
					setError(true);
				}
			} else {
				setError(true);
			}
			history.replace("/settings");
		},
		onCompleted: () => {
			history.replace("/settings");
		},
		refetchQueries: () => {
			return [{ query: GET_SQUARE_INFO }];
		},
	});

	const [disconnectSquare, disconnectSquareResponse] = useMutation(
		DISCONNECT_SQUARE,
		{
			onError: (err) => {
				setError(true);
			},
			onCompleted: ({ disconnectSquare }) => {
				history.replace("/settings");
			},
			refetchQueries: () => {
				return [{ query: GET_SQUARE_INFO }];
			},
		}
	);

	const squareDisconnectHandler = (e) => {
		e.preventDefault();
		const squareInfoObject = getSquareInfo.data.getSquareInfo;
		const { accessToken, expiresAt, refreshToken } = squareInfoObject;
		disconnectSquare({ variables: { accessToken } });
	};

	useEffect(() => {
		if (location.search) {
			const code = new URLSearchParams(location.search).get("code");
			const responseType = new URLSearchParams(location.search).get(
				"response_type"
			);
			const stateParam = new URLSearchParams(location.search).get("state");
			if (
				getSquareInfo.data &&
				!getSquareInfo.data.getSquareInfo &&
				code &&
				responseType === "code" &&
				stateParam == localStorage.getItem("state")
			) {
				connectSquare({ variables: { authCode: code } });
			}
		}
	}, [getSquareInfo.data]);

	return (
		<div>
			<Option
				title='Connect with Square'
				content={
					getSquareInfo.data && getSquareInfo.data.getSquareInfo
						? "Your Square Account is connected to Refmonkey."
						: "Connect your Square Account with Refmonkey to  track how much revenue each affiliate generates."
					// : "Your Stripe Account is connected to Refmonkey."
				}>
				{/* add loadings here */}

				{/* {loading || connectedResult.loading || disconnectedResult.loading ? (
					<Loading />
				) : (
					<button
						onClick={
							data && data.getStripeUserID
								? disconnectStripeHandler
								: stripeConnectHandler
						}
						className={
							data && data.getStripeUserID
								? "flex items-center focus:outline-none transition duration-300 ease-in-out text-white hover:bg-red-600 bg-red-700 rounded-lg w-22 h-10 pt-3 pb-3 px-3 text-center  mb-3 border border-2 border-none"
								: "focus:outline-none w-32 transition duration-300 ease-in-out justfy-center items-center hover:bg-gray-700 hover:text-white bg-gray-100 rounded-xl px-3 py-1 flex flex-row border border-2 border-black"
						}>
						{data && !data.getStripeUserID && (
							<img className=' rounded-3xl mr-1 h-4' src={StripeLogo} alt='' />
						)}
						{data && data.getStripeUserID ? "Disconnect" : "Connect"}
					</button>
				)} */}
				{getSquareInfo.loading || connectSquareResponse.loading ? (
					<Loading />
				) : (
					<button
						onClick={
							getSquareInfo.data && getSquareInfo.data.getSquareInfo
								? squareDisconnectHandler
								: squareConnectHandler
						}
						className={
							getSquareInfo.data && getSquareInfo.data.getSquareInfo
								? "flex items-center focus:outline-none transition duration-300 ease-in-out text-white hover:bg-red-600 bg-red-700 rounded-lg w-22 h-10 pt-3 pb-3 px-3 text-center  mb-3 border border-2 border-none"
								: "focus:outline-none w-32 transition duration-300 ease-in-out justfy-center items-center hover:bg-gray-700 hover:text-white bg-gray-100 rounded-xl px-3 py-1 flex flex-row border border-2 border-black"
						}>
						<div className='w-full flex justify-center items-center'>
							{getSquareInfo.data && getSquareInfo.data.getSquareInfo ? (
								""
							) : (
								<img
									className=' rounded-3xl mr-1 h-4'
									src={SquareLogo}
									alt=''
								/>
							)}
							{getSquareInfo.data && getSquareInfo.data.getSquareInfo
								? "Disconnect"
								: "Connect"}
						</div>
					</button>
				)}

				{showSquareErrorMessage && (
					<Text
						className='text-red-500 m-2 border-none bg-red-100 text-center py-3 w-96 px-2 border rounded-lg transition opacity-100 duration-300 ease-in-out'
						type='text-small'>
						Problem occured while connecting Square Account!{" "}
					</Text>
				)}

				{/* {states.showStripeErrorMessage && (
					<Text
						className='text-red-500 m-2 border-none bg-red-100 text-center py-3 w-96 px-2 border rounded-lg transition opacity-100 duration-300 ease-in-out'
						type='text-small'>
						Problem occured while connecting Stripe Account!{" "}
					</Text>
				)} */}
			</Option>
		</div>
	);
}

export default withRouter(ConnectSquare);
