import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import TextInput from 'components/textinput'
import Form from 'components/form'
import FieldInput from 'components/fieldinput'
import Button from 'components/button'
import Option from 'app/settings/components/option'
import { GET_ADVOCATE_SETTINGS, SET_ADVOCATE_SETTINGS } from '../../constants/GqlQueries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import SuccessAlert from 'components/alert/success'
import ErrorAlert from 'components/alert/error'
import { FormattedMessage,injectIntl } from 'react-intl';


const Settings = ({ toRight,enablePaypal, advocateData, referralDashboardCode,intl }) => {

    const [paypalEmail, setPaypalEmail] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)


    const [getAdvocateSettings, { data: advocateSettingsData }] = useLazyQuery(GET_ADVOCATE_SETTINGS)
    const [setAdvocateSettings, { loading: saveLoading }] = useMutation(SET_ADVOCATE_SETTINGS, {
        onError: e => setShowError(true),
        update: (cache) => {
            const { advocateSettings } = cache.readQuery({
                 query: GET_ADVOCATE_SETTINGS,
                 variables: {
                    referralDashboardCode,
                    identifier: advocateData.advocate.identifier
                }
                })
            
            cache.writeQuery({
                query: GET_ADVOCATE_SETTINGS,
                data: {
                    advocateSettings: {
                        __typename: advocateSettings.__typename,
                        paypalEmail
                    },
                    variables: {
                        referralDashboardCode,
                        identifier: advocateData.advocate.identifier
                    }
                }
            })

            setShowSuccess(true)
        }
    })

    useEffect(() => {
        if (advocateData && referralDashboardCode) {
            getAdvocateSettings({
                variables: {
                    referralDashboardCode,
                    identifier: advocateData.advocate.identifier
                }
            })
        }
    }, [advocateData, referralDashboardCode])

    useEffect(() => {
        console.log({advocateSettingsData})

        if (advocateSettingsData && advocateSettingsData.advocateSettings) {
            const ppEmail = advocateSettingsData.advocateSettings.paypalEmail
            setPaypalEmail(ppEmail)
        }

    }, [advocateSettingsData])


    const handleSave = () => {
        setShowSuccess(false)
        setShowError(false)


        setAdvocateSettings({
            variables: {
                identifier: advocateData.advocate.identifier,
                paypalEmail,
                referralDashboardCode
            }
        })

    }

    return (
        enablePaypal ?

            <Form  onSubmit={handleSave}>

                <div>

                    {enablePaypal &&
                        <Option
                        
                            title={<FormattedMessage id="aff.settings.paypalTitle"/>}
                        >
                            <FieldInput
                                className={"text-right"}
                                aff={true}
                                value={paypalEmail}
                                onChange={value => setPaypalEmail(value)}
                                type='email'
                                tailwind='w-64 mb-4'
                                placeholder={intl.formatMessage({id: "aff.settings.paypalPlaceholder"})}
                            />
                        </Option>
                    }


                    {showError &&
                        <ErrorAlert
                            aff={true}
                            tailwind='my-4'
                            isShown={true}
                        />
                    }

                    <SuccessAlert
                        content='Saved'
                        tailwind='my-4'
                        isShown={showSuccess}
                    />

                    <div className='flex justify-end w-full'>
                        <Button
                            typeSubmit
                            loading={saveLoading}
                            //onClick={handleSave}
                            tailwind='my-6'>{intl.formatMessage({id: "aff.dashboard.saveButton"})}</Button>
                    </div>
                </div>
            </Form>

            :
            //todo get rid of this section when more settings are added
            <div className='flex items-center justify-center h-64'>
                <Text><FormattedMessage id="aff.settings.noSettings"/></Text>
            </div>
    );
};

Settings.propTypes = {

};

export default injectIntl(Settings);