import { gql } from 'apollo-boost'


export const REGISTER = gql`
    mutation($email: String,$password: String,$name:String,$referralDashboardCode: String){
        advocateRegister(email: $email,password: $password,name:$name,referralDashboardCode: $referralDashboardCode){
            refCode
            identifier
        }
    }
`

export const LOGIN = gql`
    mutation($email: String,$password: String,$referralDashboardCode: String){
        advocateLogin(email: $email,password: $password,referralDashboardCode: $referralDashboardCode){
            refCode
            identifier
        }
    }
`


export const RESET_PASSWORD = gql`
mutation($email: String!,$referralDashboardCode : String!){
  resetAdvocatePassword(email: $email,referralDashboardCode: $referralDashboardCode)
}
`

export const CHANGE_TOKEN_PASSWORD = gql`
  mutation($token: String!,$password:String!,$referralDashboardCode : String!){
changeAdvocateTokenPassword(token:$token,password: $password,referralDashboardCode: $referralDashboardCode){
    identifier
  }
  }
`

export const IS_PASSWORD_RESET_VALID = gql`
query($token: String!,$referralDashboardCode : String!){
  isAdvocatePasswordResetValid(token: $token,referralDashboardCode: $referralDashboardCode)
}
`