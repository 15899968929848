import { useMutation } from "@apollo/react-hooks";
import { VERIFY_SHOPIFY, SHOPIFY_CHARGE } from "./constants/GqlQueries";
import { isLoggedIn } from 'utils/authUtil';
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from 'react'
import { signin } from "../../../utils/authUtil";
import { HTTP_URL } from 'constants/index.js'
import { IS_LOGGED_IN } from "../../../components/signin/constants/SigninGqlQueries";

const useVerifyShopify = () => {
    const history = useHistory()
    const [manualLoggedIn, setManualLoggedIn] = useState(false)
    const [isShopify, setIsShopify] = useState(false)




    const [verifyShopifyMutate, { data: verifyData, loading }] = useMutation(VERIFY_SHOPIFY, {
        onError: (e) => console.log(e)
    })

    const [shopifyCharge] = useMutation(SHOPIFY_CHARGE, {
        onError: (e) => console.log(e)
    })

    const code = useRef()
    const hmac = useRef()
    const timestamp = useRef()
    const state = useRef()
    const shop = useRef()

    const charge = useRef()


    useEffect(() => {
        if (verifyData) {
            const { success, email, jwt, chargeConfirmationUrl } = verifyData.authenticateShopify

            if (success) {
                if (jwt) {
                    signin(jwt, email)
                    setManualLoggedIn(true)

                    if (chargeConfirmationUrl) {
                        window.location.href = chargeConfirmationUrl
                    }


                } else {
                    history.replace(`/shopify-create-account?email=${email}`)
                }
            } else {
                //do something when installation fails
            }
        }

    }, [verifyData])

    useEffect(() => {
        const url = new URL(window.location.href);
        code.current = url.searchParams.get("code");
        hmac.current = url.searchParams.get("hmac");
        timestamp.current = url.searchParams.get("timestamp");
        state.current = url.searchParams.get("state");
        shop.current = url.searchParams.get("shop");
        charge.current = url.searchParams.get("charge_id");


        if (code.current && hmac.current && shop.current) {
            verifyShopify()
        } else if (charge.current) {
            //a shopify charge was made
            shopifyCharge()
        }



    }, [])
    /**
     * 
     */
    const verifyShopify = () => {

        if (code.current && hmac.current && shop.current) {
            setIsShopify(true)

            verifyShopifyMutate({
                variables: {
                    code: code.current,
                    hmac: hmac.current,
                    timestamp: timestamp.current,
                    state: state.current,
                    shop: shop.current
                }
            })
        }
    }


    return { loading, manualLoggedIn,isShopify }
}

export default useVerifyShopify