import { useState, useEffect } from 'react'
import { GET_USER } from './constants/GqlQueries'
import { useQuery } from '@apollo/react-hooks'

const useIsShopify = () => {
    const [usesShopify, setUsesShopify] = useState(false)

    const { data: userData } = useQuery(GET_USER)

    useEffect(() => {
        if (userData && userData.user.usesShopify) {
            setUsesShopify(true)
        }
    }, [userData])


    return { usesShopify }

}

export default useIsShopify