import React from 'react'
import PropTypes from 'prop-types';

/**
 * todo add documentation at the bottom
 */
const Content = () =>{
    return (
        <div>

        </div>
    )
}

Content.defaultProps = {

}

Content.propTypes = {

};

export default Content;