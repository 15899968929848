import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CreditCard from 'react-credit-cards';
import TextInput from 'components/textinput'
import Form from 'components/form'
import { useMutation } from '@apollo/react-hooks';
import { SEND_CC } from './constants/GqlQueries';
import Container from 'components/container'
import Logo from 'components/logo'
import Card from 'components/card'
import LogoPNG from 'images/logo.png'
import Text from 'components/text'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import Button from 'components/button'
import { useHistory } from 'react-router-dom';
import ErrorAlert from 'components/alert/error'
import Alert from 'components/alert'
import styled from 'styled-components'
import GrayDiv from 'components/gray-div'
import Testimonial1PNG from 'images/testimonials/1.png'
import Testimonial2PNG from 'images/testimonials/2.png'
import useLogout from '../../hooks/useLogout/index.js';
import { GET_USER } from '../hooks/useCheckCC/constants/GqlQueries';

const Input = styled(TextInput)`
width: 335px;
`


//todo on dashboard check if the cc information was given,if not then show this form again
//todo account display for when a trial is remaining and for when trial has finished
const CollectCC = props => {
    const [showInvalidCC, setShowInvalidCC] = useState(false)

    const [cvc, setCvc] = useState('')
    const [expiry, setExpiry] = useState('')
    const [zipcode, setZipCode] = useState('')
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [discountcode, setDiscountCode] = useState('')

    const cvcErrorRef = useRef(true)
    const expiryErrorRef = useRef(true)
    const numberErrorRef = useRef(true)


    const logout = useLogout()
    const history = useHistory()

    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
    } = usePaymentInputs();


    const [sendCC, { error, loading }] = useMutation(SEND_CC, {
        onError: (e) => console.log(e),
        update: (cache, { data }) => {
            console.log('begin update')

            try {

                const { user } = cache.readQuery({
                    query: GET_USER
                })

                const newUser = {showCC: false, __typename:user.__typename}

                cache.writeQuery({
                    query: GET_USER,
                    data: {
                        user: newUser
                    }
                })

            } catch (e) {
                console.log({ e })
            }

            // history.replace('/onboard')
            history.replace('/')
        }
    })

    const handleFormSubmit = () => {
        setShowInvalidCC(false)

        let error = false

        if (!zipcode.trim() || !name.trim() || cvcErrorRef.current || expiryErrorRef.current || numberErrorRef.current) {
            error = true
        }

        if (error) {
            setShowInvalidCC(true)
        }

        if (!error) {
            sendCC({
                variables: {
                    cvc, expiry, name, number, zipcode, discountcode
                }
            })
        }
    }

    return (
        <GrayDiv tailwind='min-h-screen w-full'>
            <Container
                tailwind='flex flex-col justify-evenly items-center min-h-screen '
            >


                <Button
                    size='extra-small'
                    variant='outline'
                    tailwind='absolute right-0 top-0 m-8'
                    onClick={() => logout()}
                >Logout</Button>



                <Card tailwind='p-4 py-10 max-w-lg w-full flex flex-col items-center justify-center max-w-xl m-4 p-10 bg-white rounded shadow-xl'>
                    <div className='mb-2 md:px-4'>

                        <Text tailwind='text-3xl mb-6 font-medium'>Billing Details</Text>

                        <Text tailwind='font-xl'>Please enter billing details to get a 14-day trial of Refmonkey for free and $49/month after trial. You can cancel anytime.</Text>

                    </div>

                    <ErrorAlert
                        tailwind='my-4'
                        isShown={error}
                    />

                    <Alert
                        content='Invalid Credit Card'
                        variant='warning'
                        tailwind='mb-2'
                        isShown={showInvalidCC} />



                    <Form
                        tailwind='flex flex-col'
                        onSubmit={handleFormSubmit}>

                        <Text tailwind='text-m self-start mt-8'>Full Name</Text>
                        <Input
                            tailwind='py-2 mb-8 px-3'
                            padding
                            name="name"
                            placeholder="Full Name"
                            onChange={value => setName(value)}
                        />

                        <Text tailwind='text-m self-start'>ZIP Code</Text>
                        <Input
                            tailwind='py-2 mb-8 px-3'
                            padding
                            type='text'
                            name="name"
                            placeholder="ZIP Code"
                            onChange={value => setZipCode(value)}
                        />

                        <Text tailwind='text-m self-start'>Card</Text>
                        <PaymentInputsWrapper {...wrapperProps}>
                            <svg {...getCardImageProps({ images })} />
                            <input {...getCardNumberProps({ onChange: (e) => setNumber(e.target.value), onError: (error) => numberErrorRef.current = error })} />
                            <input {...getExpiryDateProps({ onChange: e => setExpiry(e.target.value), onError: (error) => expiryErrorRef.current = error })} />
                            <input {...getCVCProps({ onChange: (e) => setCvc(e.target.value), onError: (error) => cvcErrorRef.current = error })} />
                        </PaymentInputsWrapper>

                        <Text tailwind='text-m self-start mt-8'>Discount Code (optional)</Text>
                        <Input
                            tailwind='py-2 px-3'
                            padding
                            name="name"
                            placeholder="Discount Code"
                            onChange={value => setDiscountCode(value)}
                        />

                        <div className='w-full flex flex-row-reverse'>
                            <Button
                                loading={loading}
                                tailwind='mt-10'
                                typeSubmit>
                                Start using Refmonkey
                        </Button>
                        </div>
                    </Form>


                </Card>

                {/**
 *

                <div className='flex flex-col md:grid md:grid-cols-2 px-6 md:px-12 lg:px-16  gap-16 my-24'>


                    <img
                        className='rounded-lg '
                        src={Testimonial2PNG} />

                    <img
                        className='rounded-lg '
                        src={Testimonial1PNG} />


                </div>

                */}
            </Container>
        </GrayDiv>
    );
};

//todo on dashboard check if the cc information was given,if not then show this form again
CollectCC.propTypes = {

};

//todo on dashboard check if the cc information was given,if not then show this form again
export default CollectCC;
