import React,{useState} from 'react';
import PropTypes from 'prop-types';
import SetupButton from 'app/setup/components/button'
import AccessToken from 'app/setup/components/access_token'
import Button from 'components/button'
import ZapierPNG from 'images/zapier.png'

const Integrations = props => {
    const [showAccessToken, setShowAccessToken] = useState(false)


    return (
        <div>
        <div className={`grid grid-cols-1 md:grid-cols-3 gap-10  py-8`}>
        <SetupButton
                            png
                            onClick={() => window.location.href = 'https://zapier.com/apps/refMonkey'}
                            image={
                                <img src={ZapierPNG} />
                            }
                        >Zapier</SetupButton>
        </div>

<div className='h-32 flex items-center'>
{showAccessToken ?
    <AccessToken />
    :
    <Button
        variant='secondary'
        tailwind={`w-full md:w-auto`}
        onClick={() => setShowAccessToken(true)}
    >Get Access Token</Button>
}
</div>
</div>
    );
};

Integrations.propTypes = {
    
};

export default Integrations;