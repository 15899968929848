import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from '../../../setup/components/count-item'
import CodeBox from 'components/code-box'
import GrayCode from 'components/code-box/gray-code'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import Button from 'components/button'
import Text from 'components/text'
import { HashLink } from 'react-router-hash-link';

const Ecwid = ({ mobileView }) => {
    const match = useRouteMatch()
    const history = useHistory()

    return (
        <div className='w-full'>
            <Heading>Integrate with Ecwid</Heading>

            <CountItem
                number='1'>
                log in to your dashboard, go to <b>‘Website’ {'>'} ‘SEO’</b> {'>'} Paste the following code in the box under the title <b>‘Header meta tags and site verification’.</b> </CountItem>

            <CodeBox>{`<script src="https://cdn.refmonkey.com/ecwid.js"></script>
<script type="text/javascript">window.refMonkeyClient = RefMonkey.client(${'YOUR_ACCESS_TOKEN'}); </script>`}
            </CodeBox>

            <CountItem
                number='2'>
                Hit save.
            </CountItem>

            <CountItem
                number='3'>
                from your Ecwid dashboard, go to <b>‘Settings’ {'>'} ‘General’ {'>'} ‘Cart {'&'} Checkout’.</b>
            </CountItem>

            <CountItem
                number='4'>
                Scroll down to ‘<b>Checkout Settings’</b>, option <b>‘Custom tracking code on Order Confirmation page’.</b>
            </CountItem>

            <CountItem
                number='5'>
                Swith the toggle to <b>‘Enabled’</b> and click on <b>‘Edit Code’.</b>
            </CountItem>

            <CountItem
                number='6'>
                Paste the following code in the box:
            </CountItem>

            <CodeBox>{`<script src="https://cdn.refmonkey.com/ecwid.js"></script>
<script type="text/javascript">window.refMonkeyClient = RefMonkey.client(${'YOUR_ACCESS_TOKEN'}); </script>
<script type="text/javascript">
window.refMonkeyClient.purchase('%order_id%',%order_subtotal%);
</script>
`}
            </CodeBox>

            <CountItem
                number='7'>
                Hit save and you are all done!
            </CountItem>

        </div>
    );
};

Ecwid.propTypes = {

};

export default Ecwid;