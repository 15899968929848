import React from "react";
import styled from "styled-components";
import Text from "../../../components/text";
import Dialog from "../../../components/dialog";
import Loading from '../../../components/loading';
import ErrorAlert from '../../../components/alert/error';

const AnimationWrapper = styled.div`
	svg {
		width: 100px;
		display: block;
		margin: 40px auto 0;
	}

	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;
		&.circle {
			-webkit-animation: dash 0.9s ease-in-out;
			animation: dash 0.9s ease-in-out;
		}
		&.line {
			stroke-dashoffset: 1000;
			-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
			animation: dash 0.9s 0.35s ease-in-out forwards;
		}
		&.check {
			stroke-dashoffset: -100;
			-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
			animation: dash-check 0.9s 0.35s ease-in-out forwards;
		}
	}

	p {
		text-align: center;
		margin: 20px 0 60px;
		font-size: 1.25em;
		&.success {
			color: #73af55;
		}
		&.error {
			color: #d06079;
		}
	}

	@-webkit-keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}
		100% {
			stroke-dashoffset: 0;
		}
	}

	@-webkit-keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}
		100% {
			stroke-dashoffset: 900;
		}
	}

	@keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}
		100% {
			stroke-dashoffset: 900;
		}
	}
`;

function AddReferralSuccess({
	closeAddReferralFormHandler,
	undoConfirm,
	undoConfirmHandler,
	setUndoConfirm,
	undoHandler,
	reverseConversionLoading,
	showUndoError
}) {

	console.log(reverseConversionLoading);
	return (
		<div>
			<Dialog
				onCloseComplete={() => {
					setUndoConfirm(false);
				}}
				shouldCloseOnOverlayClick={false}
				isShown={undoConfirm}
				title="Warning!"
				text="Are you sure to remove the affiliate's reward for this referral?"
				onConfirm={undoHandler}
				// variant="normal"
			>

			{/* <div className="h-32  flex flex-col p-10 justify-center items-center">
			<Text>Are you sure to remove the referral you just added?</Text>
			<div className="flex flex-row mt-5 justify-between items-center">
				<button className="p-10 bg-red-500">Cancel</button>
				<button className="p-10 bg-blue-700">Confirm</button>
			</div>
			</div> */}
			
			</Dialog>
			<div className='flex justify-center items-center'>
				<Text type='heading'>Referral Has Been Added!</Text>
			</div>

			<AnimationWrapper>
				<svg
					version='1.1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 130.2 130.2'>
					<circle
						class='path circle'
						fill='none'
						stroke='#73AF55'
						stroke-width='6'
						stroke-miterlimit='10'
						cx='65.1'
						cy='65.1'
						r='62.1'
					/>
					<polyline
						class='path check'
						fill='none'
						stroke='#73AF55'
						stroke-width='6'
						stroke-linecap='round'
						stroke-miterlimit='10'
						points='100.2,40.2 51.5,88.8 29.8,67.5 '
					/>
				</svg>
			</AnimationWrapper>
			
			<div className="mt-10">
			<ErrorAlert isShown={showUndoError} variant="error"/>
			</div>

			<div className='flex flex-col mt-10'>
				<div className='mb-2'>
					<button
						className={`w-full ${reverseConversionLoading && 'cursor-default' } focus:outline-none text-black flex justify-center bg-gray-300 py-3 rounded-lg items-center ml-2 md:mr-4 lg:mr-6`}
						onClick={undoConfirmHandler}
						disabled={reverseConversionLoading}
						size='xl'>
						{reverseConversionLoading ? <Loading/> : 'Remove Reward'}
					</button>
				</div>

				<button
					className='w-full focus:outline-none text-white flex justify-center bg-red-700 py-3 rounded-lg items-center ml-2 md:mr-4 lg:mr-6'
					onClick={closeAddReferralFormHandler}
					size='xl'>
					Close
				</button>
			</div>
		</div>
	);
}

export default AddReferralSuccess;
