import React from 'react';

import Request from 'components/api/js-request'
import ParamItem from 'components/api/param-item'

const DocRequest = props => {
    return (
        <Request
            id="get-referrals"
            title='Get referrals'
            description='Get the referrals of an advocate/affiliate'
                 tabs={[{
                    title: 'sync',
                    script: true,
                    code:` 
                    window.refMonkeyClient.getReferrals(identifier, (success, referrals)=>{ 
                     //
                    })
             `,
             inputParams:  <>
             <ParamItem
                 required={true}
                 title='identifier'
                 type='string'
                 content={`The advocate/affiliate username that was previously created. This can be an email address, username or id`}
             />

             <ParamItem
                 required={false}
                 title='callback'
                 type='function'
                 content={`Callback, to be called after completion.`}
                 childItems={
                     <>
                         <ParamItem
                             title='success'
                             type='boolean'
                             content={`Indicates the outcome of the function call.`}
                         />

                         <ParamItem
                             title='referrals'
                             type='object'
                             content='Array of an advocates referrals'
                             childItems={
                                 <>
                                     <ParamItem
                                         title='name'
                                         type='string'
                                         content={`The name of the user in your system`}
                                     />
                                     
                                     <ParamItem
                                         title='identifier'
                                         type='string'
                                         content={`A unique identifier for the user in your system.`}
                                     />

                                     <ParamItem
                                         title='referredBy'
                                         type='string'
                                         content={`A unique identifier for the advocate that referred the referral`}
                                     />

                                 </>
                             }
                         />
                     </>
                 }
             />
         </>
                }, 
                {
                    title: 'async',
                    script: false,
                    code: ` 
                    const response = await window.refMonkeyClient.getReferrals(identifier)
             `,
             inputParams: <>
             <ParamItem
                 required={true}
                 title='identifier'
                 type='string'
                 content={`A unique identifier for the user in your system. This can be an email address, username or id`}
             />

         </>,
         outputParams:  
                 <>
                     <ParamItem
                         title='success'
                         type='boolean'
                         content={`Indicates the outcome of the function call.`}
                     />

                     <ParamItem
                         title='referrals'
                         type='object'
                         content='Array of an advocates referrals'
                         childItems={
                             <>
                                 <ParamItem
                                     title='name'
                                     type='string'
                                     content={`The name of the user in your system`}
                                 />
                                 
                                 <ParamItem
                                     title='identifier'
                                     type='string'
                                     content={`A unique identifier for the user in your system.`}
                                 />

                                 <ParamItem
                                     title='referredBy'
                                     type='string'
                                     content={`A unique identifier for the advocate that referred the referral`}
                                 />

                             </>
                         }
                     />
                 </>
                }]}
            
        />
    );
};

DocRequest.propTypes = {

};

export default DocRequest;