import { gql } from 'apollo-boost'

export const GET_BILLING = gql`
    query{
        billing{
            renews
            price
            manualBillingUpgrade
            pricePlan
        }
    }
`

export const UNSUBSCRIBE = gql`
        mutation{
            unsubscribe
        }
`

export const SHOPIFY_YEARLY_PURCHASE = gql`
mutation{
  shopifyYearlyPurchase
}
`

export const SHOPIFY_MONTHLY_PURCHASE = gql`
mutation{
  shopifyMonthlyPurchase
}
`