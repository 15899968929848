import React from 'react';
import Text from 'components/text'

const PrivacyPolicy = () => {
    const headingType = 'heading-small'
    const contentType = 'text'
    const contentTailwind = ''
    const headingTailWind = ''

    const appName = 'RefMonkey'

    return (
        <div>
            <div className='w-full py-6 px-8 border-b border-gray-300 mb-6'>
                <Text type='heading'> Privacy policy</Text>
            </div>
            <div className='p-8'>

                <Text type={contentType} tailwind={contentTailwind}>Your privacy is critically important to us. We have a few fundamental principles: We are thoughtful about the personal information we ask you to provide and 
                the personal information that we collect about you through the operation of our services. We store personal information for only as long as we have a reason to keep it. We aim to make it as simple as possible
                 for you to control what information is shared publicly (or kept private), indexed by search engines, and permanently deleted. We aim for full transparency on how we gather, use, and share your personal information.
           </Text>
                <Text type={headingType} tailwind={headingTailWind}>1. General information</Text>
                <Text type={contentType} tailwind={contentTailwind}>This policy sets out our privacy practices and explains how we handle the information we collect when you visit and use our sites, services, mobile applications,
                 products, and content (“Services”).
</Text>
                <Text type={headingType} tailwind={headingTailWind}>2. What we may collect</Text>
                <Text type={contentType} tailwind={contentTailwind}>We collect information about what {appName} pages you access, information about your mobile device (such as device or browser type), information you send us
                 (such as an email address used to register or communicate with us), and referral information.

                When you use our Services, we may collect and store your Internet Protocol address. We may use this information to fight spam and other abuse; to personalize our Services; or to generate aggregate,
                 non-identifying information about how people use our Services.

                When you create your {appName} account, and authenticate via a third-party service like Twitter or Google, we may collect, store, and periodically update the profile information associated with that third-party account.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>3. Email from {appName}</Text>
                <Text type={contentType} tailwind={contentTailwind}>Sometimes we’ll send administrative emails about account or service changes, or new policies. You can’t opt out of them. You can always opt out of 
                non-administrative emails such as thread notifications.

                We won’t email you to ask for your password or other account information. If you receive such an email, send it to us so we can investigate.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>4. Disclosure of your information</Text>
                <Text type={contentType} tailwind={contentTailwind}>As a rule, we don’t share your personal information outside the company. We won’t sell your personal information.

                We may share your personal information with third parties in limited circumstances, including: (1) with your consent; (2) to a vendor or partner who meets our data protection standards; or
                 (3) when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process.

                If we’re going to share your information in response to legal process, we’ll give you advance notice so you can challenge it (for example by seeking court intervention), 
                unless we’re prohibited from doing so by law or court order. We will object to requests for information about users of our site that we believe to be improper.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>5. Modifying your personal information or deleting your account</Text>
                <Text type={contentType} tailwind={contentTailwind}>If you have a {appName} account, you can access and modify your personal information, or delete your account here. If you delete your account,
                 your account and content may be unrecoverable.

                To protect information from accidental or malicious destruction, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>6. Your data protection rights</Text>
                <Text type={contentType} tailwind={contentTailwind}>In accordance with the European General Data Protection Regulation (GDPR), you have a right of access, correction, and removal of your personal data which
                 you may exercise by sending us an email request . Your requests will be processed within 30 days. We may require that your request is accompanied by a photocopy of proof of identity or authority.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>7. Cookies</Text>
                <Text type={contentType} tailwind={contentTailwind}>We use cookies and similar technologies such as pixels and local storage to recognize you when you return to our Services. We use them in various ways,
                 for example to log you in, remember your preferences, evaluate email effectiveness, and personalize information.

                Some third-party services that we use, such as Google Analytics, may place their own cookies in your browser. This Privacy Policy covers use of cookies by {appName} only and not the use of cookies by third parties.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>8. Third party services</Text>
                <Text type={contentType} tailwind={contentTailwind}>{appName} uses third-party vendors and hosting partners, for hardware, software, networking, storage, and related technology we need to run {appName}.
</Text>
                <Text type={headingType} tailwind={headingTailWind}>9. Security</Text>
                <Text type={contentType} tailwind={contentTailwind}>We use encryption (HTTPS/TLS) to protect data transmitted to and from our site. While no online service is 100% secure, we work very hard to protect information 
                about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. You use the Service at your own risk, and you’re responsible for taking reasonable measures to secure 
                your account (like using a strong password).
</Text>
                <Text type={headingType} tailwind={headingTailWind}>10. Changes</Text>
                <Text type={contentType} tailwind={contentTailwind}>Although most changes are likely to be minor, we may change our Privacy Policy from time to time. {appName} encourages visitors to frequently check this page for
                 any changes to its Privacy Policy. Your continued use of the Services after any change in this Privacy Policy will constitute your consent to such change.

</Text>
            </div >
        </div>
    );
};

export default PrivacyPolicy;