import React from 'react';
import Text from 'components/text'
import { HTTP_URL } from 'constants/index.js'
import { HashLink as Link } from 'react-router-hash-link';

const RewardHelp = () => {

    const textType = 'text-small'

    const textTailwind = 'text-center'

    return (
        <div className='md:mx-16 flex flex-col items-center justify-center'>
            <Text
                type={textType}
                tailwind={`${textTailwind}`}
            >Rewards are flexible, allowing you to reward advocates and referrals with credits, modules, add-ons and discounts etc. For example; 10GB additional storage or 15% discount.</Text>

            <Text
                type={textType}
                color
                tailwind={`${textTailwind} mt-12 text-gray-800 font-medium`}>Status of a Reward</Text>
            <Text
                type={textType}
                tailwind={`${textTailwind}`}
            >There are two states for a reward:</Text>

            <ul className='mx-0 my-4'>
                <li><Text
                    tailwind={`${textTailwind}`}
                    type={textType}
                >Pending</Text></li>
                <li
                ><Text
                    tailwind={`${textTailwind}`}
                    type={textType}
                >Redeemed</Text></li>
            </ul>

            <Text
                tailwind={`${textTailwind}`}
                type={textType}
            >After a reward has been applied to a user's account, i.e - 1000 credits loaded into user's account, The reward is then redeemed either by the refmonkey <Link to={`setup/js/docs#redeem-reward`}><span className='text-blue-600 cursor-pointer'>js library</span></Link>, refmonkey <Link to={`setup/api/docs#redeem-advocate-reward`}><span className='text-blue-600 cursor-pointer'>API</span></Link> or through the Pending Rewards dashboard page.</Text>

            <Text
                tailwind={`${textTailwind}`}
                className='my-4'
                type={textType}
            >Redeemed rewards appear here. </Text>
        </div>
    );
};

export default RewardHelp;
