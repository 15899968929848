import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Text from "components/text";
import Button from "components/button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_PENDING_REWARDS, REDEEM_REWARD } from "../../constants/GqlQueries";
import styled from "styled-components";
import ErrorAlert from "components/alert/error";
import { GET_REDEEMED_REWARDS } from "../../../redeemed-rewards/constants/GqlQueries";

const Wrapper = styled.div`
  background-color: ${({ theme, isGray }) => (isGray ? theme.gray50 : "white")};
`;

const PendingRewardItem = (props) => {
    const { identifier, date, rewardName, rewardValue, type, units, isGray,
        name, ids,referralIdentifier } = props

  const [showError, setShowError] = useState(false);

  const [rewardTitle, setRewardTitle] = useState("");

  useEffect(() => {
    if (rewardValue === "refmonkey_custom_reward_value") {
      setRewardTitle(rewardName);
    } else {
      setRewardTitle(`${rewardValue} ${rewardName}`);
    }
  }, [rewardName, rewardValue]);

  const [redeemReward, { loading: redeemLoading }] = useMutation(
    REDEEM_REWARD,
    {
      onError: (error) => setShowError(true),
      update: (cache, { data }) => {
        if (data && data.redeemReward) {
          const pendingRewards = cache.readQuery({
            query: GET_PENDING_REWARDS,
          });

          const newRewards = pendingRewards.pendingRewards.filter((item) => {
            if (
              identifier === item.identifier &&
              rewardName === item.rewardName &&
              rewardValue === item.rewardValue
            ) {
              return false;
            } else {
              return true;
            }
          });

          cache.writeQuery({
            query: GET_PENDING_REWARDS,
            data: { pendingRewards: newRewards },
          });

          //update redeemed rewards
          const redeemedRewards = cache.readQuery({
            query: GET_REDEEMED_REWARDS,
          });

          const redeemDate = new Date();

          const newArr = [];

          for (let i = 0; i < units; i++) {
            const obj = { ...props };
            obj.redeemDate = redeemDate.toLocaleDateString();

            newArr.push(obj);
          }

          cache.writeQuery({
            query: GET_REDEEMED_REWARDS,
            data: {
              redeemedRewards: redeemedRewards.redeemedRewards
                ? [...newArr, ...redeemedRewards.redeemedRewards]
                : newArr,
            },
          });
        } else {
          setShowError(true);
        }
      },
    }
  );

  const handleRedeem = () => {
    setShowError(false);

    redeemReward({
      variables: {
        identifier,
        type,
        ids,
      },
    });
  };

  console.log("STEVENDEBUG PendingRewardItem props ", props);

  return (
    <div>
      <ErrorAlert isShown={showError} />

            <Wrapper
                isGray={isGray}
                className='grid grid-cols-5 py-4 px-6'>

<Text tailwind='col-span-1 break-all'>{name ? <div className='flex flex-col'><span>{name}</span><span className='text-gray-500 text-xs'> {identifier}</span></div> : identifier}</Text>

<Text tailwind='col-span-1 break-all px-1'>{referralIdentifier}</Text>
<Text>{date}</Text>
                <Text>{rewardTitle}  {units > 1 ? `(x ${units})` : ''}</Text>
                <Button
                    onClick={handleRedeem}
                    loading={redeemLoading}
                    size='extra-small'
                    variant='outline'>Mark as paid</Button>
            </Wrapper>


        </div>
    );
};

PendingRewardItem.propTypes = {};

export default PendingRewardItem;
