import Cookies from 'universal-cookie';
import { BASE_URL, HTTP_URL, isDev } from '../../../../constants'

const cookies = new Cookies();

if (process.env.NODE_ENV === 'test') {
    cookies.HAS_DOCUMENT_COOKIE = false
}


const getOptions = () => {

    const options = {
        path: '/',
        domain: '.' + BASE_URL,
    }

    const devOptions = {
        path: '/',
    }

    if (isDev) {
        return devOptions
    } else {
        return options
    }
}

const affRefCode = 'affiliate-refcode'
const affDashboardCode = 'affiliate-dashboard-code'

export const isLoggedIn = () => {
    const advocate = getAdvocate()
    if ((advocate.refCode === '0' || !advocate.refCode) || (advocate.dashboardCode === '0' || !advocate.dashboardCode)) {
        return false
    } else {
        return true
    }
}

export const getAdvocate = () => {
    const refCode = cookies.get(affRefCode)
    const dashboardCode = cookies.get(affDashboardCode)

    return { refCode, dashboardCode }
}

export const signin = (refCode, referralDashboardCode) => {
    setRefCode(refCode)
    cookies.set(affDashboardCode, referralDashboardCode, getOptions())

}

export const setRefCode = (refCode) => {
    cookies.set(affRefCode, refCode, getOptions())
}

export const signout = () => {
    cookies.set(affRefCode, '0', getOptions())
    cookies.set(affDashboardCode, '0', getOptions())

}