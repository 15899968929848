import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/textinput'
import Button from 'components/button'
import Text from 'components/text'
import Toggle from 'components/toggle'
import Form from 'components/form'
import ErrorAlert from 'components/alert/error'
import Alert from 'components/alert'
import Loading from 'components/loading'
import FieldInput from 'components/fieldinput'
import Option from '../components/option';
import Tab from 'components/tab'
import AffUrlOption from '../components/aff-url-option';
import General from '../tabs/general';
import Reward from '../tabs/reward';
import Notifications from '../tabs/notifications';
import AffDashboard from '../tabs/aff-dashboard';
import Alerts from '../components/alerts';


const tabs = ['General', 'Reward', 'Affiliate Dashboard','Notifications']

const DefaultSettings = ({ saveText, saveButtonTailwind, states, setStates,
    showTabs = true, manualSelectedTabIndex, disableStates, onFormSubmit, loading, data }) => {

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

    useEffect(() => {
        disableStates()
    }, [tabSelectedIndex])


    useEffect(() => {
        if (manualSelectedTabIndex) {
            setTabSelectedIndex(manualSelectedTabIndex)
        }
    }, [manualSelectedTabIndex])





    return (
        <div className='w-full h-full'>
            {states.showError &&
                <ErrorAlert
                    isShown={true}
                />
            }

            {loading.getSettingsLoading &&
                <Loading />

            }
            {data &&
                <div className='md:px-6'>
                    {showTabs &&
                        <div className='flex mt-2'>
                            {tabs.map((value, index) => (
                                <Tab
                                    tailwind='px-8'
                                    id={index}
                                    variant='underline'
                                    onClick={() => {
                                        setTabSelectedIndex(index)
                                    }}
                                    isSelected={index === tabSelectedIndex}
                                >{value}</Tab>
                            ))}
                        </div>
                    }
                    <div className='w-full h-px border-t border-gray-200' />

                    <Form onSubmit={onFormSubmit}>

                        {tabSelectedIndex === 0 &&
                            <General
                                states={states}
                                setStates={setStates}
                            />
                        }
                        {tabSelectedIndex === 1 &&
                            <Reward
                                states={states}
                                setStates={setStates}
                            />
                        }

                        {tabSelectedIndex === 2 &&
                            <AffDashboard
                                states={states}
                                setStates={setStates}
                            />
                        }

                         {tabSelectedIndex === 3 &&
                            <Notifications
                                states={states}
                                setStates={setStates}
                            />
                        }


                        <Alerts
                            states={states}
                        />

                        <div className='w-full flex justify-end'>
                            <Button
                                typeSubmit
                                loading={loading.setSettingsLoading}
                                tailwind={`my-6 ${saveButtonTailwind}`}>{saveText}</Button>
                        </div>
                    </Form>

                </div >
            }
        </div>
    );
};

DefaultSettings.propTypes = {

};

export default DefaultSettings;