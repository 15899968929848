import React, { useState, useEffect } from "react";
import Card from "components/card";
import Text from "components/text";
import { useQuery } from "@apollo/react-hooks";
import ErrorAlert from "components/alert/error";
import Loading from "components/loading";
import emptyIllustrationPNG from "images/emptyIllustration.png";
import SortText from "components/sort-text";
import Pagination from "components/pagination";
import { TRANSACTIONS } from "./constants/GqlQueries";
import styled from "styled-components";
import Referral from "./components/referral";
import { getUser } from "utils/authUtil";
import Cvs from "./components/cvs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Image = styled.img`
  opacity: 0.04;
`;

const CardWrapper = styled(Card)`
  min-width: 28rem;
`;

const SORT_BY_DATE = 1;
const SORT_BY_IDENTIFIER = 2;
const SORT_BY_REFERREDBY = 3;
const SORT_BY_REWARDVALUE = 4;
const SORT_BY_STATUS = 5;

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const user = getUser();
  const { loading, data, error } = useQuery(TRANSACTIONS, {
    variables: {
      email: user.email,
    },
  });

  console.log("STEVENDEBUG TRANSACTIONS data ", data);

  const [identifierAscending, setIdentifierAscending] = useState(true);
  const [referredByAscending, setReferredByAscending] = useState(true);
  const [statusAscending, setStatusAscending] = useState(true);
  const [dateAscending, setDateAscending] = useState(true);
  const [rewardValueAscending, setRewardValueAscending] = useState(true);
  const [showEditButton, setShowEditButton] = useState(true);
  const [sortBy, setSortBy] = useState(SORT_BY_DATE);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(50);
  const [showPagination, setShowPagination] = useState(false);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = transactions.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const dateArray =
    data &&
    data.transactions &&
    data.transactions.map(
      (element) =>
        new Date(parseInt(element._id.toString().substring(0, 8), 16) * 1000)
    );
  const furthestDate = dateArray && Math.min(...dateArray);
  const nearestDate = dateArray && Math.max(...dateArray);

  useEffect(() => {
    let filteredData = [];
    let addDateFilteredData = [];
    if (data && data.transactions) {
      if (startDate === "" && endDate === "") {
        filteredData = data.transactions;
      } else if (startDate === "") {
        filteredData = data.transactions.filter(
          (item) => new Date(item.date) <= new Date(endDate)
        );
      } else if (endDate === "") {
        filteredData = data.transactions.filter(
          (item) => new Date(item.date) >= new Date(startDate)
        );
      } else {
        filteredData = data.transactions.filter(
          (item) =>
            new Date(item.date) >= new Date(startDate) &&
            new Date(item.date) <= new Date(endDate)
        );
      }
      addDateFilteredData = filteredData.map((element) => {
        return { ...element, date: element.date };
      });
    
      setTransactions(addDateFilteredData);
      sortByDate(addDateFilteredData)
      setShowPagination(filteredData.length / postsPerPage > 1);
    }
  }, [data, startDate, endDate]);

  function sortByIdentifier() {
    setSortBy(SORT_BY_IDENTIFIER);

    const sortedTransactions = transactions.sort((a, b) => {
      if (identifierAscending) {
        return a.identifier.localeCompare(b.identifier);
      } else {
        return b.identifier.localeCompare(a.identifier);
      }
    });
    setTransactions([...sortedTransactions]);
    setIdentifierAscending(!identifierAscending);
  }

  function sortByDate(customTransactions) {

    setSortBy(SORT_BY_DATE);

    const items = customTransactions ? customTransactions : transactions
    const sortedTransactions = items.sort((a, b) => {
      if (dateAscending) {
        return new Date(a.date) - new Date(b.date);
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });
    setTransactions([...sortedTransactions]);
    setDateAscending(!dateAscending);
  }

  function sortByReferredBy() {
    setSortBy(SORT_BY_REFERREDBY);

    const sortedTransactions = transactions.sort((a, b) => {
      if (referredByAscending) {
        return a.referredBy.localeCompare(b.referredBy);
      } else {
        return b.referredBy.localeCompare(a.referredBy);
      }
    });
    setTransactions([...sortedTransactions]);
    setReferredByAscending(!referredByAscending);
  }

  function sortByRewardValue() {
    setSortBy(SORT_BY_REWARDVALUE);

    const sortedTransactions = transactions.sort((a, b) => {
      if (rewardValueAscending) {
        return (a.rewardValue ? a.rewardValue : "0").localeCompare(
          b.rewardValue ? b.rewardValue : "0"
        );
      } else {
        return (b.rewardValue ? b.rewardValue : "0").localeCompare(
          a.rewardValue ? a.rewardValue : "0"
        );
      }
    });
    setTransactions([...sortedTransactions]);
    setRewardValueAscending(!rewardValueAscending);
  }

  function sortByStatus() {
    setSortBy(SORT_BY_STATUS);

    const sortedTransactions = transactions.sort((a, b) => {
      if (statusAscending) {
        return a.status.localeCompare(b.status);
      } else {
        return b.status.localeCompare(a.status);
      }
    });
    setTransactions([...sortedTransactions]);
    setStatusAscending(!statusAscending);
  }
  return (
    <div className="h-full">
      <p className="mb-4 ml-2">
        Transactions are all the referrals that occurred in the account.
      </p>
      
      {/** 
      <div className="w-64">
        <Cvs data={transactions} />
      </div>*/}

       {data &&
        (transactions.length > 0 ? (
          <>
            <div className="flex">
              <label className="mx-2 ">Start date: </label>
              <DatePicker
                className="border-solid border-2 border-black-500"
                selected={startDate}
                minDate={new Date(furthestDate)}
                maxDate={endDate ? new Date(endDate) : new Date(nearestDate)}
                onChange={(date) => setStartDate(date)}
              />
              <label className="mx-2 ">End date: </label>
              <DatePicker
                className="border-solid border-2 border-black-500"
                selected={endDate}
                minDate={
                  startDate ? new Date(startDate) : new Date(furthestDate)
                }
                maxDate={new Date(nearestDate)}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div className="w-64">
              <Cvs data={transactions} />
            </div>
          </>
        ) : null)} 

      {error && <ErrorAlert isShown={true} />}

      {loading && <Loading />}

      {data &&
        (transactions.length > 0 ? (
          <CardWrapper tailwind="md:mx-6 lg:mx-6 my-6">
            <div className="border-b border-gray-200">
              <div
                className={`grid grid-cols-12 py-4 px-6
            mr-12`}
              >
                <SortText
                  isAscending={dateAscending}
                  sort={sortBy === SORT_BY_DATE}
                  onClick={sortByDate}
                  tailwind="col-span-1"
                >
                  Refer Date
                </SortText>
                <SortText
                  isAscending={identifierAscending}
                  sort={sortBy === SORT_BY_IDENTIFIER}
                  onClick={sortByIdentifier}
                  tailwind="col-span-2"
                >
                  Identifier
                </SortText>
                <SortText
                  isAscending={referredByAscending}
                  sort={sortBy === SORT_BY_REFERREDBY}
                  onClick={sortByReferredBy}
                  tailwind="col-span-2"
                >
                  Referred By
                </SortText>
                <SortText
                  isAscending={rewardValueAscending}
                  sort={sortBy === SORT_BY_REWARDVALUE}
                  onClick={sortByRewardValue}
                  tailwind="col-span-2 text-center"
                >
                  Affiliate Reward Value
                </SortText>

                <div className="col-span-1"></div>
                <SortText
                  isAscending={rewardValueAscending}
                  sort={sortBy === SORT_BY_STATUS}
                  onClick={sortByStatus}
                  tailwind="col-span-2 text-center"
                >
                  Status
                </SortText>

                <div className="col-span-1">{/** edit button */}</div>
              </div>
            </div>

            {currentPosts.map((value, index) => {
              return (
                <Referral
                  index
                  showEditButton={showEditButton}
                  setShowEditButton={setShowEditButton}
                  isGray={index % 2 === 0}
                  referral={value}
                />
              );
            })}

            {showPagination && (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={transactions.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </CardWrapper>
        ) : (
          <div className="relative w-full h-full flex flex-col justify-center items-center">
            <div className="my-8 relative z-10">
              <Text tailwind="w-full text-center font-medium text-lg my-6">
                No transaction yet.
              </Text>
            </div>

            <Image
              alt="No transaction yet."
              className="z-0 absolute w-full h-full object-cover"
              src={emptyIllustrationPNG}
            />
          </div>
        ))}
    </div>
  );
};

export default Transactions;
