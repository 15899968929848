import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/text'
import TextInput from 'components/textinput'
import Option from '../option';
import Button from 'components/button'
import DropDown from 'components/dropdown'
import DropDownItem from 'components/dropdown/dropdownitem'

const RewardOption = ({ title, content, toggleValue, onToggle, rewardName, rewardValue,
    onNameChange, onValueChange, onTypeChange, rewardType, showPercentOption = true,
    percentTypeHint, otherTypeHint, hasToggle = true }) => {

    const [rewardTypeTitle, setRewardTypeTitle] = useState('')

    useEffect(() => {

        switch (rewardType) {
            case 2:
                setRewardTypeTitle('Other')
                break
            case 1:
                setRewardTypeTitle('% of sale')

                break
            default:
                setRewardTypeTitle('Monetary')

                break
        }

    }, [rewardType])

    return (
        <Option
            hasToggle={hasToggle}
            toggleValue={toggleValue}
            onToggle={onToggle}
            title={title}
            content={content}
        >
            <div className='flex'>
                <div className={`grid gap-4 grid-cols-3`}>

                    <Text
                        textSize
                        tailwind='text-gray-700 text-sm  font-semibold'>
                        {(rewardType === 0 || !rewardType) ? 'Currency' : 'Reward Name'}
                    </Text>



                    <Text
                        textSize
                        tailwind='text-gray-700 text-sm  font-semibold'>
                        Amount
                    </Text>


                    <div />



                    <TextInput
                        disabled={rewardType === 1}
                        value={rewardName}
                        onChange={(value) => onNameChange(value)}
                    />


                    {rewardType === 2 ?
                        <TextInput
                            tailwind=''
                            value={rewardValue}
                            onChange={(value) => onValueChange(value)}
                        />
                        :
                        <TextInput
                            min={1}
                            max={rewardType === 1 ? 100 : 20000}
                            type='number'
                            tailwind=''
                            value={rewardValue}
                            onChange={(value) => onValueChange(value)}
                        />
                    }


                    <DropDown

                        content={
                            showPercentOption ?
                                <>
                                    <DropDownItem
                                        onClick={() => {
                                            onTypeChange(0)
                                            onNameChange('USD')
                                        }}
                                    >Monetary</DropDownItem>
                                   
                                   {/**  <DropDownItem
                                        onClick={() => {
                                            onTypeChange(1)
                                            onNameChange('% of sale')
                                        }}
                                    >% of sale</DropDownItem>
                                    */}
                                    <DropDownItem
                                        onClick={() => {
                                            onTypeChange(2)
                                            onNameChange('Tokens')
                                        }
                                        }
                                    >Other</DropDownItem>
                                </>
                                :
                                <>
                                    <DropDownItem
                                        onClick={() => {
                                            onTypeChange(0)
                                            onNameChange('USD')
                                        }}
                                    >Monetary</DropDownItem>
                                    <DropDownItem
                                        onClick={() => {
                                            onTypeChange(2)
                                            onNameChange('Tokens')
                                        }
                                        }
                                    >Other</DropDownItem>
                                </>
                        }
                    >
                        <Button
                            padding
                            tailwind='w-24 py-2'
                            size='small'
                            variant='outline'
                        >{rewardTypeTitle}</Button>
                    </DropDown>
                </div>
            </div>

            {rewardType === 1 &&
                <Text
                    tailwind='my-2'
                    type='text-small'>{percentTypeHint}</Text>

            }
            {rewardType === 2 &&
                <Text
                    tailwind='my-2'
                    type='text-small'>{otherTypeHint}</Text>
            }

        </Option >
    );
};

RewardOption.defaultProps = {
    percentTypeHint: 'Default currency for rewards is USD.',
    otherTypeHint: <p>Reward name can be anything (Ex: <b>Credits, Add-on</b> or <b>Tokens</b>).</p>
}

RewardOption.propTypes = {

};

export default RewardOption;