import React from 'react';
import CodeBox from 'components/code-box'
import { Content, Heading, TopHeading } from 'components/api/text'
import GrayDiv from 'components/gray-div'
import GetAdvocate from './requests/get-advocate'
import GetReferral from './requests/get-referral'
import GetReferralCode from './requests/get-referral-code'
import GetReferrals from './requests/get-referrals'
import NewUser from './requests/new-user'
import NewAdvocate from './requests/new-advocate'
import PendingReward from './requests/pending-reward'
import Purchase from './requests/purchase'
import ReversePurchase from './requests/reverse-purchase'
import RedeemAdvocateReward from './requests/redeem-advocate-reward'
import RedeemReferralReward from './requests/redeem-referral-reward'
import ResponseCodes from './requests/response-codes'
import GetAdvocateEmail from './requests/get-advocate-email'
import Conversion from './requests/conversion'
import ReverseConversion from './requests/reverse-conversion'


const ApiDocs = ({ padding = true }) => {

    return (
        <GrayDiv>

            <div className={`${padding && 'p-8'}`}>

                <TopHeading>Introduction</TopHeading>

                <Content>The RefMonkey API is based around REST. Our API has predictable, resource-oriented URLs, and uses HTTP response codes to indicate API errors. We use built-in HTTP features,
                like HTTP authentication and HTTP verbs, which are understood by off-the-shelf HTTP clients. We support cross-origin resource sharing, allowing you to interact securely with
                our API from a client-side web application (though you should never expose your secret Access Token in any public website’s client-side code). JSON is returned by all API
                  responses, including errors.</Content>

                <Content className='my-t'>RefMonkey API calls are made to the following URL:</Content>
                <CodeBox>
                    https://api.refmonkey.com/
                  </CodeBox>

                <Heading>Authentication</Heading>
                <Content>Authentication with the RefMonkey API is achieved by sending your Access Token in a "Authorization" header of every request:</Content>

                <Content>Authorization: “YOUR_ACCESS_TOKEN”</Content>

                <Content>Example: </Content>
                <CodeBox>{'const instance = axios.create({\n' +
                '      headers: {\n' +
                '        Authorization: "YOUR_ACCESS_TOKEN"\n' +
                '      }\n' +
                '})'}
                </CodeBox>

                <Content>All of your API requests must be made over HTTPS. Plain HTTP calls will fail. API requests without authentication will also fail.</Content>

                <ResponseCodes />
                <Heading>API Methods</Heading>
                
                <Conversion />
                <ReverseConversion />

{/** 
               <NewAdvocate />
               <GetReferralCode />
               <NewUser />
                <Purchase />
                <ReversePurchase />
*/}
                <PendingReward />
                <RedeemAdvocateReward />
                <RedeemReferralReward />
                <GetReferral />
                <GetAdvocate />
                <GetReferrals />
                <GetAdvocateEmail />

            </div>
        </GrayDiv >
    );
};

export default ApiDocs;
