import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from 'components/api/text'
import CountItem from 'app/setup/components/count-item'
import Button from 'components/button'
import screenshot1PNG from 'images/paypal/1.png'
import screenshot2PNG from 'images/paypal/1.png'
import { CSVLink } from "react-csv";
import { useQuery } from '@apollo/react-hooks';
import { GET_CSV_PAYOUT } from '../../constants/GqlQueries';
import Loading from 'components/loading'
import ErrorAlert from 'components/alert/error'



const MassPay = () => {

    const { data, error, loading } = useQuery(GET_CSV_PAYOUT)

    return (
        <div>
            {loading &&
                <Loading />
            }

            {data &&
                <div className='mt-8 flex'>
                    <CSVLink
                        headers={[
                            { label: "PAYOUT_SUMMARY", key: "payout" },
                            { label: data.csvPayout.total, key: "paypalEmail" },
                            { label: data.csvPayout.currency, key: "value" },
                            { label: data.csvPayout.payouts.length, key: "currency" }, //total number of payments
                            { label: "RefMonkey Payout", key: "id" }, //email subject
                            { label: "You have received a payout.", key: "note" } //email subject




                        ]}
                        filename={`pp_payouts_${Date.now()}_refmonkey.csv`}
                        data={data.csvPayout.payouts}>
                        <Button
                            variant='outline'>Download .CSV</Button>
                    </CSVLink>
                </div>
            }

            {error &&
                <ErrorAlert
                    isShown={true} />
            }

            <Heading>Make mass payments via PayPal</Heading>

            <Content><b>Important:</b> only USD is supported. any other reward type should first be redeemed by its own before downloading the mass pay csv file.</Content>

            <Content><b>Note:</b> You will need a 'PayPal for business' account to conduct mass payments via PayPal.</Content>


            <CountItem
                number='1'>
                Download the .CSV file above.
          </CountItem>

            <CountItem
                number='2'>
                Go to your <a target='_blank' className='text-indigo-600 hover:text-indigo-500' href='https://www.paypal.com'>PayPal</a> account.
          </CountItem>

            <CountItem
                number='3'>
                At the top of the page click on ‘Tools’ and from the drop down menu select ‘All tools.’ After you have done this you will end up on this page below.
          </CountItem>

            <CountItem
                number='4'>
                Click on ‘Mass Payments'.
          </CountItem>

            <div className='flex items-center justify-center mb-4'>
                <img src={screenshot1PNG} alt='paypal mass payments' />
            </div>

            <CountItem
                number='5'>
                4. You'll be taken to a page with the option ‘Upload a TXT or CSV file’.
          </CountItem>

            <div className='flex items-center justify-center mb-4'>
                <img src={screenshot2PNG} alt='upload .csv file' />
            </div>

            <Content>You can now upload to PayPal the CSV file you downloaded, and you're all done!</Content>

            <div className='h-16' />
        </div>
    );
};

MassPay.propTypes = {

};

export default MassPay;