import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button'
import GrayDiv from 'components/gray-div'
import Container from 'components/container'
import Card from 'components/card'
import Text from 'components/text'
import TextInput from 'components/textinput'
import { useMutation } from '@apollo/react-hooks';
import { REDEEM_CODE } from './constants/GqlQueries';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import SaastronauticsPNG from 'images/saastronautics.png'
import ErrorAlert from 'components/alert/error'
import Alert from 'components/alert'


const RedeemSaastraunatics = props => {

    const match = useRouteMatch()
    const history = useHistory()
    const [showError, setShowError] = useState(false)
    const [showInvalidCode, setShowInvalidCode] = useState(false)


    const [redeemCode, { data, loading }] = useMutation(REDEEM_CODE, {
        onError: error => setShowError(true)
    })


    const [promoCode, setPromoCode] = useState('')
    const [morePromoCodes, setMorePromoCodes] = useState('')
    const [showAddMoreCodes, setShowAddMoreCodes] = useState(false)

    useEffect(() => {
        if (data) {
            if (data.redeemSaastraunaticsCode) {
                history.push(`${match.path}/complete`)
            } else {
                setShowInvalidCode(true)
            }
        }

    }, [data])

    const handleSubmit = () => {
        setShowError(false)
        setShowInvalidCode(false)

        redeemCode({
            variables: {
                code: promoCode,
                moreCodes: morePromoCodes
            }
        })

    }

    return (
        <GrayDiv tailwind='min-h-screen'>


            <Container
                tailwind='flex flex-col justify-center items-center h-full w-full min-h-screen'
                narrow={true}>


                <Card tailwind='p-8 w-full flex flex-col max-w-lg justify-center items-center'>

                    <img
                        className=''
                        src={SaastronauticsPNG} alt='saastronautics logo' />

                    <Route exact path={`${match.path}/complete`}>
                        <Text
                            tailwind='my-8'
                        >Awesome! Successfully redeemed code.</Text>

                        <Button
                            tailwind='my-4'
                            onClick={() => history.push('/')}>Go to my Dashboard</Button>

                    </Route>

                    <Route exact path={`${match.path}`}>

                        <Text
                            tailwind='mb-4'
                            type='heading-small'>
                            Welcome Saastronaut!
                    </Text>

                        {
                            showError &&
                            <ErrorAlert
                                isShown={true}
                            />
                        }


                        <Alert
                        title="Invalid Code"
                            content="The code you have inserted is invalid. please try a different code."
                            isShown={showInvalidCode}
                        />


                        <Text
                            tailwind='mb-4'>Please enter your promo code to claim your deal.</Text>

                        <TextInput
                            tailwind='my-4 w-full'
                            onChange={value => setPromoCode(value)}
                            value={promoCode}
                            placeholder='Promo Code'
                        />


                        {showAddMoreCodes ?

                            <TextInput
                                tailwind='my-4 w-full'
                                onChange={value => setMorePromoCodes(value)}
                                value={morePromoCodes}
                                placeholder='comma seperated promocodes. i.e DNF-232, DR434'
                            />
                            :
                            <div className='flex w-full justify-left'>
                                <Button
                                    color
                                    hover
                                    size='small'
                                    tailwind='text-indigo-600 hover:text-indigo-500'
                                    onClick={() => setShowAddMoreCodes(true)}
                                    variant='text'>Add more codes</Button>
                            </div>
                        }

                        <Button
                            tailwind='my-4 w-full'
                            loading={loading}
                            onClick={() => handleSubmit()}>Redeem</Button>
                    </Route>
                </Card>

            </Container>
        </GrayDiv>
    );
};

RedeemSaastraunatics.propTypes = {

};

export default RedeemSaastraunatics;