import React,{useState,useEffect} from 'react'
import Option from '../../components/option';
import Text from 'components/text'
import StripeLogo from '../../../../images/StripeLogo.jpg'
import {useQuery,useMutation} from '@apollo/react-hooks'
import Loading from '../../../../components/loading'
import {CONNECT_STRIPE,GET_STRIPE_USERID,DISCONNECT_STRIPE} from '../../constants/GqlQueries'
import {withRouter} from 'react-router-dom'

function ConnectStripe({states,setStates,location,history}) {
    const {data,loading,error} = useQuery(GET_STRIPE_USERID);
    const [connectStripe,connectedResult] = useMutation(CONNECT_STRIPE,{
        onError: (err) => {
            setStates.setError(err);
            history.replace("/settings");
        },
        onCompleted: () => {
            history.replace("/settings");
        },
        refetchQueries: () => {
            return [{ query: GET_STRIPE_USERID }]
        } 
    });
    const [disconnectStripe,disconnectedResult] = useMutation(DISCONNECT_STRIPE, {
        onError: (err) => setStates.setError(err),
        onCompleted: () => {
            history.replace("/settings");
        },
        refetchQueries: () => {
            return [{ query: GET_STRIPE_USERID }]
        }
    })

    useEffect(() => {
      
        if(states.Error && states.Error.graphQLErrors[0].message.indexOf("Error: Authorization code does not exist:") === -1) {
            setStates.setShowStripeErrorMessage(true);
            const timeout = setTimeout(() => setStates.setShowStripeErrorMessage(false),3000)

            return () => clearTimeout(timeout);
        }

        
    },[states.Error])

    useEffect(() => {
        if(location.search && location.search.includes("?scope=read_only&code=ac_")) {
            const code = new URLSearchParams(location.search).get("code");
            
            //TODOS
            //make sure only saves FIRST TIME there is a code, and THE CODE is valid 
            if(data && !data.getStripeUserID && code !== localStorage.getItem("code")) {
                connectStripe({variables: {authCode: code}})
                localStorage.setItem("code",code);
            }            
            
            //send the code to the server using graphql
            //connect the userID with refmonkey secret key
            //save the code in the database. As long as it is stored in the database, the user stripe account is connected to refmonkey.
            //Allow update or cancel connection with stripe to refmonkey
            
            
        }
    },[data])

    const stripeConnectHandler = async (e) => {
        e.preventDefault();
        
        window.location.replace(`${  process.env.NODE_ENV === 'development' ? 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_IS77Xh0AOIKWQx7LstPLCDLN8h59QX90&scope=read_only&redirect_uri=http://app.localhost:3000/settings' : 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_IS77vzUfDRVQtX22gZRD3GMmtUkBVmH8&scope=read_only&redirect_uri=https://refmonkey.com/settings'} `)
    }

    const disconnectStripeHandler = async (e) => {
        e.preventDefault();
        disconnectStripe();
    }

    return (
        <Option
        title='Connect with Stripe'
        content={data && !data.getStripeUserID ? 'Connect your Stripe Account with Refmonkey to  track how much revenue each affiliate generates.' : 'Your Stripe Account is connected to Refmonkey.'}
        >
    {/* add loadings here */}

        {loading || connectedResult.loading || disconnectedResult.loading ? <Loading/> : 
        <button onClick={data && data.getStripeUserID ? disconnectStripeHandler : stripeConnectHandler} 
        className={data && data.getStripeUserID ? "flex items-center focus:outline-none transition duration-300 ease-in-out text-white hover:bg-red-600 bg-red-700 rounded-lg w-22 h-10 pt-3 pb-3 px-3 text-center  mb-3 border border-2 border-none":
        "focus:outline-none w-32 transition duration-300 ease-in-out justfy-center items-center hover:bg-gray-700 hover:text-white bg-gray-100 rounded-xl px-3 py-1 flex flex-row border border-2 border-black"}>
            {data && !data.getStripeUserID && <img className=" rounded-3xl mr-1 h-4" src={StripeLogo} alt=""/>}
             {data && data.getStripeUserID ? 'Disconnect' : 'Connect'}
        </button>}

        {states.showStripeErrorMessage &&
        
         <Text className="text-red-500 m-2 border-none bg-red-100 text-center py-3 w-96 px-2 border rounded-lg transition opacity-100 duration-300 ease-in-out" type="text-small">Problem occured while connecting Stripe Account! </Text>
        }

        
        </Option>
    )
}

export default withRouter(ConnectStripe)
