import { gql } from 'apollo-boost'

export const VERIFY_SHOPIFY = gql`
    mutation($code: String, $hmac: String, $timestamp: String,
        $state: String, $shop: String){

        authenticateShopify(auth: {
             code: $code,
             hmac: $hmac,
             timestamp: $timestamp,
             state: $state,
             shop: $shop
        }){
            success
            email
            jwt
            chargeConfirmationUrl
        }
    }
`


export const SHOPIFY_CHARGE = gql`
    mutation{
        shopifyCharged
    }
`
